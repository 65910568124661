// stuff I need to put in a .env file ================================================================================================================================================================================================================================================
import axios from 'axios';
import './videoplayer.css';
import { v4 as uuidv4 } from 'uuid';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { Buffer } from 'buffer';
const ffmpegWasm = new FFmpeg({ log: true });
const API_URL = process.env.REACT_APP_API_URL;



/* GENEREAL NOTES
- sometimes videoplayer freezes but timebar continues which unsyncs image with time (can't seem to reproduce bug)
  - scrubbing or pausing fixes it

- external commands (e.g. seeking with mac touchbar) fucks up canvas handling
  - dont think this is urgent, nobody will do this frfr



*/

let ffmpegLoaded = false;

async function ensureFFmpegLoaded() {
  if (!ffmpegLoaded) {
    await ffmpegWasm.load();
    ffmpegLoaded = true;
  }
}

/**
 * Upscales an input image (provided as a base64 data URL) entirely in memory using ffmpeg.wasm.
 * The function writes the image to the ffmpeg virtual file system, runs a scaling filter,
 * and then reads back the upscaled image as a base64 data URL.
 *
 * @param {string} inputDataUrl - The base64 data URL of the input image.
 * @param {number} scaleFactor - Factor by which to upscale the image.
 * @returns {Promise<string>} - The upscaled image as a base64 data URL.
 */
async function upscaleImageInMemory(inputDataUrl, scaleFactor = 2) {
  await ensureFFmpegLoaded();

  // Remove the data URL prefix.
  const base64Data = inputDataUrl.split(',')[1];
  const buffer = Buffer.from(base64Data, 'base64');

  // Write the input image to the virtual file system.
  ffmpegWasm.FS('writeFile', 'input.jpg', buffer);

  // Run ffmpeg with a scaling filter.
  await ffmpegWasm.run(
    '-i', 'input.jpg',
    '-vf', `scale=iw*${scaleFactor}:ih*${scaleFactor}`,
    '-frames:v', '1',
    '-qscale:v', '2',
    'output.jpg'
  );

  // Read the upscaled image from the virtual file system.
  const upscaledData = ffmpegWasm.FS('readFile', 'output.jpg');
  const upscaledBase64 = Buffer.from(upscaledData).toString('base64');
  const outputDataUrl = `data:image/jpeg;base64,${upscaledBase64}`;

  // Clean up the virtual file system.
  ffmpegWasm.FS('unlink', 'input.jpg');
  ffmpegWasm.FS('unlink', 'output.jpg');

  return outputDataUrl;
}


/**
 * Helper: Capture the current video frame at intrinsic resolution.
 * This returns a data URL of the video frame as a JPEG.
 *
 * @param {HTMLVideoElement} videoEl - The video element.
 * @returns {string} Data URL of the captured frame.
 */
function captureVideoFrame(videoEl) {
  const offscreenCanvas = document.createElement("canvas");
  offscreenCanvas.width = videoEl.videoWidth;
  offscreenCanvas.height = videoEl.videoHeight;
  const ctx = offscreenCanvas.getContext("2d");
  ctx.drawImage(videoEl, 0, 0, videoEl.videoWidth, videoEl.videoHeight);
  return offscreenCanvas.toDataURL("image/jpeg", 0.9);
}

/**
 * Finds the closest key in an object to a given time (as a number),
 * within a given epsilon. Keys are assumed to be strings representing numbers.
 */
function getClosestKey(time, obj, epsilon = 0.03) {


  const timeNum = parseFloat(time);
  const keys = Object.keys(obj);
  for (let key of keys) {
    const keyNum = parseFloat(key);
    if (Math.abs(keyNum - timeNum) <= epsilon) {
      return key;
    }
  }
  // If no key is close enough, return the time formatted to 4 decimals.
  return timeNum.toFixed(4);
}
/**
 * generateFocus
 * Generates minimal focus information for a 20-second clip.
 *
 * - The clip always covers exactly 20 seconds, composed of 2 segments of 10s each.
 * - The base start time is rounded down to the nearest multiple of 10.
 * - If the annotation occurs in the first 5 seconds of its segment,
 *   the clip shifts one segment earlier (if possible).
 * - If the annotation occurs after 15 seconds into its segment,
 *   the clip shifts one segment forward (if possible).
 * - If near the end of the video, the clip is shifted so that the last 20 seconds are used.
 * - Returns a JSON string containing:
 *    - timeOffset: the offset (in seconds) of the annotation within the 20s clip.
 *    - startDir: the subfolder number where the first 10s segment resides.
 *    - startSeg: the segment number within that subfolder (1-10).
 *
 * The function now subtracts a dynamic drift correction from the annotation time.
 * The drift is modeled as: drift = k * currentTime, where k = 0.001. It was manually computed as k=0.95 for t=16:08.
 *
 * @param {number} currentTime - The annotation time in seconds.
 * @param {number} videoDurationSec - Total video duration in seconds.
 * @returns {string} - JSON string with focus metadata.
 */
function generateFocus(currentTime, videoDurationSec) {
  const SEGMENT_DURATION = 10; // seconds per segment
  const CLIP_DURATION = 20;    // seconds, always 2 segments
  const k = 0.001;             // drift correction factor
  const drift = k * currentTime;        // cumulative drift (in seconds)
  const correctedTime = currentTime - drift; // effective annotation time after drift correction

  // 1) Compute the base start time rounded down to the nearest 10s using correctedTime.
  const baseStart = Math.floor(correctedTime / SEGMENT_DURATION) * SEGMENT_DURATION;
  const offset = correctedTime - baseStart;
  let startTime;

  // 2) Adjust startTime to center the annotation:
  if (offset < 5) {
    // If the annotation occurs early in the segment, shift one segment backward (if possible).
    startTime = Math.max(baseStart - SEGMENT_DURATION, 0);
  } else if (offset > 15) {
    // If the annotation occurs late in the segment, shift one segment forward.
    startTime = baseStart + SEGMENT_DURATION;
  } else {
    startTime = baseStart;
  }

  // 3) If the 20s clip would extend past the video duration, shift the window backward.
  if (startTime + CLIP_DURATION > videoDurationSec) {
    startTime = Math.max(videoDurationSec - CLIP_DURATION, 0);
  }




  // 4) Calculate the annotation's offset within the 20s clip.
  const timeOffset = correctedTime - startTime;

  // 5) Convert startTime to a segment index (each segment is 10s).
  const startIndex = startTime / SEGMENT_DURATION;

  // 6) Compute the subfolder and segment number.
  const startDir = Math.floor(startIndex / 10);
  const startSeg = (startIndex % 10) + 1; // segment numbers 1-10

  // 7) Build the focus metadata object.
  const focusData = {
    timeOffset, // Offset within the 20s clip (drift-corrected)
    startDir,   // The subfolder containing the first segment
    startSeg    // The segment number within that subfolder
  };

  return JSON.stringify(focusData);
}

// private functions ================================================================================================================================================================================================================================================
const leadingZeroFormatter = new Intl.NumberFormat(undefined, {
  minimumIntegerDigits: 2,
})
/**
 * Formats the duration in hours, minutes, and seconds.
 *
 * @param {number} time - The time in seconds.
 * @returns {string} The formatted duration.
 */

export function formatDuration(time) {
  const seconds = Math.floor(time % 60)
  const minutes = Math.floor(time / 60) % 60
  const hours = Math.floor(time / 3600)
  if (hours === 0) {
    return `${minutes}:${leadingZeroFormatter.format(seconds)}`
  } else { 
    return `${hours}:${leadingZeroFormatter.format(
      minutes
    )}:${leadingZeroFormatter.format(seconds)}`
  }
}
/**
 * Calculates the margins needed scale the drawing properly
 *
 * @param {HTMLVideoElement} videoElement - The video element.
 * @returns {Object} The margins needed to center the video.
 * @returns {number} marginX - The margin on the X-axis.
 * @returns {number} marginY - The margin on the Y-axis.
 */

export function calculateMargins(videoElement, canvas) {
  // Get displayed dimensions of the video
  const rect = videoElement.getBoundingClientRect();
  const videoWidth = rect.width;
  const videoHeight = rect.height;

  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;

  if (videoWidth === 0 || videoHeight === 0 || canvasWidth === 0 || canvasHeight === 0) {
    return { marginX: 0, marginY: 0 };
  }

  const videoAspectRatio = videoWidth / videoHeight;
  const canvasAspectRatio = canvasWidth / canvasHeight;

  let newVideoWidth, newVideoHeight;

  if (canvasAspectRatio > videoAspectRatio) {
    // Canvas is wider than the video; fit by height
    newVideoHeight = canvasHeight;
    newVideoWidth = canvasHeight * videoAspectRatio;
  } else {
    // Canvas is taller than the video; fit by width
    newVideoWidth = canvasWidth;
    newVideoHeight = canvasWidth / videoAspectRatio;
  }

  // Calculate the margins to center the video in the canvas
  const marginX = (canvasWidth - newVideoWidth) / 2;
  const marginY = (canvasHeight - newVideoHeight) / 2;

  return { marginX, marginY };
}



/**
 * Easing function for smooth animations.
 * @param {number} t - Current time.
 * @param {number} b - Start value.
 * @param {number} c - Change in value.
 * @param {number} d - Duration.
 * @returns {number} - Calculated value at current time.
 */
function easeInOutQuad(t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
}

/**
 * Smoothly scrolls a container to a target position.
 *
 * @param {HTMLElement} container - The scrollable container element.
 * @param {HTMLElement} target - The target element to scroll into view.
 * @param {number} duration - Duration of the scroll animation in milliseconds.
 */
export function smoothScrollTo(container, target, duration) {
  const start = container.scrollTop;
  const targetPosition = target.offsetTop - 30;
  const change = targetPosition - start;
  const startTime = performance.now();

  function animateScroll(currentTime) {
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / duration, 1); // Ensure progress doesn't exceed 1
    const easedProgress = easeInOutQuad(elapsed, start, change, duration);
    container.scrollTop = easedProgress;

    if (elapsed < duration) {
      requestAnimationFrame(animateScroll);
    }
  }

  requestAnimationFrame(animateScroll);
}




/**
 * Calculates the position percentage of a given save time relative to the video's total duration.
 *
 * @param {number} saveTime - The save time in seconds.
 * @param {React.RefObject<HTMLVideoElement>} videoRef - Reference to the video element.
 * @returns {number} The position percentage.
 */
export function calculatePositionPercentage(saveTime, videoRef) {
  if (!videoRef.current) return;

  const video = videoRef.current;
  const totalDuration = video ? video.duration : 100; 

  const positionPercentage = totalDuration ? (saveTime / totalDuration) * 100 : 0;
    
  return positionPercentage;
}

/**
 * Removes a timeline indicator and its corresponding annotation card based on time.
 *
 * @param {string|number} time - The time identifier for the annotation (e.g., "2.2").
 * @param {Document} document - The document object.
 */
export function removeAnnotation(time, document) {
  // Ensure time is a string with one decimal place
  const timeId = parseFloat(time).toFixed(4);

  // Remove the timeline indicator
  const indicator = document.getElementById(`indicator-${timeId}`);
  if (indicator) {
    indicator.parentNode.removeChild(indicator);
  } else {
  }

  // Remove the annotation card
  const annotationCard = document.getElementById(`annotation-card-${timeId}`);
  if (annotationCard) {
    annotationCard.parentNode.removeChild(annotationCard);
  } else {
  }
}



/**
 * Adds a timeline indicator to the document.
 *
 * @param {string|number} time - The time identifier for the annotation (e.g., the focus offset).
 * @param {number} positionPercentage - The position percentage for the indicator.
 * @param {Document} document - The document object.
 * @param {string} [title=''] - The annotation title.
 * @param {string|number|null} [actualTime=null] - (Optional) The full annotation time.
 */
export function addTimelineIndicator(time, positionPercentage, document, title = '', actualTime = null) {
  const timeline = document.querySelector('.timeline');
  if (timeline) {
    // Format the time to one decimal place to use as part of the ID.
    const timeId = parseFloat(time).toFixed(4);
    // If actualTime is provided, append it (separated by a dash) to create a unique ID.
    /// the checker was initially if actualTime was non-null
    let indicatorId = actualTime !== null 
      ? `indicator-${timeId}-${parseFloat(actualTime).toFixed(4)}` 
      : `indicator-${timeId}`;
    
    // Avoid duplicates.
    if (document.getElementById(indicatorId)) {
      return;
    }
    
    const indicator = document.createElement('button');
    indicator.className = 'timeline-indicator';
    indicator.id = indicatorId;
    indicator.style.left = `${positionPercentage}%`;
    
    // Attach the annotation title as a data attribute.
    indicator.dataset.title = title;
    
    // If actualTime is provided, store it in a data attribute.
    if (actualTime !== null) {
      indicator.dataset.actualTime = parseFloat(actualTime).toFixed(4);
    }
    
    timeline.appendChild(indicator);
  } else {
    console.error('Timeline not found');
  }
}

/**
 * Adds an annotation card to the annotation list.
 *
 * @param {Object} annotation - The annotation object containing time, title, and commentary.
 * @param {Document} document - The document object.
 */
export function addAnnotationCard(annotation, document) {
  const annotationList = document.querySelector('.annotation-list');
  
  if (annotationList) {
    // Use time-based identifier for the card ID
    const timeId = parseFloat(annotation.time).toFixed(4); // Ensuring one decimal place
    const cardId = `annotation-card-${timeId}`;

    // Check if the card already exists
    if (document.getElementById(cardId)) {
      return; // Skip adding duplicate card
    }

    const card = document.createElement('div');
    card.className = 'annotation-card';
    card.id = cardId;

    // Ensure that title, description, and time are correctly referenced
    const title = annotation.title || 'Untitled';  // Fallback if title is missing
    const description = annotation.description || ' ';  // Fallback if description is missing
    const time = annotation.time;  // Raw time for comparison and sorting

    // Structure of the annotation card with a delete button
    card.innerHTML = `
      <button class="annotation-delete-button" aria-label="Delete Annotation">&times;</button> <!-- X symbol for delete -->
      <div class="annotation-time">${formatDuration(time)}</div>
      <div class="annotation-title">${title}</div>
      <div class="annotation-commentary">${description}</div>
    `;

    // Insert the card in the correct order based on time
    const existingCards = annotationList.children;
    
    let inserted = false;
    for (let i = 0; i < existingCards.length; i++) {
      // Compare the raw time (in seconds) for sorting, not the formatted time
      const existingTime = parseFloat(existingCards[i].getAttribute('data-time'));
      
      if (parseFloat(time) < existingTime) {
        annotationList.insertBefore(card, existingCards[i]); // Insert before the current card
        inserted = true;
        break;
      }
    }

    // If no card has a later time, append this card at the end
    if (!inserted) {
      annotationList.appendChild(card);
    }

    // Store the raw time as a data attribute for easier comparison
    card.setAttribute('data-time', time);

    // Note: We will attach event listeners to the delete buttons elsewhere
  } else {
    console.error('Annotation list container not found');
  }
}







// exported functions ================================================================================================================================================================================================================================================

/**
 * Handles the pause event for the video.
 *
 * @param {React.RefObject<HTMLVideoElement>} videoRef - Reference to the video element.
 * @param {HTMLElement} videoContainer - The container element for the video.
 * @param {Function} setLastPausedTime - Function to set the last paused time.
 */
export function handlePause(videoRef, videoContainer, setLastPausedTime) {
  if (!videoRef.current) return;
  videoContainer.classList.add("paused");
  const currTime = videoRef.current.currentTime.toFixed(4);
  setLastPausedTime(currTime);
}
/**
 * Handles the play event for the video.
 *
 * @param {React.RefObject<HTMLVideoElement>} videoRef - Reference to the video element.
 * @param {HTMLElement} videoContainer - The container element for the video.
 * @param {number} lastPausedTime - The last paused time of the video.
 */
export function handlePlay(videoRef, videoContainer, lastPausedTime, canvasManager) {
  if (!videoRef.current) return;

  videoContainer.classList.remove("paused");
  videoRef.current.currentTime = parseFloat(lastPausedTime);
  canvasManager.removeRestartIcon();
  
}
/**
 * Handles the end event for the video.
 *
 * @param {React.RefObject<HTMLVideoElement>} videoRef - Reference to the video element.
 * @param {number} lastPausedTime - The last paused time of the video.
 */
export function handleVideoEnd(videoRef,lastPausedTime, setPlaybackState) {
  if (!videoRef.current) return;

  videoRef.current.currentTime = lastPausedTime; // either this or set it to 0
  //setLastPausedTime(0);
  videoRef.current.pause();
  setPlaybackState("paused");

}
/**
 * Handles the loaded data event for the video.
 *
 * @param {React.RefObject<HTMLVideoElement>} videoRef - Reference to the video element.
 * @param {HTMLElement} totalTimeElem - The element displaying the total time of the video.
 */
export function handleLoadedData(videoRef, totalTimeElem) {
  if (!videoRef.current) return;

  if (!videoRef) return;
  totalTimeElem.textContent = formatDuration(videoRef.current.duration);
}
/**
 * Handles the timeline update event.
 * modified .x to .clientX to pass tests, not sure if works
 *
 * @param {MouseEvent} e - The mouse event.
 * @param {HTMLElement} timelineContainer - The container element for the timeline.
 * @param {boolean} isScrubbing - Indicates if scrubbing is in progress.
 */
export function handleTimelineUpdate(e, timelineContainer, isScrubbing) {
  if (!timelineContainer) {
    console.error("timelineContainer is undefined");
    return;
  }
  const rect = timelineContainer.getBoundingClientRect();
  const percent = Math.min(Math.max(0, e.clientX - rect.left), rect.width) / rect.width;  
  if (isScrubbing) {
    e.preventDefault();
    timelineContainer.style.setProperty("--progress-position", percent);
  }
}


/**
 * Handles the time update event for the video.
 *
 * @param {React.RefObject<HTMLVideoElement>} videoRef - Reference to the video element.
 * @param {HTMLElement} currentTimeElem - The element displaying the current time of the video.
 * @param {HTMLElement} timelineContainer - The container element for the timeline.
 */
export function handleTimeUpdate(videoRef, currentTimeElem, timelineContainer) {
  if (!videoRef.current) return;

  const currentTime = videoRef.current.currentTime;
  const duration = videoRef.current.duration;

  // If duration is not set or invalid, set progress to 0 and update the text appropriately.
  if (!duration || isNaN(duration) || duration <= 0) {
    timelineContainer.style.setProperty("--progress-position", 0);
    currentTimeElem.textContent = formatDuration(0);
    return;
  }

  // Clamp currentTime if it's somehow greater than duration.
  if (currentTime > duration) {
    videoRef.current.currentTime = duration;
  }

  // Update display using valid numbers.
  currentTimeElem.textContent = formatDuration(currentTime);
  const percent = currentTime / duration;
  timelineContainer.style.setProperty("--progress-position", percent);
}



/**
 * Toggles the draw mode.
 *
 * @param {string} mode - The draw mode to toggle.
 * @param {React.RefObject<HTMLVideoElement>} videoRef - Reference to the video element.
 * @param {Function} setDrawMode - Function to set the draw mode.
 */
export function toggleDrawMode(mode, videoRef, setDrawMode, setPlaybackState) {
  if (!videoRef.current) return;

  setDrawMode(prevMode => {
    const newMode = (prevMode === mode ? '' : mode);
    if (videoRef.current && newMode !== '' && prevMode !== newMode) {
      // round currTime to force glitchframe out
      videoRef.current.pause();
      setPlaybackState("paused");
    }
    return newMode;
  });
}
/**
 * Toggles the visibility of the color palette.
 *
 * @param {Document} document - The document object.
 */
export function toggleColorPalette(document) {
  const colorPaletteContainer = document.querySelector(".color-palette-container");
  const thicknessSlider = document.querySelector(".thickness-slider");
  if (colorPaletteContainer.style.display === "none") {
    colorPaletteContainer.style.display = "flex";
    thicknessSlider.style.display = "block";
    setTimeout(() => {
      colorPaletteContainer.style.opacity = "0.8";
      thicknessSlider.style.opacity = "0.8";
    }, 10);
  } else {
    colorPaletteContainer.style.opacity = "0";
    thicknessSlider.style.opacity = "0";
    setTimeout(() => {
      colorPaletteContainer.style.display = "none";
      thicknessSlider.style.display = "none";
    }, 150);
  }
}

/**
 * Adds a flash animation to a button.
 *
 * @param {HTMLElement} button - The button element to animate.
 */
export function clickAnimation(button) {
  button.classList.add("flash");
  setTimeout(() => {
    button.classList.remove("flash");
  }, 150);
}
/**
 * Changes the playback speed of the video.
 *
 * @param {React.RefObject<HTMLVideoElement>} videoRef - Reference to the video element.
 * @param {HTMLElement} speedBtn - The button element displaying the playback speed.
 */
export function changePlaybackSpeed(videoRef, speedBtn) {
  if (!videoRef.current) return;

  let newPlaybackRate = videoRef.current.playbackRate + 0.25
  if (newPlaybackRate > 2) newPlaybackRate = 0.25
  videoRef.current.playbackRate = newPlaybackRate
  speedBtn.textContent = `${newPlaybackRate}x`
}




// classes ================================================================================================================================================================================================================================================

/**
 * @typedef {Object} VideoPlaybackHandlerArgs
 * @property {React.RefObject<HTMLVideoElement>} videoRef - The reference to the video element.
 * @property {string} videoLink - The link to the video source.
 * @property {HTMLElement} videoContainer - The container element for the video.
 * @property {React.RefObject<HTMLCanvasElement>} coldCanvasRef - The reference to the cold canvas element.
 * @property {Function} setCanvasEdited - Function to set the canvas edited state.
 * @property {Function} setLastPausedTime - Function to set the last paused time.
 * @property {number} lastPausedTime - The last paused time of the video.
 * @property {Object} canvasManager - The canvas manager instance.
 * @property {Object.<string, string>} canvasStatus - The status of the canvas at different timestamps.
 * @property {Function} updateCanvasStatus - Function to update the canvas status.
 * @property {HTMLElement} currentTimeElem - The element displaying the current time.
 * @property {HTMLElement} timelineContainer - The container element for the timeline.
 * @property {React.RefObject<boolean>} isScrubbingRef - Reference object indicating if scrubbing is in progress.
 * @property {Function} setLoading - Function to set the loading state.
 * @property {React.RefObject<HTMLCanvasElement>} hotCanvasRef - The reference to the hot canvas element.
 * @property {Document} document - The document object.
 * @property {Function} setDrawMode - Function to set the draw mode.
 * @property {Function} resetStatus - Function to reset the canvas status.
 */
/**
 * Handles the playback of the video
 *
 * @export
 * @class VideoPlaybackHandler
 */
export class VideoPlaybackHandler {
  /**
   * Creates an instance of VideoPlaybackHandler.
   * @memberof VideoPlaybackHandler
   */
  constructor() {
    this.lastScrubEvent = null;
    this.animationFrameId = null;
    // Bind methods to ensure 'this' refers to the class instance
    this.indicatorsWithListeners = new Set();
    this.annotationCardsWithListeners = new Set();
    //this.handleMouseEnter = this.handleMouseEnter.bind(this);
    //this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleIndicatorClick = this.handleIndicatorClick.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
    this.deleteButtonsWithListeners = new Set();

    // Initialize the set for tracking indicators with listeners
    this.indicatorsWithListeners = new Set();
    this.args = {
      videoRef: null,
      videoLink: null,
      videoContainer: null,
      coldCanvasRef: null,
      setCanvasEdited: null,
      setLastPausedTime: null,
      lastPausedTime: null,
      canvasManager: null,
      canvasStatus: null,
      updateCanvasStatus: null,
      currentTimeElem: null,
      timelineContainer: null,
      isScrubbingRef: null,
      setLoading: null,
      hotCanvasRef: null,
      document: null,
      setDrawMode: null,
      resetStatus: null,
      token: null
    }
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.currentHoveredIndicator = null;
  }
  /**
   * Sets the arguments for the VideoPlaybackHandler
   *
   * @param {VideoPlaybackHandlerArgs} args
   * @memberof VideoPlaybackHandler
   */
  setArgs(args) {
    this.args = args;
    // Initialize preview element when args are set
    const { timelineContainer, videoRef } = args;
    if (timelineContainer && !timelineContainer.querySelector('.timeline-preview')) {
      const previewElement = document.createElement('div');
      previewElement.className = 'timeline-preview';
      timelineContainer.appendChild(previewElement);
    }

    // Add mousemove listener to timeline container
    if (timelineContainer) {
      timelineContainer.addEventListener('mousemove', this.handleMouseMove);
      timelineContainer.addEventListener('mouseleave', this.handleMouseLeave);
    }
  }

/**
 * Sets the canvas size and position to overlay the video content area accurately.
 * @returns {Promise} - Resolves when the canvas is set.
 */
setCanvasSize() {
  const { videoRef, hotCanvasRef, coldCanvasRef } = this.args;
  const video = videoRef.current;

  if (!video) return Promise.resolve();

  return new Promise((resolve) => {
    const checkSize = () => {
      if (video.videoWidth && video.videoHeight) {
        const { contentWidth, contentHeight, offsetX, offsetY } = this.calculateVideoContentArea(video);

        if (hotCanvasRef.current && coldCanvasRef.current) {
          [hotCanvasRef.current, coldCanvasRef.current].forEach((canvas) => {
            // Set canvas internal dimensions to match video's intrinsic dimensions
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            // Position the canvas to overlay the video content area
            canvas.style.position = 'absolute';
            canvas.style.left = `${offsetX}px`;
            canvas.style.top = `${offsetY}px`;
            canvas.style.width = `${contentWidth}px`;
            canvas.style.height = `${contentHeight}px`;

            // Optional: Ensure canvas transforms align with video scaling
            canvas.style.transform = 'none'; // Reset any transformations
          });
        }
        resolve();
      } else {
        // Video dimensions not yet updated, check again
        requestAnimationFrame(checkSize);
      }
    };
    checkSize();
  });
}


// Function to calculate the video content area
/**
 * Calculates the content area of the video excluding black bars.
 * @param {HTMLVideoElement} video - The video element.
 * @returns {Object} - Contains contentWidth, contentHeight, offsetX, offsetY.
 */
 calculateVideoContentArea(video) {
  const videoRect = video.getBoundingClientRect();
  const displayedVideoWidth = videoRect.width;
  const displayedVideoHeight = videoRect.height;

  const videoAspectRatio = video.videoWidth / video.videoHeight;
  const displayedAspectRatio = displayedVideoWidth / displayedVideoHeight;

  let contentWidth, contentHeight, offsetX, offsetY;

  if (displayedAspectRatio > videoAspectRatio) {
    // Black bars on the left and right (pillarboxing)
    contentHeight = displayedVideoHeight;
    contentWidth = contentHeight * videoAspectRatio;
    offsetX = (displayedVideoWidth - contentWidth) / 2; // Horizontal margin
    offsetY = 0;
  } else {
    // Black bars on the top and bottom (letterboxing)
    contentWidth = displayedVideoWidth;
    contentHeight = contentWidth / videoAspectRatio;
    offsetX = 0;
    offsetY = (displayedVideoHeight - contentHeight) / 2; // Vertical margin
  }

  return {
    contentWidth,
    contentHeight,
    offsetX, // The left/right black bar margin
    offsetY, // The top/bottom black bar margin
  };
}





  /**
   * Starts the frame-based drawing using requestAnimationFrame
   * 
   * this implementation might be perfect
   * if theres a problem, make the requestAnimationFrames watch the timelineContainer rather than time
   *
   * @return {*} 
   * @memberof VideoPlaybackHandler
   */
drawTracingPlay() {
  if (this.animationFrameId) return;

  const draw = () => {
    if (!this.args.videoRef.current) return;

    if (!this.args.videoRef.current.paused) {
      this.drawTracing();
      this.animationFrameId = requestAnimationFrame(draw);
    } else {
      this.animationFrameId = null;
    }
  };

  this.animationFrameId = requestAnimationFrame(draw);
}


  /**
   * Pauses the frame-based drawing
   *
   * @memberof VideoPlaybackHandler
   */
  drawTracingPause() {

    if (!this.args.videoRef.current) return
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }  

  /**
   * Ensures requestAnimationFrame stays in sync with the video playback after switching tabs
   *
   * @param {*} isVisible
   * @memberof VideoPlaybackHandler
   */
  handleVisibilityChange(isVisible) {
    const video = this.args.videoRef;
    const setPlaybackState = this.args.setPlaybackState;

    if (!video.current) return;
    if (isVisible) {
      const { videoRef } = this.args;
      if (videoRef.current) {
        this.drawTracingPlay();
      }
    } else {
      if (!video.current.paused){
         video.current.pause();
         setPlaybackState("paused");
      }
      this.drawTracingPause();
    }
  }
  





      /*

ok so i successfully have the mappedTime now. Perhaps i can just replace everything by mappedTime in presentation mode
except for the scrubbing time related stuff


- in presentationMode
    - modify the check to be bypassed if we have the offsetTime = currentTime
    - set the currentTime of the video to the offsetTime
    - fetch the canvas data of the annotationId (no actual need for the time in this step)
    - (in the finally block) select the cards of the real annotation time


    */


/**
   * Checks for saved canvas and draws it on the cold canvas
   *
   * @param {boolean} [override=false]
   * @return {*} 
   * @memberof VideoPlaybackHandler
   */
async drawTracing(override = false) {
  const {
    videoId,
    token,
    isScrubbingRef,
    videoRef,
    videoLink,
    coldCanvasRef,
    setCanvasEdited,
    setLastPausedTime,
    canvasStatus,
    updateCanvasStatus,
    currentTimeElem,
    timelineContainer,
    setLoading,
    setPlaybackState,
    document,
    presentationMode,
    focusIndex,
    annotationFocusList
  } = this.args;

  if (!videoLink || !videoRef.current || !coldCanvasRef.current) {
    console.error('videoLink is missing');
    return;
  }

  if (!presentationMode){
    const currTime = videoRef.current.currentTime.toFixed(4).toString();

    const approxCurrTime = getClosestKey(currTime, canvasStatus);
    const playedStatus = override ? "U" : canvasStatus[approxCurrTime];



    if (playedStatus === "U" && !isScrubbingRef.current) {
      if (!videoRef.current.paused) {
        videoRef.current.pause();
        setPlaybackState("paused");
      }
      setLoading(true);
      videoRef.current.pause();


      try {
        // Fetch annotation metadata
        const metadataResponse = await axios.get(`${API_URL}/meta_annotation/video/${videoId}/annotation`, {
          params: { time: approxCurrTime },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const annotationMetadata = metadataResponse.data;
        if (!annotationMetadata || !annotationMetadata.annotationId) {
          console.error('No annotation metadata found for this time.');
          setLoading(false);
          return;
        }

        const annotationId = annotationMetadata.annotationId;

        // Fetch the annotation data using the found annotationId
        const annotationResponse = await axios.get(`${API_URL}/annotation/${annotationId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const annotationData = annotationResponse.data;
        const canvasData = annotationData?.annotationData;
        const originalCanvasWidth = annotationData?.canvasWidth;
        const originalCanvasHeight = annotationData?.canvasHeight;
        const originalVideoWidth = annotationData?.videoWidth;
        const originalVideoHeight = annotationData?.videoHeight;

        if (!canvasData || !originalCanvasWidth || !originalCanvasHeight || !originalVideoWidth || !originalVideoHeight) {
          console.error('Incomplete annotation data.');
          setLoading(false);
          return;
        }

        // Ensure current video and canvas sizes are set
        await this.setCanvasSize();

        const currentCanvas = coldCanvasRef.current;
        const currentVideo = videoRef.current;

        // Get current displayed dimensions of the video
        const currentRect = currentVideo.getBoundingClientRect();
        const currentVideoWidth = currentRect.width;
        const currentVideoHeight = currentRect.height;

        // Calculate scaling factors based on video dimensions
        const scaleX = currentVideoWidth / originalVideoWidth;
        const scaleY = currentVideoHeight / originalVideoHeight;

        // Use the minimum scale factor to maintain aspect ratio
        const scale = Math.min(scaleX, scaleY);

        // Calculate adjusted dimensions for the annotation image
        const adjustedWidth = originalCanvasWidth * scale;
        const adjustedHeight = originalCanvasHeight * scale;

        // Calculate margins to center the annotation
        const adjustedMarginX = (currentCanvas.width - adjustedWidth) / 2;
        const adjustedMarginY = (currentCanvas.height - adjustedHeight) / 2;

        //glitchframe fix
        videoRef.current.currentTime = parseFloat(approxCurrTime).toFixed(1); // to load correct image before displaying canvas

// Get the drawing context and clear the canvas.
const ctx_cold = currentCanvas.getContext('2d');
ctx_cold.clearRect(0, 0, currentCanvas.width, currentCanvas.height);

// Retrieve the saved video frame URL from annotation data.
const videoFrameUrl = annotationData?.videoFrameUrl;
if (videoFrameUrl) {
  // First, draw the saved video frame (scaled and centered).
  await new Promise((resolve, reject) => {
    const videoImg = new Image();
    
    videoImg.onload = () => {
      ctx_cold.drawImage(videoImg, 0, 0, currentCanvas.width, currentCanvas.height);
      resolve();
    };
    videoImg.onerror = (err) => {
      console.error('Video frame image loading error:', err);
      reject(err);
    };
    videoImg.src = videoFrameUrl;
    videoImg.crossOrigin = 'anonymous';

  });
} else {
  console.warn('No video frame URL found in annotation data, skipping underlying video frame rendering.');
}



// If canvasData is a string, draw it as a single composite image.
if (Array.isArray(canvasData)) {
  // Handle array of stroke data URLs
  for (const strokeData of canvasData) {
    if (typeof strokeData !== 'string' || !strokeData.startsWith('data:image/')) {
      console.warn('Skipping invalid strokeData:', strokeData);
      continue;
    }

    await new Promise((resolve, reject) => {
      const strokeImg = new Image();
      strokeImg.crossOrigin = 'anonymous';
      strokeImg.onload = () => {
        ctx_cold.drawImage(strokeImg, 0, 0, currentCanvas.width, currentCanvas.height);
        resolve();
      };
      strokeImg.onerror = (err) => {
        console.error('Stroke image loading error:', err);
        reject(err);
      };
      strokeImg.src = strokeData;
    });
  }
} else if (typeof canvasData === 'string' && canvasData.startsWith('data:image/')) {
  // Handle single base64 string
  await new Promise((resolve, reject) => {
    const drawingImg = new Image();
    drawingImg.crossOrigin = 'anonymous';
    drawingImg.onload = () => {
      ctx_cold.drawImage(drawingImg, 0, 0, currentCanvas.width, currentCanvas.height);
      resolve();
    };
    drawingImg.onerror = (err) => {
      console.error('Drawing image loading error:', err);
      reject(err);
    };
    drawingImg.src = canvasData;
  });
} else {
  console.warn('canvasData is neither a valid data URL string nor an array of strings:', canvasData);
}



        setLastPausedTime(approxCurrTime);
        handleTimeUpdate(videoRef, currentTimeElem, timelineContainer);
        setCanvasEdited(true);
        updateCanvasStatus(approxCurrTime, "P");
        setLoading(false);

      } catch (error) {
        setLoading(false);
        console.error('Error fetching canvas data:', error);
      } finally {
        // fixes the near-indicator scrub bug 
        videoRef.current.pause();

        const currTime2 = approxCurrTime; // Ensure one decimal place

        // Escape the dot in currTime2 for use in querySelector
        const escapedCurrTime = currTime2.replace('.', '\\.');

        // Select the corresponding timeline indicator
        const selectedIndicator = document.querySelector(`#indicator-${escapedCurrTime}`);


        if (selectedIndicator) {
          selectedIndicator.classList.add('glowing'); // Add the glowing class to the indicator
        } else {
          console.error(`Indicator for time ${currTime2} not found`);
        }


        await new Promise(resolve => setTimeout(resolve, 100)); // Adjust the delay if needed

      }
    }

  } else{
    // the presentationMode logic....

    ////////////// Presentation Mode Logic //////////////

    // Define a constant offset to compensate for known frame jitter.
    // const MAGIC_FRAME_OFFSET = 0; // Use a negative value if the frame appears earlier


    // Read the full annotation time (aT) and stored offset (oT) from your focus metadata.
    const aT = annotationFocusList[focusIndex].time;        // Full annotation time from full video
    const oT = annotationFocusList[focusIndex].offsetTime;    // Offset within the 20s (or drift-corrected) clip

    // Read the current mini-player time with full five-decimal precision, then adjust:
    const cT = videoRef.current.currentTime;

    // Compute the mapped full video time:
    const mappedTime = aT - oT + cT;

    const approxCurrTime = getClosestKey(mappedTime, canvasStatus);

    // Use five decimals when working with canvasStatus keys, etc.
    let playedStatus = override ? "U" : canvasStatus[approxCurrTime];
    let currTime = approxCurrTime;

    if (playedStatus === "U" && !isScrubbingRef.current) {
      if (!videoRef.current.paused) {
        videoRef.current.pause();
        setPlaybackState("paused");
      }
      setLoading(true);
      videoRef.current.pause();
      try {
        let timeToFetch;
        // For presentation mode, re-read the mini-player current time and adjust:
        const miniTime = videoRef.current.currentTime;;
        // Map: full video time = aT - oT + miniTime.
        timeToFetch = getClosestKey(aT - oT + miniTime, canvasStatus);

        // For consistency, we set currTime to the computed mappedTime.
        currTime = timeToFetch;

        // Fetch annotation metadata using the drift-corrected time
        const metadataResponse = await axios.get(`${API_URL}/meta_annotation/video/${videoId}/annotation`, {
          params: { time: timeToFetch},
          headers: { Authorization: `Bearer ${token}` },
        });
        const annotationMetadata = metadataResponse.data;
        if (!annotationMetadata || !annotationMetadata.annotationId) {
          console.error('No annotation metadata found for this time.');
          setLoading(false);
          return;
        }
        const annotationId = annotationMetadata.annotationId;

        // Fetch the full annotation data
        const annotationResponse = await axios.get(`${API_URL}/annotation/${annotationId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const annotationData = annotationResponse.data;
        const canvasData = annotationData?.annotationData;
        const originalCanvasWidth = annotationData?.canvasWidth;
        const originalCanvasHeight = annotationData?.canvasHeight;
        const originalVideoWidth = annotationData?.videoWidth;
        const originalVideoHeight = annotationData?.videoHeight;
        if (!canvasData || !originalCanvasWidth || !originalCanvasHeight || !originalVideoWidth || !originalVideoHeight) {
          console.error('Incomplete annotation data.');
          setLoading(false);
          return;
        }

        await this.setCanvasSize();
        const currentCanvas = coldCanvasRef.current;
        const currentVideo = videoRef.current;
        const currentRect = currentVideo.getBoundingClientRect();
        const currentVideoWidth = currentRect.width;
        const currentVideoHeight = currentRect.height;
        const scaleX = currentVideoWidth / originalVideoWidth;
        const scaleY = currentVideoHeight / originalVideoHeight;
        const scale = Math.min(scaleX, scaleY);
        const adjustedWidth = originalCanvasWidth * scale;
        const adjustedHeight = originalCanvasHeight * scale;
        const adjustedMarginX = (currentCanvas.width - adjustedWidth) / 2;
        const adjustedMarginY = (currentCanvas.height - adjustedHeight) / 2;


        videoRef.current.currentTime = oT;
        await new Promise(resolve => {
          videoRef.current.addEventListener('seeked', resolve, { once: true });
        });
        
        // Now measure the landed time.
        const landedTime = videoRef.current.currentTime;
        const delta = oT - landedTime; // This should ideally be zero.
        
        // If there's a small difference (on the order of a few milliseconds), nudge the time.
        if (Math.abs(delta) > 0.0005) { // adjust the threshold as needed
          videoRef.current.currentTime = oT + delta;
          await new Promise(resolve => {
            videoRef.current.addEventListener('seeked', resolve, { once: true });
          });
        }


        // Draw the annotation image
// Get the drawing context and clear the canvas.
const ctx_cold = currentCanvas.getContext('2d');
ctx_cold.clearRect(0, 0, currentCanvas.width, currentCanvas.height);

// Retrieve the saved video frame URL from annotation data.
const videoFrameUrl = annotationData?.videoFrameUrl;
if (videoFrameUrl) {
  // First, draw the saved video frame (scaled and centered).
  await new Promise((resolve, reject) => {
    const videoImg = new Image();
    videoImg.onload = () => {
      ctx_cold.drawImage(videoImg, 0, 0, currentCanvas.width, currentCanvas.height);
      resolve();
    };
    videoImg.onerror = (err) => {
      console.error('Video frame image loading error:', err);
      reject(err);
    };
    videoImg.src = videoFrameUrl;
    videoImg.crossOrigin = 'anonymous';

  });
} else {
  console.warn('No video frame URL found in annotation data, skipping underlying video frame rendering.');
}

// If canvasData is a string, draw it as a single composite image.
if (Array.isArray(canvasData)) {
  // Handle array of stroke data URLs
  for (const strokeData of canvasData) {
    if (typeof strokeData !== 'string' || !strokeData.startsWith('data:image/')) {
      console.warn('Skipping invalid strokeData:', strokeData);
      continue;
    }

    await new Promise((resolve, reject) => {
      const strokeImg = new Image();
      strokeImg.crossOrigin = 'anonymous';
      strokeImg.onload = () => {
        ctx_cold.drawImage(strokeImg, 0, 0, currentCanvas.width, currentCanvas.height);
        resolve();
      };
      strokeImg.onerror = (err) => {
        console.error('Stroke image loading error:', err);
        reject(err);
      };
      strokeImg.src = strokeData;
    });
  }
} else if (typeof canvasData === 'string' && canvasData.startsWith('data:image/')) {
  // Handle single base64 string
  await new Promise((resolve, reject) => {
    const drawingImg = new Image();
    drawingImg.crossOrigin = 'anonymous';
    drawingImg.onload = () => {
      ctx_cold.drawImage(drawingImg, 0, 0, currentCanvas.width, currentCanvas.height);
      resolve();
    };
    drawingImg.onerror = (err) => {
      console.error('Drawing image loading error:', err);
      reject(err);
    };
    drawingImg.src = canvasData;
  });
} else {
  console.warn('canvasData is neither a valid data URL string nor an array of strings:', canvasData);
}

        setLastPausedTime(oT);
        handleTimeUpdate(videoRef, currentTimeElem, timelineContainer);
        setCanvasEdited(true);
        updateCanvasStatus(approxCurrTime, "P");
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching canvas data:', error);
      } finally {
        //videoRef.current.pause();
        //videoRef.current.currentTime = approxCurrTime;

        // Use five-decimal precision for query selectors:
        const currTime2 = currTime; // already a string with five decimals
        const escapedCurrTime = currTime2.replace(/\./g, '\\.');
        let selectedIndicator;
        // For presentation mode, we use both offset and actual annotation time as keys
        const offsetStr = oT.toFixed(4);
        const actualStr = aT.toFixed(4);
        const escapedOffset = offsetStr.replace(/\./g, '\\.');
        const escapedActual = actualStr.replace(/\./g, '\\.');
        selectedIndicator = document.querySelector(`#indicator-${escapedOffset}-${escapedActual}`);
        if (selectedIndicator) {
          selectedIndicator.classList.add('glowing');
        } else {
          console.error(`Indicator for time ${currTime2} not found`);
        }
        // (If needed, add scrolling logic for annotation cards here.)
        await new Promise(resolve => setTimeout(resolve, 100));
      }
    }

  }




}
  
  
  
  

   getVideoContentArea(videoWidth, videoHeight, canvasWidth, canvasHeight) {
    const videoAspectRatio = videoWidth / videoHeight;
    const canvasAspectRatio = canvasWidth / canvasHeight;
  
    let contentWidth, contentHeight;
  
    if (canvasAspectRatio > videoAspectRatio) {
      // Canvas is wider than video aspect ratio
      contentHeight = canvasHeight;
      contentWidth = contentHeight * videoAspectRatio;
    } else {
      // Canvas is taller than video aspect ratio
      contentWidth = canvasWidth;
      contentHeight = contentWidth / videoAspectRatio;
    }
  
    const offsetX = (canvasWidth - contentWidth) / 2;
    const offsetY = (canvasHeight - contentHeight) / 2;
  
    return { contentWidth, contentHeight, offsetX, offsetY };
  }
  
  
  
  
  


  
/**
 * Initializes the timeline indicators with respect to the saved canvases data.
 * In presentation mode, only the indicator corresponding to the focused annotation
 * is initialized at its target time (i.e. the annotation time minus the focus offset).
 *
 * @return {*} 
 * @memberof VideoPlaybackHandler
 */
async initializeTimelineIndicators() {
  const {
    videoId,
    token,
    videoRef,
    document,
    updateCanvasStatus,
    setPreloading,
    presentationMode, // new argument/state
    focusIndex,       // new argument/state,
    annotationFocusList,
  } = this.args;
  const timeline = document.querySelector('.timeline');


  if (!timeline) {
    console.error('Timeline not found');
    return;
  }

  if (!videoRef.current) {
    console.error('videoRef is missing');
    return;
  }

  try {


    // Fetch annotation metadata for the video
    const response = await axios.get(`${API_URL}/meta_annotation/video/${videoId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });

    const annotations = response.data;

    // Clear the timeline and annotation list before updating
    timeline.innerHTML = '';
    if (!presentationMode) {
      const annotationList = document.querySelector('.annotation-list');
      if (annotationList) {
        annotationList.innerHTML = '';
      }
    }

    // Sort the annotations by time (ascending)
    const sortedAnnotations = annotations.sort((a, b) => parseFloat(a.time) - parseFloat(b.time));

    if (presentationMode) {
      // In presentation mode, only initialize the indicator for the focused annotation.
      if (focusIndex < 0 || focusIndex >= sortedAnnotations.length) {
        console.error("Invalid focus index");
        return;
      }

      const item = annotationFocusList[focusIndex].annotationId;

      // Fetch detailed annotation data using annotationId
      const annotationResponse = await axios.get(`${API_URL}/annotation/${item}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const annotation = annotationResponse.data;
      let focusData = null;
      try {
        focusData = annotation.focus ? JSON.parse(annotation.focus) : null;
      } catch (err) {
        console.error("Error parsing focus data:", err);
      }

      if (focusData) {
        // Here, targetTime is computed from focusData (for positioning).
        const targetTime = parseFloat(focusData.timeOffset);
        const positionPercentage = calculatePositionPercentage(targetTime, videoRef);
        // Now pass the actual annotation time as well.
        addTimelineIndicator(targetTime, positionPercentage, document, annotation.title, annotation.time);
        updateCanvasStatus(parseFloat(annotation.time).toFixed(4), "U");
      }
    } else {
      // Non-presentation mode: initialize indicators for all annotations.
      const processAnnotations = sortedAnnotations.map(async (item) => {
        const storedTime = parseFloat(item.time).toFixed(4);
        if (storedTime >= 0) {
          const positionPercentage = calculatePositionPercentage(parseFloat(storedTime), videoRef);
          // Fetch detailed annotation data using annotationId
          const annotationResponse = await axios.get(`${API_URL}/annotation/${item.annotationId}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          const annotation = annotationResponse.data;
          // Add timeline indicator and card
          addTimelineIndicator(storedTime, positionPercentage, document, annotation.title);
          updateCanvasStatus(storedTime, "U");
          addAnnotationCard({
            annotationId: annotation.annotationId,
            title: annotation.title,
            description: annotation.description,
            time: storedTime
          }, document);
        }
      });
      await Promise.all(processAnnotations);
    }

    setPreloading(false);
    return true;
  } catch (error) {
    console.error('Error fetching annotation metadata or details:', error);
    return false;
  } 
}
  
  
  
  
  
  
/**
 * Handles the full screen change event
 *
 * @memberof VideoPlaybackHandler
 */
handleFullScreenChange() {
  const { 
    videoContainer,
    document,
    videoRef,
    lastPausedTime, 
    canvasStatus, 
    canvasManager,
    setDrawMode,
    setAnnotationTitle,
    setAnnotationCommentary,
    currentTimeElem,
    timelineContainer,
    setLastPausedTime,
    setCanvasStatus,
    resetStatus
  } = this.args;

  // Return if no video
  if (!videoRef.current) return;

  // Toggle fullscreen CSS class
  videoContainer.classList.toggle(
    "full-screen",
    !!(document.fullscreenElement || document.webkitFullscreenElement)
  );

  const isPaused = videoRef.current.paused;
  // Using the currentTime on the actual <video> in case user has scrubbed:
  const currentTime = parseFloat(videoRef.current.currentTime.toFixed(4));
  const timeKey = currentTime.toFixed(4);
  const timeExists = canvasStatus[getClosestKey(timeKey, canvasStatus)] === "P" || canvasStatus[getClosestKey(timeKey, canvasStatus)] === "U";

  if (isPaused && timeExists) {
    // Rewind by 0.2 seconds but stay paused
    const newTime = Math.max(0, currentTime - 0.2);
    videoRef.current.currentTime = newTime;
    setLastPausedTime(newTime.toFixed(4).toString());

    // Reset statuses so user must explicitly re-load or re-draw
    resetStatus(canvasStatus, setCanvasStatus);

    // Update on-screen time display and timeline progress
    handleTimeUpdate(videoRef, currentTimeElem, timelineContainer);


    videoRef.current.play();

  } else {
    // Otherwise, clear any annotation/draw mode data
    setDrawMode("");
    if (setAnnotationTitle && setAnnotationCommentary) {
      setAnnotationTitle("");
      setAnnotationCommentary("");
    }
    canvasManager.clearCanvas();
  }
}



  /**
   * Toggles the scrubbing of the video
   *
   * @param {*} e
   * @return {*} 
   * @memberof VideoPlaybackHandler
   */
  toggleScrubbing(e, loading = false, skipPrompt = false) {
    const { drawMode, videoRef, setPendingAction, setHasUnsavedChanges, hasUnsavedChanges, presentationMode } = this.args;
  
    // If user not drawing => never prompt
    if (drawMode === "") {
      this.performScrubbing(e, loading);
      return;
    }
  
    // If user IS drawing & skipPrompt === false => show prompt
    if (!skipPrompt && hasUnsavedChanges && !presentationMode) {
      videoRef.current?.pause();
      setPendingAction("scrubbing");
      this.lastScrubEvent = e;
      return;
    }
  
    // Otherwise skipPrompt === true => do real scrubbing
    this.performScrubbing(e, loading);
  }

  performScrubbing(e, loading = false) {
    const {
      canvasManager,
      setCanvasEdited,
      setDrawMode,
      setAnnotationTitle,
      setAnnotationCommentary,
      videoRef,
      setPlaybackState,
      resetStatus,
      videoContainer,
      timelineContainer,
      setLastPausedTime,
      isScrubbingRef
      // any other needed props
    } = this.args;
  
    if (loading || !videoRef.current) return;
  
    canvasManager.removeRestartIcon();
    canvasManager.clearCanvas();
    setCanvasEdited(false);
    setDrawMode("");
    setAnnotationTitle("");
    setAnnotationCommentary("");
  
    videoRef.current.pause();
    setPlaybackState("paused");
    resetStatus();
  
    const rect = timelineContainer.getBoundingClientRect();
    const percent = Math.min(Math.max(0, e.clientX - rect.x), rect.width) / rect.width;
    isScrubbingRef.current = (e.buttons & 1) === 1;
    videoContainer.classList.toggle("scrubbing", isScrubbingRef.current);
  
    if (isScrubbingRef.current) {
      this.handleMouseMove(e); // Update preview while scrubbing
    }
  
    const newTime = percent * videoRef.current.duration;
    videoRef.current.currentTime = newTime;
  
    // If near end, show restart icon
    if (Math.abs(newTime - videoRef.current.duration) < 0.1) {
      canvasManager.toggleRestartIcon();
    }
  
    // ... handleTimeUpdate, setLastPausedTime, etc...
    setLastPausedTime(videoRef.current.currentTime.toFixed(4).toString());
    handleTimelineUpdate(e, timelineContainer, isScrubbingRef.current);
  }

  

  setupIndicatorEventListeners() {
    const { documen } = this.args;
  
    const setupEventListeners = () => {
      const indicators = document.querySelectorAll('.timeline-indicator');
      const annotationCards = document.querySelectorAll('.annotation-card');
  
      // Setup event listeners for indicators
      indicators.forEach((indicator) => {
        if (!this.indicatorsWithListeners.has(indicator)) {
          //indicator.addEventListener('mouseenter', this.handleMouseEnter);
          //indicator.addEventListener('mouseleave', this.handleMouseLeave);
          indicator.addEventListener('click', (e) => this.handleIndicatorClick(e));
          
          // Hover event listeners for tooltip
          /*indicator.addEventListener('mouseenter', this.handleIndicatorHoverEnter);
          indicator.addEventListener('mouseleave', this.handleIndicatorHoverLeave);
          */
          this.indicatorsWithListeners.add(indicator);
        }
      });
  
      // Setup event listeners for annotation cards
      annotationCards.forEach((card) => {
        if (!this.annotationCardsWithListeners.has(card)) {
          //card.addEventListener('mouseenter', this.handleMouseEnter);
          //card.addEventListener('mouseleave', this.handleMouseLeave);
          card.addEventListener('click', this.handleCardClick);
          this.annotationCardsWithListeners.add(card);
        }
  
        // Setup event listeners for delete buttons within the cards
        const deleteButton = card.querySelector('.annotation-delete-button');
        if (deleteButton && !this.deleteButtonsWithListeners.has(deleteButton)) {
          deleteButton.addEventListener('click', this.handleDeleteButtonClick);
          this.deleteButtonsWithListeners.add(deleteButton);
        }
      });
    };
  
    // Initially set up event listeners on existing indicators and cards
    setupEventListeners();
  
    // Observe changes to attach listeners to new indicators
    this.indicatorObserver = new MutationObserver(() => {
      setupEventListeners();
    });
  
    // Start observing the timeline container for added children
    const timeline = document.querySelector('.timeline');
    if (timeline) {
      this.indicatorObserver.observe(timeline, { childList: true, subtree: true });
    }
  
    // Observe changes to attach listeners to new annotation cards
    this.annotationObserver = new MutationObserver(() => {
      setupEventListeners();
    });
  
    // Start observing the annotation list for added children
    const annotationList = document.querySelector('.annotation-list');
    if (annotationList) {
      this.annotationObserver.observe(annotationList, { childList: true, subtree: true });
    }
  
    // Initialize the sets if not already initialized
    if (!this.annotationCardsWithListeners) {
      this.annotationCardsWithListeners = new Set();
    }
    if (!this.deleteButtonsWithListeners) {
      this.deleteButtonsWithListeners = new Set();
    }
  }
  
  

  cleanupIndicatorEventListeners() {
    if (this.indicatorObserver) {
      this.indicatorObserver.disconnect();
      this.indicatorObserver = null;
    }
  
    // Remove event listeners from indicators
    this.indicatorsWithListeners.forEach((indicator) => {
      //indicator.removeEventListener('mouseenter', this.handleMouseEnter);
      //indicator.removeEventListener('mouseleave', this.handleMouseLeave);
      indicator.removeEventListener('click',(e)=> this.handleIndicatorClick(e));
      // Remove hover event listeners
      /*indicator.removeEventListener('mouseenter', this.handleIndicatorHoverEnter);
      indicator.removeEventListener('mouseleave', this.handleIndicatorHoverLeave);
      */
    });
  
    // Clear the indicators set
    this.indicatorsWithListeners.clear();
  
    // Remove event listeners from annotation cards
    if (this.annotationCardsWithListeners) {
      this.annotationCardsWithListeners.forEach((card) => {
        //card.removeEventListener('mouseenter', this.handleMouseEnter);
        //card.removeEventListener('mouseleave', this.handleMouseLeave);
        card.removeEventListener('click', this.handleCardClick);
      });
  
      // Clear the annotation cards set
      this.annotationCardsWithListeners.clear();
    }
  
    // Remove event listeners from delete buttons
    if (this.deleteButtonsWithListeners) {
      this.deleteButtonsWithListeners.forEach((deleteButton) => {
        deleteButton.removeEventListener('click', this.handleDeleteButtonClick);
      });
  
      // Clear the delete buttons set
      this.deleteButtonsWithListeners.clear();
    }
  }
  

  handleDeleteButtonClick = (e) => {
    e.stopPropagation(); 
    e.preventDefault(); 
    e.currentTarget.blur();
  
    const deleteButton = e.currentTarget;
    const cardElem = deleteButton.closest('.annotation-card');
    const time = parseFloat(cardElem.getAttribute('data-time'));
    
    // Call toggleDeleteCanvas with the remoteTime parameter
    this.args.canvasManager.toggleDeleteCanvas(time);
  };
  

/*
// Method to handle hover enter event
handleIndicatorHoverEnter = (e) => {
  const indicatorElem = e.currentTarget;
  const timeId = indicatorElem.id.split('-')[1]; // Assuming id="indicator-420.8"
  const timeInSeconds = parseFloat(timeId);
  const formattedTime = formatDuration(timeInSeconds);

  // Create tooltip element
  const tooltip = document.createElement('div');
  tooltip.className = 'indicator-tooltip';
  tooltip.innerText = formattedTime;

  // Position the tooltip above the indicator
  tooltip.style.position = 'absolute';
  tooltip.style.bottom = '100%';
  tooltip.style.left = '50%';
  tooltip.style.transform = 'translateX(-50%) translateY(-5px)';
  tooltip.style.pointerEvents = 'none'; // Ensure it doesn't interfere with hover
  tooltip.style.zIndex = '10';

  // Attach tooltip to the indicator
  indicatorElem.appendChild(tooltip);

  // Store reference to the tooltip for later removal
  indicatorElem._tooltip = tooltip;
};

// Method to handle hover leave event
handleIndicatorHoverLeave = (e) => {
  const indicatorElem = e.currentTarget;
  const tooltip = indicatorElem._tooltip;

  if (tooltip) {
    indicatorElem.removeChild(tooltip);
    delete indicatorElem._tooltip;
  }
};
*/

// Ensure 'this' refers to the class instance in handlers
/*handleMouseEnter(e) {
  e.target.classList.add('hovered');
}

handleMouseLeave(e) {
  e.target.classList.remove('hovered');
}
*/

// Add this method to handle card clicks
handleCardClick = (e) => {

  const {setDrawMode} = this.args;

  const targetTime = parseFloat(e.currentTarget.id.split('-')[2]); // Assuming id="annotation-card-2.2"



  // Link to the existing indicatorClicked function
  this.indicatorClicked(targetTime);
    // Apply glow to the clicked card and corresponding indicator

  this.applyGlow(targetTime);



  

};


handleIndicatorClick = async (e) => {

  const {setDrawMode, presentationMode} = this.args;
  
  // Extract the default targetTime from the id.
  // For an indicator with id like "indicator-2.2-4.5", we split by '-' and check if a third element exists.
  const parts = e.currentTarget.id.split('-');
  let targetTime = parseFloat(parts[1]); // default: the focus offset
  
  // If the indicator has a data attribute for actualTime, use that.
  if (e.currentTarget.dataset.actualTime) {
    targetTime = parseFloat(e.currentTarget.dataset.actualTime);
  }

  
  // Now call indicatorClicked with the targetTime.
  this.indicatorClicked(targetTime);

  
  
  // And apply glow to the clicked indicator and corresponding card.
  this.applyGlow(targetTime);
};



// Add this helper method to manage glow effects
applyGlow(targetTime) {
  // Escape the targetTime in case it contains special characters
  const safeTargetTime = CSS.escape(targetTime);

  // Remove existing glow from all indicators and cards
  const allIndicators = this.args.document.querySelectorAll('.timeline-indicator');

  allIndicators.forEach(indicator => indicator.classList.remove('glowing'));

  // Add glow to the selected indicator
  const selectedIndicator = this.args.document.querySelector(`#indicator-${safeTargetTime}`);
  if (selectedIndicator) {
    selectedIndicator.classList.add('glowing');
  }

}


  
// Function to handle clicking on a timeline indicator
indicatorClicked(targetTime) {
  const {
    loading,
    videoRef,
    canvasManager,
    setCanvasEdited,
    setDrawMode,
    resetStatus,
    setLastPausedTime,
    canvasStatus,
    currentTimeElem,
    timelineContainer,
    setPlaybackState,
    setAnnotationCommentary,
    setAnnotationTitle,
    presentationMode, annotationFocusList, focusIndex, 
  } = this.args;



  if (loading) return;
  if (!videoRef.current) return;



  // Pause the video
  videoRef.current.pause();
  setPlaybackState("paused");

  // Remove any restart icons and clear the canvas
  canvasManager.removeRestartIcon();
  canvasManager.clearCanvas();

  // Reset states
  setCanvasEdited(false);
  
  if (!presentationMode){
    setDrawMode("editing");
  }
  

  setAnnotationTitle("");
  setAnnotationCommentary("");
  resetStatus();


  const relativeTargetTime = presentationMode ? annotationFocusList[focusIndex].offsetTime : targetTime;
  // Move the video to the specific time
  videoRef.current.currentTime = relativeTargetTime;
  // Update the last paused time
  setLastPausedTime(relativeTargetTime.toFixed(4).toString());

  // Check if there is a saved canvas for this time
  const currTime = relativeTargetTime.toFixed(4).toString();
  const timeExists = canvasStatus[getClosestKey(currTime, canvasStatus)] === 'P' || canvasStatus[getClosestKey(currTime, canvasStatus)] === 'U';
  const relativeTimeExists = presentationMode ? true : timeExists;


  if (relativeTimeExists) {
    // Draw the saved tracing if it exists
    this.drawTracing(true);
  }

  // Update the timeline and current time display
  handleTimeUpdate(videoRef, currentTimeElem, timelineContainer);



}



handleMouseMove(e) {
  const { timelineContainer, videoRef } = this.args;
  if (!timelineContainer || !videoRef.current) return;

  // Get (or create) the preview element
  let preview = timelineContainer.querySelector('.timeline-preview');
  if (!preview) {
    preview = document.createElement('div');
    preview.className = 'timeline-preview';
    timelineContainer.appendChild(preview);
  }

  // Ensure the time and title elements exist
  let timeSpan = preview.querySelector('.preview-time');
  let titleSpan = preview.querySelector('.preview-title');
  if (!timeSpan) {
    timeSpan = document.createElement('span');
    timeSpan.className = 'preview-time';
    preview.appendChild(timeSpan);
  }
  if (!titleSpan) {
    titleSpan = document.createElement('span');
    titleSpan.className = 'preview-title';
    preview.appendChild(titleSpan);
  }
  const rect = timelineContainer.getBoundingClientRect();
  // Use an offset on the Y coordinate for the hit test
  const hoveredIndicator = document.elementFromPoint(e.clientX, e.clientY + 10)
                              ?.closest('.timeline-indicator');
  if (hoveredIndicator) {
    const timeId = hoveredIndicator.id.split('-')[1]; // e.g. "2.2"
    const annotationTitle = hoveredIndicator.dataset.title || '';
    timeSpan.textContent = formatDuration(parseFloat(timeId));
    titleSpan.textContent = annotationTitle;

    titleSpan.style.display = 'inline';

    preview.classList.add('indicator-preview');
    preview.style.left = hoveredIndicator.style.left;
    preview.style.display = 'flex';
    preview.classList.add('visible');
    this.currentHoveredIndicator = hoveredIndicator;
  } else {
    const percent = Math.min(Math.max(0, e.clientX - rect.left), rect.width) / rect.width;
    const previewTime = percent * videoRef.current.duration;


    timelineContainer.style.setProperty('--preview-position', percent);
    titleSpan.textContent = '';
    titleSpan.style.display = 'none';

    timeSpan.textContent = formatDuration(previewTime);
    titleSpan.textContent = ''; // Hide title if not hovering an indicator
    preview.classList.remove('indicator-preview');
    preview.style.left = `${e.clientX - rect.left}px`;
    preview.style.display = 'flex';
    preview.classList.add('visible');
    this.currentHoveredIndicator = null;
  }
}
  // Add this method to handle mouse leave
  handleMouseLeave = () => {
    const { timelineContainer } = this.args;
    const preview = timelineContainer.querySelector('.timeline-preview');
    if (preview) {
      preview.style.display = 'none';
      preview.classList.remove('visible');
    }
    this.currentHoveredIndicator = null;
  }

  cleanup() {
    if (this.args && this.args.timelineContainer) {
      this.args.timelineContainer.removeEventListener('mousemove', this.handleMouseMove);
      this.args.timelineContainer.removeEventListener('mouseleave', this.handleMouseLeave);
    }
  }
}

/**
 * @typedef {Object} CanvasManagerArgs
 * @property {React.RefObject<HTMLCanvasElement>} coldCanvasRef - Reference to the cold canvas element.
 * @property {function(boolean):void} setCanvasEdited - Function to set the canvas edited status.
 * @property {function(string):void} setDrawMode - Function to set the drawing mode.
 * @property {boolean} loading - Loading state.
 * @property {number} lastPausedTime - The last paused time of the video.
 * @property {React.RefObject<HTMLVideoElement>} videoRef - Reference to the video element.
 * @property {string} videoLink - Link to the video.
 * @property {Document} document - The document object.
 * @property {string} canvasStatus - The current status of the canvas.
 * @property {boolean} canvasEdited - Indicates whether the canvas has been edited.
 * @property {function():void} resetStatus - Function to reset the status.
 * @property {function(string):void} updateCanvasStatus - Function to update the canvas status.
 * @property {function(string):void} deleteCanvasStatus - Function to delete the canvas status.
 * @property {HTMLElement} videoContainer - The container element for the video.
 */
/**
 *
 * Manages the canvas element of the video player
 * @export
 * @class CanvasManager
 */
export class CanvasManager{
  /**
   * Creates an instance of CanvasManager.
   * @memberof CanvasManager
   */
  constructor(){
    this.args = {
      coldCanvasRef: null,
      setCanvasEdited: null,
      setDrawMode: null,
      loading: null,
      lastPausedTime: null,
      videoRef: null,
      videoLink: null,
      document: null,
      canvasStatus: null,
      canvasEdited: null,
      resetStatus: null,
      updateCanvasStatus: null,
      deleteCanvasStatus: null,
      videoContainer: null,
      videoEditContainer: null,
      setLastPausedTime: null,
      currentTimeElem: null,
      timelineContainer: null,
      speedBtn: null,
      token: null,
      setPlaybackState: null,
      annotationTitle: null,
      annotationCommentary: null,
      qualityEnforced: false
    };
  }
  /**
   *
   * Sets the arguments for the CanvasManager
   * @param {CanvasManagerArgs} args
   * @memberof CanvasManager
   */
  setArgs(args){
    this.args = args;
  }
  /**
   *
   * Clears any drawings on the canvas
   * @memberof CanvasManager
   */
  clearCanvas() {
    const { coldCanvasRef, setCanvasEdited, setHasUnsavedChanges } = this.args;
    if (!coldCanvasRef.current) return;
    const ctx_cold = coldCanvasRef.current.getContext('2d');
    ctx_cold.clearRect(0, 0, coldCanvasRef.current.width, coldCanvasRef.current.height);
  
    setCanvasEdited(false);
    setHasUnsavedChanges(false);
  }
/**
 *
 * Saves the canvas in the database and visually,
 * and also captures the underlying video frame.
 *
 * @memberof CanvasManager
 */
async saveCanvas() {
  const {
    videoId,
    token,
    loading,
    coldCanvasRef,
    lastPausedTime,
    videoRef,
    videoLink,
    canvasEdited,
    document,
    updateCanvasStatus,
    setDrawMode,
    canvasStatus,
    annotationTitle,
    annotationCommentary,
    setAnnotationTitle,
    setAnnotationCommentary,
    setHasUnsavedChanges, 
    setSavingAnnotation,
    hlsRef,
    availableLevels,
    savingAnnotation,
    setRefreshAnnotationsCounter,
    presentationMode,
    editingAnnotation,               // whether we are editing
    annotationFocusList,             // list of saved annotations (including annotationId)
    setEditingAnnotation,
    annotationStrokes,               // New: array of stroke data URLs from useState
    setAnnotationStrokes
  } = this.args;

  if (
    loading ||
    !videoRef.current ||
    !coldCanvasRef.current ||
    savingAnnotation ||
    this.args.qualityEnforced ||
    presentationMode
  )
    return;

  // --- Force Highest Quality Track ---
  if (!this.args.qualityEnforced && hlsRef && hlsRef.current && availableLevels && availableLevels.length > 0) {
    let highestQualityIndex = 0;
    availableLevels.forEach((level, i) => {
      if (level.height > availableLevels[highestQualityIndex].height) {
        highestQualityIndex = i;
      }
    });
    hlsRef.current.nextLevel = highestQualityIndex;
    console.log(`Forcing highest quality track: index ${highestQualityIndex}`);
    this.args.qualityEnforced = true;
    await new Promise(resolve => setTimeout(resolve, 500));
  }

  // Use the video element to capture dimensions and current time
  const currTime = lastPausedTime;
  const video = videoRef.current;
  const canvasWidth = video.videoWidth;
  const canvasHeight = video.videoHeight;
  const rect = video.getBoundingClientRect();
  const videoWidth = rect.width;
  const videoHeight = rect.height;
  const timeValid =
    !isNaN(parseFloat(currTime)) &&
    parseFloat(currTime) > 0 &&
    parseFloat(currTime) < videoRef.current.duration;

  if (!videoLink) {
    console.error('videoLink is missing');
    return;
  }

  if (canvasEdited && timeValid) {
    // Compute position and update UI status
    const positionPercentage = calculatePositionPercentage(parseFloat(currTime), videoRef);
    updateCanvasStatus(currTime, 'P');
    addTimelineIndicator(currTime, positionPercentage, document, annotationTitle);

    try {
      if (editingAnnotation) {
        // Editing: merge new strokes with the saved strokes array
        const threshold = 0.1;
        const match = annotationFocusList.find(ann =>
          Math.abs(parseFloat(ann.time) - parseFloat(currTime)) < threshold
        );
        if (!match) {
          console.error("No matching annotation found to update");
          return;
        }
        const annotationId = match.annotationId;

        // Fetch the existing annotation from the database
        const annotationResponse = await axios.get(`${API_URL}/annotation/${annotationId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const annotation = annotationResponse.data;
        if (!annotation || !annotation.annotationData) {
          console.error("No saved annotation strokes found in the annotation data.");
          return;
        }
        // Expect the saved annotation data to be an array of strokes
        const savedStrokes = Array.isArray(annotation.annotationData) ? annotation.annotationData : [];
        const mergedStrokes = [...savedStrokes, ...annotationStrokes];

        const updatePayload = {
          annotationId,
          title: annotationTitle,
          description: annotationCommentary,
          annotationData: mergedStrokes
        };

        setSavingAnnotation(true);
        await axios.put(`${API_URL}/annotation/${annotationId}`, updatePayload, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setSavingAnnotation(false);
        setRefreshAnnotationsCounter(prev => prev + 1);
      } else {
        // New annotation: use the annotationStrokes array as the drawing data
        const annotationId = uuidv4();
        const focusString = generateFocus(videoRef.current.currentTime, videoRef.current.duration);

        // Capture the video frame from the video element.
        const videoFrameData = captureVideoFrame(video);
        let upscaledVideoFrameData = videoFrameData;
        /* Optionally upscale here if needed */

        const annotationPayload = {
          title: annotationTitle,
          description: annotationCommentary,
          annotationId,
          createdDate: new Date().toISOString(),
          time: currTime,
          // Use the strokes array instead of a single composite image
          annotationData: annotationStrokes,
          videoFrameData: upscaledVideoFrameData,
          canvasWidth,
          canvasHeight,
          videoWidth,
          videoHeight,
          focus: focusString,
          videoPath: videoLink,
          videoId
        };

        setSavingAnnotation(true);
        await axios.post(`${API_URL}/annotation`, annotationPayload, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setSavingAnnotation(false);

        // Save metadata
        await axios.post(
          `${API_URL}/meta_annotation`,
          {
            videoId,
            annotationId,
            time: currTime,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // Add the new annotation card to the UI
        const newAnnotation = {
          annotationId,
          title: annotationTitle,
          description: annotationCommentary,
          time: currTime,
        };
        addAnnotationCard(newAnnotation, document);
        setRefreshAnnotationsCounter(prev => prev + 1);
      }

      // Reset UI state in both cases
      setDrawMode("");
      setAnnotationTitle("");
      setAnnotationCommentary("");
      setHasUnsavedChanges(false);
    } catch (error) {
      console.error('Error saving canvas and metadata:', error);
    } finally {
      this.args.qualityEnforced = false;
      setEditingAnnotation(false);
      // Reset the annotation strokes state after saving
      setAnnotationStrokes([]);
    }
  }
}
  
  /**
   * Deletes canvas and associated metadata from the database.
   *
   * @param {number} currTime - The time of the annotation to delete.
   */
  async deleteCanvas(currTime) {
    const { videoId, token, canvasStatus, setDrawMode, setCanvasEdited, 
      setAnnotationCommentary, setAnnotationTitle, setRefreshAnnotationsCounter, presentationMode } = this.args;

      if (presentationMode) return;

    const timeCanvas = canvasStatus[getClosestKey(currTime, canvasStatus)] === "P" || canvasStatus[getClosestKey(currTime, canvasStatus)] === "U";


    if (timeCanvas) {
      // Update UI after successful deletion
      setDrawMode("");
      setAnnotationTitle("");
      setAnnotationCommentary("");
      setCanvasEdited(false);

      const timeAsString = (typeof currTime === "number" ? currTime : parseFloat(currTime)).toFixed(4);
      try {
        // Directly attempt to delete annotation and metadata from the server
        await axios.delete(`${API_URL}/annotation`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            videoId: videoId,
            time: timeAsString  // Pass time as a string with 4 decimals
          }
        });

        setRefreshAnnotationsCounter(prev => prev-1);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Suppress the error entirely (no log needed)
        } else {
          // Log other errors (for debugging purposes)
          console.error('Error deleting canvas and metadata:', error.response ? error.response.data : error.message);
        }
      }
    } else {
      console.error('No canvas to delete at this time.');
    }
  }

  
  
  
/**
 * Toggles the deletion of the canvas and associated annotation.
 *
 * @param {number} [remoteTime=-1] - The time of the annotation to delete. If -1, uses the current paused time.
 */
toggleDeleteCanvas(remoteTime = -1) {
  const { setPendingAction, setPendingParams, lastPausedTime, canvasStatus, hasUnsavedChanges, presentationMode } = this.args;

  if (!presentationMode){
    const currTime = remoteTime > -1 ? remoteTime : lastPausedTime;
    const timeExists = canvasStatus[getClosestKey(currTime, canvasStatus)] === "P" || canvasStatus[getClosestKey(currTime, canvasStatus)] === "U";

    if (hasUnsavedChanges || timeExists) {
      setPendingParams({ remoteTime: currTime }); // Store remoteTime immediately
      setPendingAction("deleteAnnotation"); // 🔥 Store it as a **simple string**
    }
  } else{
    this.confirmedDeleteAnnotation(remoteTime);
  }
}

/**
 * Confirms the deletion of an annotation.
 * @param {number} [remoteTime=-1] - The time of the annotation to delete. If -1, uses the current paused time.
 */

// previously confirmedDeleteAnnotation
async confirmedDeleteAnnotation(remoteTime = -1) {
  const { lastPausedTime, canvasStatus, deleteCanvasStatus, videoRef, document, setAnnotationStrokes } = this.args;
  if (!videoRef.current) return;




  const currTime = remoteTime > -1 ? remoteTime : lastPausedTime;



  const timeExists = canvasStatus[getClosestKey(currTime, canvasStatus)] === "P" || canvasStatus[getClosestKey(currTime, canvasStatus)] === "U";


  if (timeExists) {
    this.clearCanvas();
    removeAnnotation(currTime, document);
    await this.deleteCanvas(currTime);
    deleteCanvasStatus(currTime);
    setAnnotationStrokes([]);
  } else {
    this.clearCanvas();
  }
}
  

  
  
  
  /**
   *
   * Toggles the fullscreen mode of the video
   * @memberof CanvasManager
   */
  toggleFullScreenMode() {
    const { videoRef, document, videoContainerRef, setPendingAction, drawMode, setHasUnsavedChanges, hasUnsavedChanges , presentationMode} = this.args;
    if (!videoRef.current) return;
    // If user is actively drawing, show a prompt
    if (hasUnsavedChanges && !presentationMode) {
      // Are we entering or exiting?
      if (!document.fullscreenElement) {
        setPendingAction("enterFullscreen");

      } else {
        setPendingAction("exitFullscreen");

      }
      // Pause so video doesn’t keep playing behind the prompt
      videoRef.current.pause();
      return;
    }
  
    // If user NOT drawing, do the operation right away
    if (!document.fullscreenElement) {
      videoContainerRef.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  
  
  /**
   *
   * Toggles the container containing editing tools
   * @memberof CanvasManager
     */
  toggleVideoEditContainer() {
    const { videoEditContainer } = this.args;
    if (videoEditContainer.style.display === "none") {
      videoEditContainer.style.display = "flex";
      setTimeout(() => {
        videoEditContainer.style.opacity = "0.8";
      }, 10);
    } else {
      videoEditContainer.style.opacity = "0";
      setTimeout(() => {
        videoEditContainer.style.display = "none";
      }, 150);
    }
  }


/**
 * Toggles the play/pause of the video, optionally skipping the "unsaved changes" prompt.
 * @memberof CanvasManager
 */
togglePlay({
  override = false,
  playbackState,
  setPlaybackState,
  playPauseInProgress,
  skipPrompt = false,
}) {
  const {
    videoRef,
    setDrawMode,
    setCanvasEdited,
    loading,
    setLastPausedTime,
    setAnnotationCommentary,
    setAnnotationTitle,
    setPendingAction,
    drawMode,
    setHasUnsavedChanges,
    hasUnsavedChanges,
    presentationMode
  } = this.args;

  if (!videoRef.current || !playPauseInProgress) return;
  if (loading || playPauseInProgress.current) return;

  // 1) If we have NOT overridden prompt logic and user is still drawing => show prompt
  if (!skipPrompt && hasUnsavedChanges && !presentationMode) {
    videoRef.current.pause();
    setPendingAction("play");   // So the prompt knows "we want to resume playing"
    return;
  }

  // 2) Otherwise proceed with the normal logic
  playPauseInProgress.current = true;

  // Clear any drawing states if you want to discard unsaved edits
  setDrawMode("");
  setAnnotationTitle("");
  setAnnotationCommentary("");
  this.clearCanvas();
  setCanvasEdited(false);

  // Check if video is at or near the end
  const currTime = parseFloat(videoRef.current.currentTime.toFixed(4));
  const duration = parseFloat(videoRef.current.duration.toFixed(4));

  // If at the end, reset to 0 and play
  if (currTime === duration) {
    this.removeRestartIcon();
    setLastPausedTime("0.0");
    videoRef.current.currentTime = 0;

    videoRef.current.play()
      .then(() => {
        setPlaybackState("playing");
      })
      .catch((error) => {
        console.error('Play error:', error);
        setPlaybackState('paused');
      })
      .finally(() => {
        playPauseInProgress.current = false;
      });
    return;
  }

  // Normal play/pause toggle
  if (videoRef.current.paused) {
    videoRef.current.play()
      .then(() => {
        setPlaybackState('playing');
      })
      .catch((error) => {
        console.error('Play error:', error);
        setPlaybackState('paused');
      })
      .finally(() => {
        playPauseInProgress.current = false;
      });

  } else {
    // The video is playing; user wants to pause
    if (videoRef.current.currentTime.toFixed(4) >= 0 || override) {
      videoRef.current.pause();
      setPlaybackState('paused');
      playPauseInProgress.current = false;
    } else {
      playPauseInProgress.current = false;
    }
  }
}


/**
 * Advances the video by 5 seconds, optionally skipping the "unsaved changes" prompt.
 * @memberof CanvasManager
 */
forwardTime({
  playbackState,
  setPlaybackState,
  playPauseInProgress,
  skipPrompt = false,
} = {}) {
  const {
    videoRef,
    drawMode,
    setPendingAction,
    loading,
    setLastPausedTime,
    canvasStatus,
    setCanvasStatus,
    currentTimeElem,
    timelineContainer,
    resetStatus,
    setDrawMode,
    setHasUnsavedChanges,
    hasUnsavedChanges, presentationMode
  } = this.args;

  if (loading || !videoRef.current) return;


  

  // 1) If user is drawing & we haven't skipped prompt => ask for confirmation
  if (!skipPrompt && hasUnsavedChanges && !presentationMode) {
    videoRef.current.pause();
    setPendingAction("forwardTime");
    return;
  }

  // 2) The normal skip logic
  this.clearCanvas();

  const currentTime = videoRef.current.currentTime;
  const duration = videoRef.current.duration;
  const timeLeft = duration - currentTime;
  videoRef.current.currentTime = (timeLeft < 5) ? duration : currentTime + 5;

  // Then all your existing code:
  setLastPausedTime(videoRef.current.currentTime.toFixed(4).toString());
  resetStatus(canvasStatus, setCanvasStatus);
  handleTimeUpdate(videoRef, currentTimeElem, timelineContainer);

  // Possibly auto-play after skipping:
  if (currentTime + 5 < duration) {
    this.togglePlay({ override: true, playbackState, setPlaybackState, playPauseInProgress });
    if (!videoRef.current.paused) {
      this.togglePlay({ override: true, playbackState, setPlaybackState, playPauseInProgress });
    }
  } else {
    this.toggleRestartIcon();
  }
}




/**
 * Goes back in time by 5 seconds, optionally skipping the "unsaved changes" prompt.
 * @memberof CanvasManager
 */
backTime({
  playbackState,
  setPlaybackState,
  playPauseInProgress,
  skipPrompt = false
} = {}) {
  const {
    videoRef,
    setLastPausedTime,
    canvasStatus,
    setCanvasStatus,
    currentTimeElem,
    timelineContainer,
    resetStatus,
    loading,
    drawMode,
    setPendingAction,
    setHasUnsavedChanges,
    hasUnsavedChanges,
    presentationMode
  } = this.args;

  if (loading || !videoRef.current) return;

  // 1) If user is drawing and skipPrompt == false => show the confirm
  if (!skipPrompt && hasUnsavedChanges && !presentationMode) {
    videoRef.current.pause();
    setPendingAction("backTime");
    return;
  }

  // 2) Otherwise, proceed with the normal back 5s logic
  this.clearCanvas();

  const currentTime = videoRef.current.currentTime;
  const newTime = currentTime - 5;
  videoRef.current.currentTime = newTime < 0 ? 0 : newTime;

  setLastPausedTime(videoRef.current.currentTime.toFixed(4).toString());
  resetStatus(canvasStatus, setCanvasStatus);
  handleTimeUpdate(videoRef, currentTimeElem, timelineContainer);

  // The existing logic toggling play
  this.togglePlay({
    override: true,
    playbackState,
    setPlaybackState,
    playPauseInProgress
  });

  if (!videoRef.current.paused) {
    this.togglePlay({
      override: true,
      playbackState,
      setPlaybackState,
      playPauseInProgress
    });
  }

  this.removeRestartIcon();
}
  /**
   *
   * Handles the key-bound video interactions
   * @param {*} e
   * @memberof CanvasManager
   */
  keyDown(e, playbackState, setPlaybackState, playPauseInProgress) {
    const { videoRef, speedBtn, loading, drawMode, pendingAction, presentationMode } = this.args;
  
    // 1) If user is focusing an input or textarea, let them type
    const activeEl = document.activeElement;
    const isTypingField = activeEl && (activeEl.tagName.toLowerCase() === 'input' 
                                    || activeEl.tagName.toLowerCase() === 'textarea');
    if (isTypingField) {
      // do nothing => let the keystroke pass to the form
      return;
    }
  
    // 2) If a custom overlay is up, block all keys
    if (document.querySelector(".custom-overlay")) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  
    if (!videoRef.current) return;
    if (loading) return;
  
    e.preventDefault();
    e.stopPropagation();
  
    switch (e.key.toLowerCase()) {
      default:
        break;
      case "arrowright":
        this.forwardTime();
        break;
      case "arrowleft":
        this.backTime();
        break;
      case " ":
        this.togglePlay({ playbackState, setPlaybackState, playPauseInProgress });
        break;
      case "f":
        if (!presentationMode) return;
        this.toggleFullScreenMode();
        break;
      case "backspace":
        this.toggleDeleteCanvas();
        break;
      case "s":
        this.saveCanvas();
        break;
      case "+":
      case "=":
        changePlaybackSpeed(videoRef, speedBtn);
        break;
      case "escape":
        e.preventDefault();
        e.stopPropagation();
        break;
    }
  }
  /**
   *
   * Removes the restart icon
   * @return {*} 
   * @memberof CanvasManager
   */
  removeRestartIcon(){
    const {restartIconRef} = this.args;
    if (!restartIconRef.current) return;
    restartIconRef.current.style.display = 'none';
  }
  /**
   *
   * Toggles the restart icon
   * @return {*} 
   * @memberof CanvasManager
   */
  toggleRestartIcon() {
    const {restartIconRef} = this.args;
    if (!restartIconRef.current) return;
    if (restartIconRef.current) {
      restartIconRef.current.style.display = 'flex';
    }
  };
}

/**
 * @typedef {Object} DrawingArgs
 * @property {HTMLCanvasElement} hotCanvas
 * @property {HTMLCanvasElement} coldCanvas
 * @property {CanvasRenderingContext2D | null} ctx_cold
 * @property {CanvasRenderingContext2D | null} ctx_hot
 * @property {number} startX
 * @property {number} startY
 * @property {string} color
 * @property {number} thickness
 * @property {string} drawMode
 */
/**
 *
 * Handles the drawing on the canvas
 * @export
 * @class Drawing
 */
export class Drawing {
  /**
   * Creates an instance of Drawing.
   * @memberof Drawing
   */
  constructor() {
    /** @type {DrawingArgs} */
    this.args = {
      hotCanvas: null,
      coldCanvas: null,
      ctx_cold: null,
      ctx_hot: null,
      startX: null,
      startY: null,
      color: null,
      thickness: null,
      drawMode: null,
      hasUnsavedChanges: null,
      strokeFlag: null,
    };
    this.drawFunctionMap = {
      pen: {
        start: this.startDrawingPen.bind(this),
        draw: this.drawPen.bind(this),
        stop: this.stopDrawingPen.bind(this),
      },
      circle: {
        start: this.startDrawingCircle.bind(this),
        draw: this.drawCircle.bind(this),
        stop: this.stopDrawingCircle.bind(this),
      },
      arrow: {
        start: this.startDrawingArrow.bind(this),
        draw: this.drawArrow.bind(this),
        stop: this.stopDrawingArrow.bind(this),
      },
    };
  }
  /**
   *
   * Sets the drawing styles
   * @memberof Drawing
   */
  setDrawStyle() {
    const { color, thickness, ctx_hot, ctx_cold } = this.args;
    ctx_cold.strokeStyle = color;
    ctx_hot.strokeStyle = color;
    ctx_cold.lineWidth = thickness;
    ctx_hot.lineWidth = thickness;
  }
  /**
   * Sets the paramaters of the drawing tool, updates cursor and drawing style
   * @param {DrawingArgs} args  - 
   */
  updateDrawConfig(args) {
    this.args = args;
    this.setDrawStyle();
    this.clearCursor();
    this.setCursor();
  }
  /**
   *
   * Initializes the drawing tool for pen drawing
   * @param {*} - event
   * @memberof Drawing
   */
  startDrawingPen(e) {
    const { hotCanvas,  ctx_hot, setHasUnsavedChanges, setStrokeFlag } = this.args;
    setHasUnsavedChanges(true);
    setStrokeFlag(true);
    const svgOffsetY = 5;
    const svgOffsetX = -24;
    const rect = hotCanvas.getBoundingClientRect();
    const scaleX = hotCanvas.width / rect.width;
    const scaleY = hotCanvas.height / rect.height;
    ctx_hot.beginPath();
    ctx_hot.moveTo((e.clientX + svgOffsetX - rect.left) * scaleX, (e.clientY + svgOffsetY - rect.top) * scaleY);
  }
  /**
   *
   * Draws the pen on the canvas
   * @param {*} e - event
   * @memberof Drawing
   */
  drawPen(e) {
    const { hotCanvas, ctx_hot } = this.args;
    const svgOffsetY = 5;
    const svgOffsetX = -24;
    const rect = hotCanvas.getBoundingClientRect();
    const scaleX = hotCanvas.width / rect.width;
    const scaleY = hotCanvas.height / rect.height;
    ctx_hot.lineTo((e.clientX + svgOffsetX - rect.left) * scaleX, (e.clientY + svgOffsetY - rect.top) * scaleY);
    ctx_hot.stroke();
  }
  /**
   *
   * Handles the stopping of the pen drawing
   * @memberof Drawing
   */
  stopDrawingPen() {
    const { hotCanvas, ctx_hot, ctx_cold, setAnnotationStrokes, setStrokeFlag, strokeFlag  } = this.args;
    if (!strokeFlag) return;
    setStrokeFlag(false);
    ctx_hot.closePath();
    ctx_cold.drawImage(hotCanvas, 0, 0);
    const strokeData = hotCanvas.toDataURL();
    setAnnotationStrokes(prev => [...prev, strokeData]);
    ctx_hot.clearRect(0, 0, hotCanvas.width, hotCanvas.height);
  }
  /**
   *
   * Initializes the drawing tool for circle drawing
   * @param {*} e
   * @memberof Drawing
   */
  startDrawingCircle(e) {
    const { hotCanvas, startX, startY, setHasUnsavedChanges, setStrokeFlag } = this.args;
    setHasUnsavedChanges(true);
    setStrokeFlag(true);
    startX.current = e.clientX - hotCanvas.getBoundingClientRect().left;
    startY.current = e.clientY - hotCanvas.getBoundingClientRect().top;
    const rect = hotCanvas.getBoundingClientRect();
    const scaleX = hotCanvas.width / rect.width;
    const scaleY = hotCanvas.height / rect.height;
    startX.current = (e.clientX - rect.left) * scaleX ;
    startY.current = (e.clientY - rect.top) * scaleY;
  }
  /**
   *
   * Draws the circle on the canvas
   * @param {*} e
   * @memberof Drawing
   */
  drawCircle(e) {
    const { hotCanvas, startX, startY, ctx_hot } = this.args;
    const rect = hotCanvas.getBoundingClientRect();
    const scaleX = hotCanvas.width / rect.width;
    const scaleY = hotCanvas.height / rect.height;
    const currentX = (e.clientX - rect.left) * scaleX ;
    const currentY = (e.clientY - rect.top) * scaleY;

    const shift = -25;
    const shifted_r = Math.sqrt((currentX - startX.current) ** 2 + (currentY - startY.current) ** 2) +shift;
    const radius = shifted_r > 0 ? shifted_r : 0;

    ctx_hot.clearRect(0, 0, hotCanvas.width, hotCanvas.height);
    ctx_hot.beginPath();
    ctx_hot.arc(startX.current, startY.current, radius, 0, 2 * Math.PI);
    ctx_hot.stroke();
  }
  /**
   *
   * Handles the stopping of the drawing of the circle
   * @memberof Drawing
   */
  stopDrawingCircle() {
    const { hotCanvas, ctx_hot, ctx_cold, setAnnotationStrokes, setStrokeFlag, strokeFlag  } = this.args;
    if (!strokeFlag) return;
    setStrokeFlag(false);
    ctx_cold.drawImage(hotCanvas, 0, 0);
    const strokeData = hotCanvas.toDataURL();
    setAnnotationStrokes(prev => [...prev, strokeData]);
    ctx_hot.clearRect(0, 0, hotCanvas.width, hotCanvas.height);
  }

/**
 * Draws a single triangular arrowhead based on arrow direction.
 * @param {CanvasRenderingContext2D} ctx - The canvas context.
 * @param {number} arrowBaseX - X coordinate of where the arrow line stops.
 * @param {number} arrowBaseY - Y coordinate of where the arrow line stops.
 * @param {number} arrowTipX - X coordinate of the arrow tip.
 * @param {number} arrowTipY - Y coordinate of the arrow tip.
 * @param {number} halfBase - Half of the base width for the triangle.
 */
drawArrowHead(ctx, arrowBaseX, arrowBaseY, arrowTipX, arrowTipY, halfBase) {
  // Determine the angle from the arrow base to the tip.
  const angle = Math.atan2(arrowTipY - arrowBaseY, arrowTipX - arrowBaseX);
  
  // Calculate the two corners of the triangle.
  const corner1X = arrowBaseX - halfBase * Math.sin(angle);
  const corner1Y = arrowBaseY + halfBase * Math.cos(angle);
  const corner2X = arrowBaseX + halfBase * Math.sin(angle);
  const corner2Y = arrowBaseY - halfBase * Math.cos(angle);
  
  // Draw the triangle arrowhead.
  ctx.beginPath();
  ctx.moveTo(arrowTipX, arrowTipY);   // Tip of the arrow.
  ctx.lineTo(corner1X, corner1Y);       // First corner.
  ctx.lineTo(corner2X, corner2Y);       // Second corner.
  ctx.closePath();
  ctx.fillStyle = ctx.strokeStyle;      // Use the same color as the line.
  ctx.fill();
}
  /**
   *
   * Initializes the drawing tool for arrow drawing
   * @param {*} e
   * @memberof Drawing
   */
  startDrawingArrow(e) {
    const { hotCanvas, startX, startY, setHasUnsavedChanges, setStrokeFlag } = this.args;
    setHasUnsavedChanges(true);
    setStrokeFlag(true);
    startX.current = e.clientX - hotCanvas.getBoundingClientRect().left;
    startY.current = e.clientY - hotCanvas.getBoundingClientRect().top;
    const rect = hotCanvas.getBoundingClientRect();
    const scaleX = hotCanvas.width / rect.width;
    const scaleY = hotCanvas.height / rect.height;
    startX.current = (e.clientX - rect.left) * scaleX;
    startY.current = (e.clientY - rect.top) * scaleY;
  }
  /**
   *
   * Draws the arrow on the canvas
   * @param {*} e
   * @memberof Drawing
   */
  drawArrow(e) {
    const { hotCanvas, startX, startY, ctx_hot, color, thickness } = this.args;
    const rect = hotCanvas.getBoundingClientRect();
    const scaleX = hotCanvas.width / rect.width;
    const scaleY = hotCanvas.height / rect.height;
    
    // Determine the current mouse coordinates on the canvas.
    const currentX = (e.clientX - rect.left) * scaleX;
    const currentY = (e.clientY - rect.top) * scaleY;
    
    // Define the starting point and the tip of the arrow.
    const startPt = { x: startX.current, y: startY.current };
    const arrowTip = { x: currentX, y: currentY };
  
    // Calculate the total distance and angle.
    const dx = arrowTip.x - startPt.x;
    const dy = arrowTip.y - startPt.y;
    const totalLen = Math.sqrt(dx * dx + dy * dy);
    if (totalLen === 0) return; // Nothing to draw if there's no movement.
    const angle = Math.atan2(dy, dx);
    
    // Determine the arrowhead's length (adjustable via thickness).
    const arrowheadLen = Math.max(10, 12 + 1.5 * thickness);
    // The arrow line stops short of the tip by the arrowhead length.
    let lineLen = totalLen - arrowheadLen;
    if (lineLen < 0) lineLen = 0;
    
    // Compute the point where the arrow line ends and the arrowhead begins.
    const arrowBaseX = startPt.x + lineLen * Math.cos(angle);
    const arrowBaseY = startPt.y + lineLen * Math.sin(angle);
    
    // Clear the temporary (hot) canvas and set drawing styles.
    ctx_hot.clearRect(0, 0, hotCanvas.width, hotCanvas.height);
    ctx_hot.beginPath();
    ctx_hot.lineWidth = thickness;
    ctx_hot.strokeStyle = color;
    
    // Draw the arrow's shaft.
    ctx_hot.moveTo(startPt.x, startPt.y);
    ctx_hot.lineTo(arrowBaseX, arrowBaseY);
    ctx_hot.stroke();
    
    // Determine the half-base width for the arrowhead triangle.
    const halfBase = Math.max(4, (arrowheadLen / 3));
    
    // Draw the improved arrowhead.
    this.drawArrowHead(ctx_hot, arrowBaseX, arrowBaseY, arrowTip.x, arrowTip.y, halfBase);
  }
  /**
   *
   * Handles the stopping of the drawing of the arrow
   * @memberof Drawing
   */
  stopDrawingArrow() {
    const { hotCanvas, ctx_hot, ctx_cold, setAnnotationStrokes, setStrokeFlag, strokeFlag } = this.args;
    if (!strokeFlag) return;
    setStrokeFlag(false);
    ctx_cold.drawImage(hotCanvas, 0, 0);
    const strokeData = hotCanvas.toDataURL();
    setAnnotationStrokes(prev => [...prev, strokeData]);
    ctx_hot.clearRect(0, 0, hotCanvas.width, hotCanvas.height);
  }
  /**
   *
   * Clears all the cursor styles
   * @memberof Drawing
   */
  clearCursor() {
    const { hotCanvas, drawMode } = this.args;
    const colors = ["blue", "yellow", "green", "black", "white", "red"];
    switch (drawMode) {
      case "pen":

        for (const color of colors) {
          hotCanvas.classList.remove("pen-cursor-" + color);
        }
        hotCanvas.classList.remove("custom-cursor");
        hotCanvas.style.pointerEvents = 'auto';
        break;
      case "circle":
      case "arrow":
        for (const color of colors) {
          hotCanvas.classList.remove("pen-cursor-" + color);
        }
        hotCanvas.style.pointerEvents = 'auto';
        break;
      default:
        for (const color of colors) {
          hotCanvas.classList.remove("pen-cursor-" + color);
        }
        hotCanvas.style.pointerEvents = 'none';
        break;
    }
  }
  /**
   *
   * Sets the cursor style based on the drawing mode
   * @memberof Drawing
   */
  setCursor() {
    const { hotCanvas, color, drawMode } = this.args;
    if (drawMode === "pen") {
      switch (color) {
        default:
          const cursor = "pen-cursor-" + color;
          hotCanvas.classList.add(cursor);
          break;
        case '#61dafb':
          hotCanvas.classList.add("pen-cursor-blue");
          break;
      }
    } else if (drawMode === "circle" || drawMode === "arrow") {
      hotCanvas.classList.add('custom-cursor');
    }
  }
}

