import React, { useState, useEffect } from 'react';
import './CookieConsent.css';

const CookieConsent = () => {
  const STORAGE_KEY = 'cookieConsentAccepted';
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem(STORAGE_KEY);
    if (consent !== 'true') {
      setVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem(STORAGE_KEY, 'true');
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div className="cookie-consent">
      <div className="cookie-consent__message">
        <p>
          We use cookies to make our website work better for you. Please allow cookies so you can enjoy a smooth experience.
        </p>
      </div>
      <button className="cookie-consent__button" onClick={handleAccept}>
        OK, I Understand
      </button>
    </div>
  );
};

export default CookieConsent;