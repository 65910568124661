import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

import './account.css';
import { useUploadProgress } from '../context/UploadProgressContext.js';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as UploadVideoIcon } from '../../assets/upload-video.svg';



const API_URL = process.env.REACT_APP_API_URL;

const DESCRIPTION_THRESHOLD = 100;
const TITLE_THRESHOLD = 50;

const Account = () => {
  const { progress, isUploading, processing, uploadMetadata } = useUploadProgress();
  const navigate = useNavigate();
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [userInfo, setUserInfo] = useState(null);
  const [groups, setGroups] = useState([]);
  const [error, setError] = useState(null);

  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false);
  const [isEditGroupModalOpen, setIsEditGroupModalOpen] = useState(false);
  const [isManageMembersModalOpen, setIsManageMembersModalOpen] = useState(false);
  
  const [currentGroup, setCurrentGroup] = useState(null);
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [groupError, setGroupError] = useState(null);

  const [currentGroupMembers, setCurrentGroupMembers] = useState([]);
  const [memberError, setMemberError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pageLoaderWrapper, setPageLoaderWrapper] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [editVideoOwnerId, setEditVideoOwnerId] = useState(null);
  const [token, setToken] = useState(null);


  // ------------------- NEW STATE for Video Editing -------------------------
  const [isEditVideoModalOpen, setIsEditVideoModalOpen] = useState(false);
  const [editVideoId, setEditVideoId] = useState(null);
  const [editPrivacy, setEditPrivacy] = useState(true);
  const [editTitle, setEditTitle] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [origPrivacy, setOrigPrivacy] = useState(true);
  const [origTitle, setOrigTitle] = useState('');
  const [origDescription, setOrigDescription] = useState('');
  const [editGender, setEditGender] = useState('');
  const [editSpeciality, setEditSpeciality] = useState('');
  const [editSurgeryType, setEditSurgeryType] = useState('');
  const [origGender, setOrigGender] = useState('');
  const [origSpeciality, setOrigSpeciality] = useState('');
  const [origSurgeryType, setOrigSurgeryType] = useState('');
  const [allowedUsersList, setAllowedUsersList] = useState([]);
  const [allowedGroupsList, setAllowedGroupsList] = useState([]);
  const [selectedNewAllowedUsers, setSelectedNewAllowedUsers] = useState([]);
  const [selectedNewAllowedGroups, setSelectedNewAllowedGroups] = useState([]); 
  const [currentAccessView, setCurrentAccessView] = useState('users');
  // -------------------------------------------------------------------------

  // This state holds member group IDs for which the description is expanded.
  const [expandedMemberDescriptions, setExpandedMemberDescriptions] = useState([]);

  // Add new state for videos
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [videoLoading, setVideoLoading] = useState(true);

  // Add these state variables
  const [page, setPage] = useState(1);
  const videosPerPage = 4; // 4 videos per page

  

  // Add these helper functions
  const getTotalPages = (totalVideos) => Math.ceil(totalVideos / videosPerPage);

  const getCurrentPageVideos = (videos) => {
    const startIndex = (page - 1) * videosPerPage;
    return videos.slice(startIndex, startIndex + videosPerPage);
  };

  // Add pagination range function
  function getPaginationRange(currentPage, totalPages) {
    const firstPage = 1;
    const lastPage = totalPages;

    return [
      firstPage,
      currentPage > 2 ? '<' : null,
      currentPage,
      currentPage < lastPage - 1 ? '>' : null,
      lastPage
    ].filter(Boolean); // Remove null values
  }

  // Add these state variables
  const [searchTerm, setSearchTerm] = useState('');
  const [searchInput, setSearchInput] = useState('');

  // Add this function to handle search
  const handleSearchClick = () => {
    setSearchTerm(searchInput);
    setPage(1); // Reset to first page when searching
  };
  const handleResetSearch = () => {
    setSearchInput('');
    setSearchTerm('');
    setPage(1);
  };

  const fetchVideosWithSearch = async (token, searchQuery) => {
    if (!token) return;
    try {
      const response = await axios.get(`${API_URL}/video/search/my-uploads`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { query: searchQuery }
      });
  
      // Now response.data is expected to be an array of video objects.
      const videosWithStatus = await Promise.all(
        response.data.map(async (video) => {
          const statusResponse = await axios.get(
            `${API_URL}/video/${video.videoId}/status`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          return {
            ...video,
            status: statusResponse.data.status,
            thumbnailUrl: `${process.env.REACT_APP_CLOUDFRONT_LINK}/videos/${video.videoId}/Thumbnails/${video.videoId}_thumbnail.0000000.jpg`,
          };
        })
      );
  
      return videosWithStatus;
    } catch (error) {
      console.error('Error searching videos:', error);
      setError(error.response?.data?.message || 'Error searching videos');
      return [];
    }
  };

  // Modify loading state to be more specific
  const [pageLoading, setPageLoading] = useState(true); // For initial page load
  


  useEffect(() => {
    const getToken = async () => {
      try {
        const tok = await getAccessTokenSilently();
        setToken(tok);
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };
    getToken();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!token) return;
  // When progress is 100 and the upload is finished (not uploading and not processing),
  // trigger a re-fetch of the videos.
  if ( !isUploading && !processing) {
    const refetchVideos = async () => {
      try {
        await fetchInitialVideos(token);
      } catch (error) {
        console.error("Error re-fetching videos after upload:", error);
      }
    };
    refetchVideos();
  }
}, [progress, isUploading, processing, token]);

  // Update the initial data fetch useEffect
  useEffect(() => {
    if (!token) return;
    if (isAuthenticated) {
      const fetchUserData = async () => {
        try {
          setPageLoading(true);
          const userResponse = await axios.get(`${API_URL}/user/info`, {
            headers: { Authorization: `Bearer ${token}` },
          });

          setUserInfo(userResponse.data.userInfo);

          // Fetch user's groups
          const groupsResponse = await axios.get(`${API_URL}/group/my-groups`, {
            headers: { Authorization: `Bearer ${token}` },
          });

          setGroups(groupsResponse.data.groups);

          // Initial video fetch
          await fetchInitialVideos(token);
        } catch (error) {
          console.error('Error fetching user data:', error);
          setError(error.response?.data?.message || 'Error fetching data');
        } finally {
          setPageLoading(false);
        }
      };

      fetchUserData();
    }
  }, [isAuthenticated, token]);

  useEffect(() => {
    const isPrivacyChanged = editPrivacy !== origPrivacy;
    const isTitleChanged = editTitle !== origTitle;
    const isDescChanged = editDescription !== origDescription;
    const isGenderChanged = editGender !== origGender;
    const isSpecChanged = editSpeciality !== origSpeciality;
    const isTypeChanged = editSurgeryType !== origSurgeryType;
  
    if (
      isPrivacyChanged ||
      isTitleChanged ||
      isDescChanged ||
      isGenderChanged ||
      isSpecChanged ||
      isTypeChanged
    ) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
  }, [
    editPrivacy,
    editTitle,
    editDescription,
    editGender,
    editSpeciality,
    editSurgeryType,
    origPrivacy,
    origTitle,
    origDescription,
    origGender,
    origSpeciality,
    origSurgeryType,
  ]);
  // Separate function for initial video fetch
  const fetchInitialVideos = async (token) => {
    if (!token) return;
    try {
      const response = await axios.get(`${API_URL}/user/uploads`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const videos = await Promise.all(
        response.data.uploadedVideos.map(async (video) => {
          const statusResponse = await axios.get(
            `${API_URL}/video/${video.videoId}/status`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          return {
            ...video,
            status: statusResponse.data.status,
            thumbnailUrl: `${process.env.REACT_APP_CLOUDFRONT_LINK}/videos/${video.videoId}/Thumbnails/${video.videoId}_thumbnail.0000000.jpg`,
          };
        })
      );

      setUploadedVideos(videos);
    } catch (error) {
      console.error('Error fetching initial videos:', error);
      setError(error.response?.data?.message || 'Error fetching videos');
    }finally{
      setVideoLoading(false);
    }
  };


useEffect(() => {
  if (isEditVideoModalOpen || isManageMembersModalOpen || isEditGroupModalOpen || isAddGroupModalOpen) {
    // Disable body scroll
    document.body.classList.add('no-scroll');
  } else {
    // Enable body scroll
    document.body.classList.remove('no-scroll');
  }
  // Cleanup on unmount
  return () => document.body.classList.remove('no-scroll');
}, [isEditVideoModalOpen, isManageMembersModalOpen, isEditGroupModalOpen, isAddGroupModalOpen]);

  // Update the search effect to use videoLoading
  useEffect(() => {
    if (!token) return;
    if (isAuthenticated) {
      const fetchVideos = async () => {
        try {
          setVideoLoading(true);
          if (searchTerm) {
            // If there is a search term, perform the search
            const videos = await fetchVideosWithSearch(token, searchTerm);
            setUploadedVideos(videos);
          } else {
            // Otherwise, fetch the initial videos
            await fetchInitialVideos(token);
          }
        } catch (error) {
          console.error('Error fetching videos:', error);
          setError(error.response?.data?.message || 'Error fetching videos');
        } finally {
          setVideoLoading(false);
        }
      };
  
      fetchVideos();
    }
  }, [isAuthenticated, token, searchTerm]);

  useEffect(() => {
    if (isManageMembersModalOpen || isEditGroupModalOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  
    // Cleanup when the component unmounts
    return () => document.body.classList.remove('no-scroll');
  }, [isManageMembersModalOpen, isEditGroupModalOpen]);

  const handleAddGroup = async () => {
    if (!token) return;
    if (!groupName.trim()) {
      setGroupError('Group name is required.');
      return;
    }
    try {
      const response = await axios.post(
        `${API_URL}/group`,
        {
          groupName,
          description: groupDescription,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setGroups([...groups, response.data.group]);
      setIsAddGroupModalOpen(false);
      setGroupName('');
      setGroupDescription('');
      setGroupError(null);
    } catch (error) {
      console.error('Error adding group:', error);
      setGroupError(error.response?.data?.message || 'Error adding group');
    }
  };

const loadUserOptions = async (inputValue, currentAllowedUsers = []) => {
  if (!token) return;
  if (!inputValue) return [];
  try {
    const response = await axios.get(`${API_URL}/user/search`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { query: inputValue },
    });

    // Safe-check arrays
    const allowedUserIds = Array.isArray(currentAllowedUsers)
      ? currentAllowedUsers.map((u) => u.userId)
      : [];

    // Filter out already allowed
    const filtered = response.data.users.filter(
      (u) => !allowedUserIds.includes(u.userId)
    );

    return filtered.map((user) => ({
      value: user.userId,
      label: `User: ${user.name}`,
    }));
  } catch (error) {
    console.error('Error fetching user options:', error);
    return [];
  }
};

// loadGroupOptions
const loadGroupOptions = async (inputValue, currentAllowedGroups = []) => {
  if (!token) return;
  if (!inputValue) return [];
  try {
    const response = await axios.get(`${API_URL}/group/my-groups`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { query: inputValue },
    });

    // Safe-check arrays
    const allowedGroupIds = Array.isArray(currentAllowedGroups)
      ? currentAllowedGroups.map((g) => g.groupId)
      : [];

    // Filter out already allowed
    const filtered = response.data.groups.filter(
      (g) => !allowedGroupIds.includes(g.groupId)
    );

    return filtered.map((group) => ({
      value: group.groupId,
      label: group.groupName,
    }));
  } catch (error) {
    console.error('Error fetching group options:', error);
    return [];
  }
};

  const handleEditGroup = async () => {
    if (!token) return;
    if (!groupName.trim()) {
      setGroupError('Group name is required.');
      return;
    }
    try {
      const response = await axios.put(
        `${API_URL}/group/${currentGroup.groupId}`,
        {
          groupName,
          description: groupDescription,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setGroups(
        groups.map((group) =>
          group.groupId === currentGroup.groupId ? response.data.group : group
        )
      );
      setIsEditGroupModalOpen(false);
      setCurrentGroup(null);
      setGroupName('');
      setGroupDescription('');
      setGroupError(null);
    } catch (error) {
      console.error('Error editing group:', error);
      setGroupError(error.response?.data?.message || 'Error editing group');
    }
  };

  const handleDeleteGroup = async (groupId) => {
    if (!token) return;
    if (window.confirm('Are you sure you want to delete this group?')) {
      try {
        await axios.delete(`${API_URL}/group/${groupId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setGroups(groups.filter((group) => group.groupId !== groupId));
      } catch (error) {
        console.error('Error deleting group:', error);
        setError(error.response?.data?.message || 'Error deleting group');
      }
    }
  };
  const handleUploadClick = () => {
    navigate('/upload_video');
  };

  const openEditGroupModal = (group) => {
    setCurrentGroup(group);
    setGroupName(group.groupName);
    setGroupDescription(group.description || '');
    setIsEditGroupModalOpen(true);
  };

  const fetchGroupMembers = async (groupId) => {
    if (!token) return;
    try {
      const response = await axios.get(`${API_URL}/group/${groupId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setCurrentGroupMembers(response.data.group.members);
    } catch (error) {
      console.error('Error fetching group members:', error);
      setMemberError(error.response?.data?.message || 'Error fetching group members');
    }
  };

  const openManageMembersModal = (group) => {
    setCurrentGroup(group);
    setIsManageMembersModalOpen(true);
    fetchGroupMembers(group.groupId);
  };

  const handleRemoveMember = async (memberToken) => {
    if (!token) return;
    if (window.confirm('Are you sure you want to remove this member?')) {
      try {
        await axios.delete(`${API_URL}/group/${currentGroup.groupId}/members`, {
          headers: { Authorization: `Bearer ${token}` },
          data: { memberToken },
        });

        fetchGroupMembers(currentGroup.groupId);
      } catch (error) {
        console.error('Error removing member:', error);
        setMemberError(error.response?.data?.message || 'Error removing member');
      }
    }
  };

  // Toggle expand/collapse for a member group description
  const toggleDescription = (groupId) => {
    setExpandedMemberDescriptions((prev) =>
      prev.includes(groupId)
        ? prev.filter((id) => id !== groupId)
        : [...prev, groupId]
    );
  };

  const toggleTitle = (groupId) => {
    const titleKey = `title-${groupId}`;
    setExpandedMemberDescriptions((prev) =>
      prev.includes(titleKey)
        ? prev.filter((key) => key !== titleKey)
        : [...prev, titleKey]
    );
  };

  // Add delete video handler
  const handleDelete = async (videoId) => {
    if (!token) return;
    try {
      await axios.delete(`${API_URL}/video/${videoId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      setUploadedVideos(prev => {
        const updatedVideos = prev.filter(video => video.videoId !== videoId);
        const totalPages = getTotalPages(updatedVideos.filter(v => v.status === 'complete').length);
        
        // Check if the current page is now empty and decrement the page if necessary
        if (page > totalPages) {
          setPage(totalPages); // Go back to the last page if current page exceeds total pages
        }
        
        return updatedVideos;
      });
    } catch (error) {
      console.error('Error deleting video:', error);
      setError('Error deleting video');
    }
  };

  // ------------------- NEW HANDLERS FOR 3 PATCH ENDPOINTS --------------------
  const handleUpdatePrivacy = async () => {
    if (!token) return;
    if (!editVideoId) return;
    try {
      await axios.patch(
        `${API_URL}/video/${editVideoId}/privacy`,
        { isPublic: editPrivacy },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // Update local state so the UI shows the new privacy
      setUploadedVideos(prev =>
        prev.map((vid) =>
          vid.videoId === editVideoId
            ? { ...vid, isPublic: editPrivacy }
            : vid
        )
      );
      alert('Privacy updated!');
    } catch (error) {
      console.error('Error updating privacy:', error);
      alert('Failed to update privacy');
    }
  };

  const handleUpdateTitle = async () => {
    if (!token) return;
    if (!editVideoId) return;
    try {
      await axios.patch(
        `${API_URL}/video/${editVideoId}/title`,
        { title: editTitle },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // Update local state
      setUploadedVideos(prev =>
        prev.map((vid) =>
          vid.videoId === editVideoId
            ? { ...vid, videoTitle: editTitle }
            : vid
        )
      );
      alert('Title updated!');
    } catch (error) {
      console.error('Error updating title:', error);
      alert('Failed to update title');
    }
  };

  const handleUpdateDescription = async () => {
    if (!token) return;
    if (!editVideoId) return;
    try {
      await axios.patch(
        `${API_URL}/video/${editVideoId}/description`,
        { description: editDescription },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // Update local state
      setUploadedVideos(prev =>
        prev.map((vid) =>
          vid.videoId === editVideoId
            ? { ...vid, videoDescription: editDescription }
            : vid
        )
      );
      alert('Description updated!');
    } catch (error) {
      console.error('Error updating description:', error);
      alert('Failed to update description');
    }
  };
  // ---------------------------------------------------------------------------

  // Helper function to format date
  function formatUploadDate(dateString) {
    const uploadDate = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - uploadDate) / 1000);

    if (diffInSeconds < 60) return "Just now";
    const minutes = Math.floor(diffInSeconds / 60);
    if (minutes < 60) return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours} hour${hours === 1 ? "" : "s"} ago`;
    const days = Math.floor(hours / 24);
    if (days < 7) return `${days} day${days === 1 ? "" : "s"} ago`;
    if (days < 30) {
      const weeks = Math.floor(days / 7);
      return `${weeks} week${weeks === 1 ? "" : "s"} ago`;
    }
    if (days < 365) {
      const months = Math.floor(days / 30);
      return `${months} month${months === 1 ? "" : "s"} ago`;
    }
    const years = Math.floor(days / 365);
    return `${years} year${years === 1 ? "" : "s"} ago`;
  }

  // ------------------- NEW FUNCTION handleEditClick --------------------------
  const handleEditClick = async (e, vid) => {
    if (!token) return;
    e.stopPropagation();
    e.preventDefault();
    setEditVideoOwnerId(vid.uploadedBy);
    // 1) Open the modal right away
    setIsEditVideoModalOpen(true);
    setEditVideoId(vid.videoId);
  
    // 2) Set initial states
    setEditPrivacy(!vid.isPublic);
    setEditTitle(vid.videoTitle ?? '');
    setEditDescription(vid.videoDescription ?? '');
    setEditGender(vid.patientGender ?? '');
    setEditSpeciality(vid.surgerySpeciality ?? '');
    setEditSurgeryType(vid.surgeryType ?? '');
  
    // 3) Store originals for comparison later
    setOrigPrivacy(!vid.isPublic);
    setOrigTitle(vid.videoTitle ?? '');
    setOrigDescription(vid.videoDescription ?? '');
    setOrigGender(vid.patientGender ?? '');
    setOrigSpeciality(vid.surgerySpeciality ?? '');
    setOrigSurgeryType(vid.surgeryType ?? '');
  
    // Reset newly selected access states
    setSelectedNewAllowedUsers([]);
    setSelectedNewAllowedGroups([]);
  
    setCurrentAccessView('users');
  
    // 4) Since video is potentially private, fetch allowed-all
    try {
      // Get the updated allowed lists
      const response = await axios.get(`${API_URL}/video/${vid.videoId}/allowed-all`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      // Filter out the owner (uploadedBy) from the allowed users
      const filteredUsers = response.data.allowedUsers.filter(
        (u) => u.userId !== vid.uploadedBy // skip the owner
      );
  
      setAllowedUsersList(filteredUsers);
      setAllowedGroupsList(response.data.allowedGroups);
    } catch (err) {
      console.error('Error fetching allowed-all data:', err);
    }
  
    setHasUnsavedChanges(false);
  };
  
  // ---------------------------------------------------------------------------

  if (!isAuthenticated) {
    return <div>Please log in to view your account information.</div>;
  }


  if (pageLoading) {
    return (
      <div className="page-loader-wrapper">
        <div className="acc-loader"></div>
      </div>
    );
  }

  // Owned Group Items — title and action buttons (Edit, Members, Delete)
  const ownedGroupItems = groups
    .filter((group) => group.isOwner)
    .map((group) => {
      // Title toggle setup
      const isTitleLong = group.groupName && group.groupName.length > TITLE_THRESHOLD;
      const isTitleExpanded = expandedMemberDescriptions.includes(`title-${group.groupId}`);
      const titleText = isTitleLong && !isTitleExpanded
        ? group.groupName.substring(0, TITLE_THRESHOLD) + '...'
        : group.groupName;

      // Description toggle setup
      const isDescriptionLong = group.description && group.description.length > DESCRIPTION_THRESHOLD;
      const isDescriptionExpanded = expandedMemberDescriptions.includes(group.groupId);
      const descriptionText = isDescriptionLong && !isDescriptionExpanded
        ? group.description.substring(0, DESCRIPTION_THRESHOLD) + '...'
        : group.description;

      return (
        <li key={group.groupId} className="acc-group-list-item">
          <div className="acc-group-item owned-group">
            <div className="acc-group-header">
              <span 
                className={`acc-group-name expanded ${isTitleExpanded ? 'expanded' : ''}`}
              >
                {titleText}
                {isTitleLong && (
                  <span
                    onClick={() => toggleTitle(group.groupId)}
                    className="read-toggle"
                  >
                    {isTitleExpanded ? ' Read Less' : ' Read More'}
                  </span>
                )}
              </span>
            </div>
            {group.description && (
              <p 
                className={`acc-group-description expanded ${isDescriptionExpanded ? 'expanded' : ''}`}
              >
                {descriptionText}
                {isDescriptionLong && (
                  <span
                    onClick={() => toggleDescription(group.groupId)}
                    className="read-toggle"
                  >
                    {isDescriptionExpanded ? ' Read Less' : ' Read More'}
                  </span>
                )}
              </p>
            )}
            <div className="acc-group-actions">
              <button className="group-edit-button" onClick={() => openEditGroupModal(group)}>
                Edit
              </button>
              <button className="group-manage-members-button" onClick={() => openManageMembersModal(group)}>
                Members
              </button>
              <button className="group-delete-button" onClick={() => handleDeleteGroup(group.groupId)}>
                Delete
              </button>
            </div>
          </div>
        </li>
      );
    });

    
  // Member (non‑owned) Group Items — title, full description, etc.
  const memberGroupItems = groups
    .filter((group) => !group.isOwner)
    .map((group) => {
      // Title toggle setup
      const isTitleLong = group.groupName && group.groupName.length > TITLE_THRESHOLD;
      const isTitleExpanded = expandedMemberDescriptions.includes(`title-${group.groupId}`);
      const titleText = isTitleLong && !isTitleExpanded
        ? group.groupName.substring(0, TITLE_THRESHOLD) + '...'
        : group.groupName;

      // Description toggle setup
      const isDescriptionLong = group.description && group.description.length > DESCRIPTION_THRESHOLD;
      const isDescriptionExpanded = expandedMemberDescriptions.includes(group.groupId);
      const descriptionText = isDescriptionLong && !isDescriptionExpanded
        ? group.description.substring(0, DESCRIPTION_THRESHOLD) + '...'
        : group.description;

      return (
        <li key={group.groupId} className="acc-group-list-item">
          <div className="acc-group-item member-group">
            <div className="acc-group-header">
              <span 
                className={`acc-group-name expanded ${isTitleExpanded ? 'expanded' : 'truncated'}`}
              >
                {titleText}
                {isTitleLong && (
                  <span
                    onClick={() => toggleTitle(group.groupId)}
                    className="read-toggle"
                  >
                    {isTitleExpanded ? ' Read Less' : ' Read More'}
                  </span>
                )}
              </span>
            </div>
            {group.description && (
              <p 
                className={`acc-group-description full-description expanded ${isDescriptionExpanded ? 'expanded' : 'truncated'}`}
              >
                {descriptionText}
                {isDescriptionLong && (
                  <span
                    onClick={() => toggleDescription(group.groupId)}
                    className="read-toggle"
                  >
                    {isDescriptionExpanded ? ' Read Less' : ' Read More'}
                  </span>
                )}
              </p>
            )}
            <div className="acc-group-actions">
              {/* You can include other controls here */}
            </div>
          </div>
        </li>
      );
    });
    const handleSaveVideoEdits = async () => {
      if (!token) return;
      if (!editVideoId) return;
    
      try {
        const requests = [];
    
        // Check for privacy change
        if (editPrivacy !== origPrivacy) {
          requests.push(
            axios.patch(
              `${API_URL}/video/${editVideoId}/privacy`,
              { isPublic: !editPrivacy },
              { headers: { Authorization: `Bearer ${token}` } }
            )
          );
        }
    
        // Check for title change
        if (editTitle !== origTitle) {
          requests.push(
            axios.patch(
              `${API_URL}/video/${editVideoId}/title`,
              { title: editTitle },
              { headers: { Authorization: `Bearer ${token}` } }
            )
          );
        }
    
        // Check for description change
        if (editDescription !== origDescription) {
          requests.push(
            axios.patch(
              `${API_URL}/video/${editVideoId}/description`,
              { description: editDescription },
              { headers: { Authorization: `Bearer ${token}` } }
            )
          );
        }
    
        // Check for gender change
        if (editGender !== origGender) {
          requests.push(
            axios.patch(
              `${API_URL}/video/${editVideoId}/gender`,
              { gender: editGender },
              { headers: { Authorization: `Bearer ${token}` } }
            )
          );
        }
    
        // Check for speciality change
        if (editSpeciality !== origSpeciality) {
          requests.push(
            axios.patch(
              `${API_URL}/video/${editVideoId}/speciality`,
              { speciality: editSpeciality },
              { headers: { Authorization: `Bearer ${token}` } }
            )
          );
        }
    
        // Check for surgery type change
        if (editSurgeryType !== origSurgeryType) {
          requests.push(
            axios.patch(
              `${API_URL}/video/${editVideoId}/type`,
              { surgeryType: editSurgeryType },
              { headers: { Authorization: `Bearer ${token}` } }
            )
          );
        }
    
        if (requests.length > 0) {
          await Promise.all(requests);
        }
    
        setUploadedVideos((prev) =>
          prev.map((vid) =>
            vid.videoId === editVideoId
              ? {
                  ...vid,
                  isPublic: !editPrivacy,
                  videoTitle: editTitle,
                  videoDescription: editDescription,
                  patientGender: editGender,
                  surgerySpeciality: editSpeciality,
                  surgeryType: editSurgeryType,
                }
              : vid
          )
        );
    
        setIsEditVideoModalOpen(false);
        setEditVideoId(null);
      } catch (err) {
        console.error('Error saving video edits:', err);
      }
    };



    const handleAddAllowedUsers = async () => {
      if (!token) return;
      if (!editVideoId || selectedNewAllowedUsers.length === 0) return;
      try {
        const userIds = selectedNewAllowedUsers.map((item) => item.value);
    
        await axios.post(
          `${API_URL}/video/${editVideoId}/allowed-users`,
          { allowedUsers: userIds },
          { headers: { Authorization: `Bearer ${token}` } }
        );
    
        const response = await axios.get(`${API_URL}/video/${editVideoId}/allowed-all`, {
          headers: { Authorization: `Bearer ${token}` },
        });
    
        // Filter out the owner again
        const filteredUsers = response.data.allowedUsers.filter(
          (u) => u.userId !== editVideoOwnerId
        );
        setAllowedUsersList(filteredUsers);
        setAllowedGroupsList(response.data.allowedGroups);
    
        setSelectedNewAllowedUsers([]);
      } catch (error) {
        console.error('Error adding allowed users:', error);
      }
    };
    
    const handleAddAllowedGroups = async () => {
      if (!token) return;
      if (!editVideoId || selectedNewAllowedGroups.length === 0) return;
      try {
        const groupIds = selectedNewAllowedGroups.map((item) => item.value);
    
        await axios.post(
          `${API_URL}/video/${editVideoId}/allowed-users`,
          { allowedGroups: groupIds },
          { headers: { Authorization: `Bearer ${token}` } }
        );
    
        // Refetch the updated allowed lists
        const response = await axios.get(`${API_URL}/video/${editVideoId}/allowed-all`, {
          headers: { Authorization: `Bearer ${token}` },
        });
    
        setAllowedUsersList(response.data.allowedUsers);
        setAllowedGroupsList(response.data.allowedGroups);
    
        // Clear the selection
        setSelectedNewAllowedGroups([]);
      } catch (error) {
        console.error('Error adding allowed groups:', error);
      }
    };

    // Remove a single user from allowed
    const handleRemoveUserFromAllowed = async (userId) => {
      if (!token) return;
      if (!editVideoId) return;
      try {
        await axios.delete(`${API_URL}/video/${editVideoId}/allowed-users`, {
          headers: { Authorization: `Bearer ${token}` },
          data: {
            allowedUsers: [userId],
          },
        });
        // Re-fetch the updated lists
        const response = await axios.get(`${API_URL}/video/${editVideoId}/allowed-all`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        // Filter out the owner again
        const filteredUsers = response.data.allowedUsers.filter(
          (u) => u.userId !== editVideoOwnerId
        );
        setAllowedUsersList(filteredUsers);
        setAllowedGroupsList(response.data.allowedGroups);
      } catch (error) {
        console.error('Error removing user from allowed:', error);
      }
    };

// Remove a single group from allowed
const handleRemoveGroupFromAllowed = async (groupId) => {
  if (!token) return;
  if (!editVideoId) return;
  try {
    await axios.delete(`${API_URL}/video/${editVideoId}/allowed-users`, {
      headers: { Authorization: `Bearer ${token}` },
      data: {
        allowedGroups: [groupId],
      },
    });
    // Re-fetch
    const response = await axios.get(`${API_URL}/video/${editVideoId}/allowed-all`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setAllowedUsersList(response.data.allowedUsers);
    setAllowedGroupsList(response.data.allowedGroups);
  } catch (error) {
    console.error('Error removing group from allowed:', error);
  }
};
    

  return (
    <div className="account-page">
      {/* Top Section containing user info and groups */}
      <div className="account-top-section">
        {/* LEFT PANEL - user info */}
        <div className="account-left">
          {userInfo && (
            <div className="user-info-card">
              <div className="avatar-placeholder">
                {userInfo.name?.charAt(0) || 'U'}
              </div>
              <h2 className="user-name">{userInfo.name}, M.D.</h2>
              <p className="user-meta">
                {userInfo.organisation} | {userInfo.specialisation}
              </p>
              <div className="account-links">
                {/* Future expansion */}
              </div>
            </div>
          )}
        </div>

        {/* RIGHT PANEL - group management */}
        <div className="account-right">
          {error && <div className="error">{error}</div>}
          <div className="groups-dual-layout">
            {/* Owned Groups Panel */}
            <div className="owned-groups-panel">
              <div className="panel-header">
                <h2>Owned</h2>
                <button
                  className="add-group-button"
                  onClick={() => {
                    setIsAddGroupModalOpen(true);
                    setGroupError(null);
                  }}
                >
                  + Add
                </button>
              </div>
              <div className="group-list-wrapper">
                {ownedGroupItems.length > 0 ? (
                  <ul className="group-list">{ownedGroupItems}</ul>
                ) : (
                  <p>No owned groups yet.</p>
                )}
              </div>
            </div>

            {/* Member Groups Panel */}
            <div className="member-groups-panel">
              <h2>Member</h2>
              <div className="group-list-wrapper">
                {memberGroupItems.length > 0 ? (
                  <ul className="group-list">{memberGroupItems}</ul>
                ) : (
                  <p>You are not a member of any groups.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {(isUploading || processing) && (
  <div className="current-upload-section">
    <h2 className="section-title">Video Uploading</h2>

    {/* Metadata display */}
    {uploadMetadata && (
      <div className="metadata-display">
        {/* Distinct filename display */}
        <div className="file-name-container">
          <p className="file-name">{uploadMetadata.fileName}</p>
        </div>

        {/* Video title and description */}
        <h3>{uploadMetadata.videoTitle}</h3>
        <p>{uploadMetadata.videoDescription}</p>
      </div>
    )}

    <div className="upload-progress-container">
      <div className="progress-bar-background">
        <div
          className="progress-bar-foreground"
          style={{ width: `${progress}%` }}
        />
      </div>
      <div className="upload-status-text">
        {isUploading && (
          progress < 100 
            ? <p>{progress}% uploaded</p> 
            : <p>Preparing for processing...</p> 
        )}
        {processing && <p>Processing...</p>}
      </div>
    </div>
  </div>
)}



      {/* Video Management Section */}
      <div className="account-video-section">
        <div className="account-video-header">
          <h2>My Videos</h2>
          <div className="account-search-container">
            <button className="acc-upload-icon-button" onClick={handleUploadClick}>
              <UploadVideoIcon className="acc-upload-video-icon" />
            </button>
            <div className="search-wrapper">
              <input
                type="text"
                className="search-input-field"
                placeholder="Search your videos..."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearchClick();
                  }
                }}
              />
              <button
                className="search-icon-button"
                onClick={handleSearchClick}
              >
                🔍
              </button>
              {/* Clear icon appears only when there is text */}
              {searchInput && (
                <button
                  className="account-clear-button"
                  onClick={() => {
                    setSearchInput('');
                    setSearchTerm('');
                    setPage(1);
                  }}
                  title="Clear search"
                >
                  &#10005;
                </button>
              )}
            </div>
          </div>
        </div>
        
        <div className="account-video-grid">
          {videoLoading ? (
            <div className="video-loader-wrapper">
              <div className="acc-loader"></div>
            </div>
          ) : (
            <>
              {uploadedVideos.length === 0 ? (
                <div className="account-no-videos">
                  <div className="no-videos-icon">📹</div>
                  <div className="no-videos-text">No videos uploaded yet</div>
                  <div className="no-videos-suggestion">
                    Start by uploading your first video
                  </div>
                </div>
              ) : (
                <>
                  {getCurrentPageVideos(
                    uploadedVideos.filter((v) => v.status === 'complete')
                  ).map((vid) => (
                    <div key={vid.videoId} className="account-video-card">
                      <Link to={`/cases_video/${vid.videoId}`}>
                        <div className="account-thumbnail-container">
                          <img
                            src={vid.thumbnailUrl}
                            alt={`${vid.videoTitle} thumbnail`}
                            className="account-thumbnail-image"
                            onError={(e) => {
                              e.target.style.display = 'none';
                              e.target.onerror = null;
                            }}
                          />
                          {/* Overlay for additional info on hover */}
                          <div className="info-bubble">
                            <div className="info-title">
                              {vid.surgeryType || 'Unknown Operation'}
                            </div>
                            <div className="info-procedure">
                              {vid.surgerySpeciality || 'Unknown Procedure'}
                            </div>
                            <div className="info-description">
                              {vid.videoDescription || 'No description available.'}
                            </div>
                            <div className="info-surgeon">You</div>
                          </div>
                        </div>
                      </Link>
                      <div className="account-video-info">
                        <div className="account-video-title">{vid.videoTitle}</div>
                        <div className="account-video-footer">
                          <span className="account-surgeon-name">You</span>
                          <span className="account-upload-date">
                            {vid.uploadDate ? formatUploadDate(vid.uploadDate) : 'Unknown Date'}
                          </span>
                          <div className="account-video-actions">
                            {/* Use handleEditClick so 'vid' is in scope */}
                            <button
                              className="account-edit-button"
                              onClick={(e) => handleEditClick(e, vid)}
                            >
                              Edit
                            </button>
                            <button
                              className="account-delete-button"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                if (window.confirm('Are you sure you want to delete this video?')) {
                                  handleDelete(vid.videoId);
                                }
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* Pagination Controls */}
                  <div className="account-pagination">
                    <button 
                      className={`account-pagination-button ${page === 1 ? 'disabled' : ''}`}
                      onClick={() => setPage(1)}
                      disabled={page === 1}
                    >
                      ≪
                    </button>
                    <button 
                      className={`account-pagination-button ${page === 1 ? 'disabled' : ''}`}
                      onClick={() => setPage((prev) => Math.max(1, prev - 1))}
                      disabled={page === 1}
                    >
                      ＜
                    </button>
                    <span className="account-pagination-info">
                      {`${page} / ${getTotalPages(
                        uploadedVideos.filter((v) => v.status === 'complete').length
                      )}`}
                    </span>
                    <button
                      className={`account-pagination-button ${
                        page ===
                        getTotalPages(uploadedVideos.filter((v) => v.status === 'complete').length)
                          ? 'disabled'
                          : ''
                      }`}
                      onClick={() =>
                        setPage((prev) =>
                          Math.min(
                            getTotalPages(uploadedVideos.filter((v) => v.status === 'complete').length),
                            prev + 1
                          )
                        )
                      }
                      disabled={
                        page ===
                        getTotalPages(uploadedVideos.filter((v) => v.status === 'complete').length)
                      }
                    >
                      ＞
                    </button>
                    <button
                      className={`account-pagination-button ${
                        page ===
                        getTotalPages(uploadedVideos.filter((v) => v.status === 'complete').length)
                          ? 'disabled'
                          : ''
                      }`}
                      onClick={() =>
                        setPage(
                          getTotalPages(uploadedVideos.filter((v) => v.status === 'complete').length)
                        )
                      }
                      disabled={
                        page ===
                        getTotalPages(uploadedVideos.filter((v) => v.status === 'complete').length)
                      }
                    >
                      ≫
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {/* ---------------------- Modals ---------------------- */}
      {isManageMembersModalOpen && currentGroup && (
        <div className="modal">
          <div className="modal-content">
            <h3>Manage Members for {currentGroup.groupName}</h3>

            <h4>Group Owner:</h4>
            <p className="group-owner">
              {currentGroup.ownerName} <span className="owner-tag">(Owner)</span>
            </p>

            <h4>Current Members:</h4>
            <ul className="member-list">
              {/* Owner at the top */}
              {currentGroupMembers
                .filter((member) => member.isCurrentUser)
                .map((member) => {
                  const isNameLong = member.name && member.name.length > TITLE_THRESHOLD;
                  const isNameExpanded = expandedMemberDescriptions.includes(`member-${member.memberToken}`);
                  const memberName = isNameLong && !isNameExpanded
                    ? member.name.substring(0, TITLE_THRESHOLD) + '...'
                    : member.name;

                  return (
                    <li key={member.memberToken} className="member-list-item owner-item">
                      <span
                        className={`member-name owner-name ${isNameExpanded ? 'expanded' : 'truncated'}`}
                      >
                        {memberName}
                        {isNameLong && (
                          <span
                            onClick={() => toggleDescription(`member-${member.memberToken}`)}
                            className="read-toggle"
                          >
                            {isNameExpanded ? ' Read Less' : ' Read More'}
                          </span>
                        )}
                        <span className="owner-tag"> (Owner)</span>
                      </span>
                      {/* Owner button */}
                      <button className="owner-indicator-button" disabled>
                        Owner
                      </button>
                    </li>
                  );
                })}

              {/* Other members */}
              {currentGroupMembers
                .filter((member) => !member.isCurrentUser)
                .map((member) => {
                  const isNameLong = member.name && member.name.length > TITLE_THRESHOLD;
                  const isNameExpanded = expandedMemberDescriptions.includes(`member-${member.memberToken}`);
                  const memberName = isNameLong && !isNameExpanded
                    ? member.name.substring(0, TITLE_THRESHOLD) + '...'
                    : member.name;

                  return (
                    <li key={member.memberToken} className="member-list-item">
                      <span className={`member-name ${isNameExpanded ? 'expanded' : 'truncated'}`}>
                        {memberName}
                        {isNameLong && (
                          <span
                            onClick={() => toggleDescription(`member-${member.memberToken}`)}
                            className="read-toggle"
                          >
                            {isNameExpanded ? ' Read Less' : ' Read More'}
                          </span>
                        )}
                      </span>
                      <button
                        className="remove-member-button"
                        onClick={() => handleRemoveMember(member.memberToken)}
                      >
                        Remove
                      </button>
                    </li>
                  );
                })}
            </ul>

            <div className="modal-actions">
              <button
                className="close-button"
                onClick={() => {
                  setIsManageMembersModalOpen(false);
                  setCurrentGroup(null);
                  setCurrentGroupMembers([]);
                  setMemberError(null);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {isAddGroupModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Create a New Group</h3>
            {groupError && <div className="error">{groupError}</div>}

            <label htmlFor="newGroupName">
              <h4>Group Name</h4>
              <p className="input-subtitle">Give your group a meaningful name.</p>
            </label>
            <input
              id="newGroupName"
              type="text"
              placeholder="Enter group name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              className="input-field"
            />

            <label htmlFor="newGroupDescription">
              <h4>Group Description</h4>
              <p className="input-subtitle">Provide a short description to help identify this group.</p>
            </label>
            <textarea
              id="newGroupDescription"
              placeholder="Enter group description"
              value={groupDescription}
              onChange={(e) => setGroupDescription(e.target.value)}
              className="input-field textarea-field"
            />

            <div className="modal-actions">
              <button className="save-button" onClick={handleAddGroup}>
                Create Group
              </button>
              <button
                className="cancel-button"
                onClick={() => {
                  setIsAddGroupModalOpen(false);
                  setGroupError(null);
                  setGroupName('');
                  setGroupDescription('');
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    
      {isEditGroupModalOpen && currentGroup && (
        <div className="modal">
          <div className="modal-content">
            <h3>Edit Group</h3>
            {groupError && <div className="error">{groupError}</div>}

            <label htmlFor="groupNameInput">
              <h4>Group Name</h4>
              <p className="input-subtitle">Update the group's name as it will appear to others.</p>
            </label>
            <input
              id="groupNameInput"
              type="text"
              placeholder="Group Name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />

            <label htmlFor="groupDescriptionInput">
              <h4>Group Description</h4>
              <p className="input-subtitle">Provide a brief description for this group.</p>
            </label>
            <textarea
              id="groupDescriptionInput"
              placeholder="Description"
              value={groupDescription}
              onChange={(e) => setGroupDescription(e.target.value)}
            />

            <div className="modal-actions">
              <button className="save-button" onClick={handleEditGroup}>
                Save Changes
              </button>
              <button
                className="cancel-button"
                onClick={() => {
                  setIsEditGroupModalOpen(false);
                  setGroupError(null);
                  setCurrentGroup(null);
                  setGroupName('');
                  setGroupDescription('');
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isEditVideoModalOpen && editVideoId && (
        <div className="modal">
          <div className="modal-content">
            <button
              className="close-modal-button"
              onClick={() => {
                setIsEditVideoModalOpen(false);
                setEditVideoId(null);
              }}
              style={{ position: 'absolute', top: '10px', right: '10px' }}
            >
              &times;
            </button>
            <h3 className="section-title">Edit Video</h3> {/* Main Section Title */}
            {/* --- MAIN EDIT SECTION (ALWAYS VISIBLE) --- */}
            <div className="edit-section">
              {/* PRIVACY */}
              <label><strong>Video Privacy:</strong></label>
              <div className="privacy-radios">
                <label>
                  <input
                    type="radio"
                    name="privacy"
                    value="public"
                    checked={!editPrivacy}
                    onChange={() => setEditPrivacy(false)}
                  />
                  Public
                </label>
                <label>
                  <input
                    type="radio"
                    name="privacy"
                    value="private"
                    checked={editPrivacy}
                    onChange={() => setEditPrivacy(true)}
                  />
                  Private
                </label>
              </div>
            </div>

            {/* TITLE */}
            <div className="edit-section">
              <label><strong>Title:</strong></label>
              <input
                type="text"
                value={editTitle}
                onChange={(e) => setEditTitle(e.target.value)}
              />
            </div>

            {/* DESCRIPTION */}
            <div className="edit-section">
              <label><strong>Description:</strong></label>
              <textarea
                value={editDescription}
                onChange={(e) => setEditDescription(e.target.value)}
              />
            </div>

            {/* GENDER */}
            <div className="edit-section">
              <label><strong>Patient Gender:</strong></label>
              <select
                value={editGender}
                onChange={(e) => setEditGender(e.target.value)}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>

            {/* SPECIALTY */}
            <div className="edit-section">
              <label><strong>Specialty:</strong></label>
              <input
                type="text"
                value={editSpeciality}
                onChange={(e) => setEditSpeciality(e.target.value)}
              />
            </div>

            {/* SURGERY TYPE */}
            <div className="edit-section">
              <label><strong>Surgery Type:</strong></label>
              <input
                type="text"
                value={editSurgeryType}
                onChange={(e) => setEditSurgeryType(e.target.value)}
              />
            </div>

            {/* --- SAVE/CANCEL BUTTONS (ALWAYS SHOWN) --- */}
            <div className="modal-actions">
              <button
                className="video-edit-save-button"
                onClick={handleSaveVideoEdits}
                disabled={!hasUnsavedChanges} // Greyed out if no changes
              >
                Save Changes
              </button>
              <button
                className="video-edit-cancel-button"
                onClick={() => {
                  setIsEditVideoModalOpen(false);
                  setEditVideoId(null);
                }}
              >
                Cancel
              </button>
            </div>

            {/* --- CONDITIONAL ACCESS PANEL (ONLY FOR PRIVATE VIDEOS) --- */}
            {editPrivacy && (
              <>
              <h3 className="section-title-2">Manage Video Access</h3> {/* New Section Title */}

                <div className="edit-section">
                  <h4>Current Access</h4>
                  <div className="access-toggle">
                    <button
                      className={`access-tab ${currentAccessView === 'users' ? 'active' : ''}`}
                      onClick={() => setCurrentAccessView('users')}
                    >
                      Users
                    </button>
                    <button
                      className={`access-tab ${currentAccessView === 'groups' ? 'active' : ''}`}
                      onClick={() => setCurrentAccessView('groups')}
                    >
                      Groups
                    </button>
                  </div>

                  {/* Allowed Users */}
                  {currentAccessView === 'users' ? (
                    allowedUsersList.length > 0 ? (
                      <ul className="allowed-list">
                        {allowedUsersList.map((user) => (
                          <li key={user.userId} className="allowed-item">
                            <div className="allowed-text">
                              {user.name}
                            </div>
                            <button
                              className="remove-button"
                              onClick={() => handleRemoveUserFromAllowed(user.userId)}
                            >
                              &times;
                            </button>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No users have access.</p>
                    )
                  ) : allowedGroupsList.length > 0 ? (
                      <ul className="allowed-list">
                        {allowedGroupsList.map((group) => (
                          <li key={group.groupId} className="allowed-item">
                            <div className="allowed-text">{group.groupName}</div>
                            <button
                              className="remove-button"
                              onClick={() => handleRemoveGroupFromAllowed(group.groupId)}
                            >
                              &times;
                            </button>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No groups have access.</p>
                    )}
                </div>

                {/* ADD USERS */}
                <div className="edit-section">
                  <h4>Add User Access</h4>
                  <AsyncSelect
                    isMulti
                    cacheOptions
                    defaultOptions
                    loadOptions={(inputValue) =>
                      loadUserOptions(inputValue, allowedUsersList)
                    }
                    onChange={(selected) => setSelectedNewAllowedUsers(selected || [])}
                    value={selectedNewAllowedUsers}
                    placeholder="Search & select users..."
                    classNamePrefix="react-select"
                  />
                  <button className="add-button" onClick={handleAddAllowedUsers}>
                    Add User(s)
                  </button>
                </div>

                {/* ADD GROUPS */}
                <div className="edit-section">
                  <h4>Add Group Access</h4>
                  <AsyncSelect
                    isMulti
                    cacheOptions
                    defaultOptions
                    loadOptions={(inputValue) =>
                      loadGroupOptions(inputValue, allowedGroupsList)
                    }
                    onChange={(selected) => setSelectedNewAllowedGroups(selected || [])}
                    value={selectedNewAllowedGroups}
                    placeholder="Search & select groups..."
                    classNamePrefix="react-select"
                  />
                  <button className="add-button" onClick={handleAddAllowedGroups}>
                    Add Group(s)
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Account;