import React from 'react';
import './Auth0Preloader.css';

export const Auth0Preloader = () => {
  return (
    <div className="auth0-loader">
      <span className="auth0-loader-text">InOperis</span>
      <span className="auth0-dots">
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </span>
    </div>
  );
};