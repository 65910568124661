import React, { useEffect, useRef, useState, useMemo } from 'react';
import axios from 'axios';
import './videoplayer.css';
import {PulseLoader} from 'react-spinners';
import { ReactComponent as RestartIcon } from '../../assets/restart.svg';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import { useLocation, useNavigate } from "react-router-dom";


import {
  handleLoadedData,
  handleTimeUpdate,
  handleTimelineUpdate,
  handleVideoEnd,
  Drawing,
  CanvasManager,
  VideoPlaybackHandler,
  changePlaybackSpeed,
  clickAnimation,
  toggleColorPalette,
  toggleDrawMode,
  handlePlay,
  handlePause,
  formatDuration,
  smoothScrollTo



} from './videoplayer_lib.js';
import { useAuth0 } from '@auth0/auth0-react';

import Hls from 'hls.js'; // Import HLS.js library


const API_URL = process.env.REACT_APP_API_URL;



// only supports safari for now
function VideoPlayer({videoLink, videoMetadata}) {

  // some states and refs
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [canvasEdited, setCanvasEdited] = useState(false);
  const [drawMode, setDrawMode] = useState('');// pen,circle,arrow
  const [color, setColor] = useState('white');
  const [thickness, setThickness] = useState(2);
  const [loading, setLoading] = useState(true);
  const [preloading, setPreloading] = useState(true);
  const [canvasStatus, setCanvasStatus] = useState({});
  const [lastPausedTime, setLastPausedTime] = useState(0);
  const [isDrawing, setIsDrawing] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [isHlsSupported, setIsHlsSupported] = useState(true);
  const [isEditing, setIsEditing] = useState(true);
  const [isRetooling, setIsRetooling] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [isBuffering, setIsBuffering] = useState(false);
  const lastScrolledAnnotationTimeRef = useRef(null);
  const [playbackState, setPlaybackState] = useState('paused'); // 'playing', 'paused'
  const [activeTab, setActiveTab] = useState('procedure'); // Default to 'procedure'
  const [annotationTitle, setAnnotationTitle] = useState('');
  const [annotationCommentary, setAnnotationCommentary] = useState('');
  const [annotations, setAnnotations] = useState([]); // Add this line
  // e.g. "enterFullscreen", "exitFullscreen", "play", null
  const [pendingAction, setPendingAction] = useState(null);
  const [pendingParams, setPendingParams] = useState(null);
  // Inside VideoPlayer component
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [availableLevels, setAvailableLevels] = useState([]);
  const [selectedQuality, setSelectedQuality] = useState("auto");
  const [isQualityOpen, setIsQualityOpen] = useState(false);
  const [token, setToken] = useState(null);
  const [presentationMode, setPresentationMode] = useState(false);
  const [focusIndex, setFocusIndex] = useState(0);
  const [focusData, setFocusData] = useState(null);
  const [annotationFocusList, setAnnotationFocusList] = useState([]);
  const [savingAnnotation, setSavingAnnotation] = useState(false);
  const [showMoments, setShowMoments] = useState(false);
  const [initializeTimeline, setInitializeTimeline] = useState(true);
  const [refreshAnnotationsCounter, setRefreshAnnotationsCounter] = useState(0);
  const [pendingAnnotation, setPendingAnnotation] = useState(null);
  const [displayedAnnotation, setDisplayedAnnotation] = useState(null);  
  const [editingAnnotation, setEditingAnnotation] =  useState(true);
  const [annotationStrokes, setAnnotationStrokes] = useState([]);
  const [strokeFlag, setStrokeFlag] = useState(false);


  


  const playPauseInProgress = useRef(false);

  const hlsRef = useRef(null);
  const videoRef = useRef(null);
  const hotCanvasRef = useRef(null);
  const coldCanvasRef = useRef(null);
  const startX = useRef(0);
  const startY = useRef(0);
  const isScrubbingRef = useRef(false);
  const restartIconRef = useRef(null);
  const videoContainerRef = useRef(null);
  const blobCacheRef = useRef({});
  const didInitRef = useRef(false);
  



  const videoId = videoMetadata.videoId;

  // initialize helper classes
  const drawingTool = useMemo(() => new Drawing(), []);
  const canvasManager = useMemo(() => new CanvasManager(), []);
  const videoPlaybackHandler = useMemo(() => new VideoPlaybackHandler(), []);



  const itemsPerPage = 5;
const totalPages = Math.ceil(annotationFocusList.length / itemsPerPage);
const [currentPage, setCurrentPage] = useState(1);
const [isPageLoading, setIsPageLoading] = useState(false);

// Slice the annotations for the current page
const currentItems = annotationFocusList.slice(
  (currentPage - 1) * itemsPerPage,
  currentPage * itemsPerPage
);

useEffect(() => {
  if (annotationFocusList.length > 0) {
    // Immediately update the pending annotation based on focusIndex
    setPendingAnnotation(annotationFocusList[focusIndex]);
    // Clear the displayed annotation immediately
    setDisplayedAnnotation(null);
    // Then update displayedAnnotation after a short delay (adjust delay as needed)
    const timer = setTimeout(() => {
      setDisplayedAnnotation(annotationFocusList[focusIndex]);
    }, 150);
    return () => clearTimeout(timer);
  } else {
    setPendingAnnotation(null);
    setDisplayedAnnotation(null);
  }
}, [annotationFocusList, focusIndex]);


// handling the annotation edit mode
// checking if we are in editing mode
useEffect(() => {

  if (drawMode === "editing"){
    setEditingAnnotation(true);
  }else if(drawMode === ""){
    setEditingAnnotation(false);
  }

}, [drawMode]);
useEffect(() => {
  if (editingAnnotation && annotationFocusList.length > 0) {
    // Find the annotation that matches lastPausedTime (using Math.floor to avoid precision issues)
    const match = annotationFocusList.find(
      (ann) => Math.floor(ann.time) === Math.floor(lastPausedTime)
    );
    if (match) {
      setAnnotationTitle(match.title);
      setAnnotationCommentary(match.description);
    }
  }
}, [editingAnnotation, lastPausedTime, annotationFocusList]);

// When the currentPage changes, simulate a preload delay.
useEffect(() => {
  setIsPageLoading(true);
  const timer = setTimeout(() => {
    setIsPageLoading(false);
  }, 300); // adjust delay as needed
  return () => clearTimeout(timer);
}, [currentPage]);



  useEffect(() => {
    const getToken = async () => {
      const tok = await getAccessTokenSilently();
      setToken(tok);
    };
    getToken();
  }, [getAccessTokenSilently]);





  // fns to manage canvasStatus state
  function deleteCanvasStatus  (time) {
    setCanvasStatus(prevStatus => {
      const newStatus = { ...prevStatus };
      delete newStatus[time];
      return newStatus;
    });
  };
  function updateCanvasStatus (time, status)  {
    setCanvasStatus(prevStatus => ({
      ...prevStatus,
      [time]: status
    }));
  };
  function resetStatus() {
    setCanvasStatus(prevStatus => {
      const newStatus = {};
      for (const key in prevStatus) {
        newStatus[key] = "U";
      }
      return newStatus;
    });
  }


  const handleColorCycle = () => {
    const colorOptions = ['#61dafb', 'green', 'yellow', 'red', 'white', 'black'];
    const currentIndex = colorOptions.indexOf(color);
    const nextIndex = (currentIndex + 1) % colorOptions.length;
    setColor(colorOptions[nextIndex]);
  };

  // Function to cycle through thickness levels
  const handleThicknessCycle = () => {
    const thicknessLevels = [2, 5, 8];
    const currentIndex = thicknessLevels.indexOf(thickness);
    const nextIndex = (currentIndex + 1) % thicknessLevels.length;
    setThickness(thicknessLevels[nextIndex]);
  };



  // Handler for the <select> dropdown
  function changeQuality(levelIndex) {
    if (!hlsRef.current) return;
  
    if (levelIndex === "auto") {
      hlsRef.current.nextLevel = -1; // -1 = auto
      setSelectedQuality("Auto");
    } else {
      hlsRef.current.nextLevel = levelIndex; 
      const levelHeight = availableLevels[levelIndex].height;
      setSelectedQuality(`${levelHeight}p`);
    }
  
    // Close the dropdown so user can’t keep spamming
    setIsQualityOpen(false);
  }

  const cleanupBlobCache = (keysToKeep = []) => {
    // Loop over all keys in the cache
    Object.keys(blobCacheRef.current).forEach(key => {
      if (!keysToKeep.includes(key)) {
        // Revoke the Blob URL and remove it from the cache
        URL.revokeObjectURL(blobCacheRef.current[key]);
        delete blobCacheRef.current[key];
      }
    });
  };


 // hook to store time info along with annotation id for a focus
  useEffect(() => {
    async function fetchAnnotations() {
      if (!token || !videoId) {
        console.warn("Token or videoId not available");
        return;
      }
      try {
        // First, fetch the metadata to get the annotation IDs.
        const metaResponse = await axios.get(`${API_URL}/meta_annotation/video/${videoId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const metaAnnotations = metaResponse.data;
        // Sort metadata by time
        const sortedMeta = metaAnnotations.sort((a, b) => parseFloat(a.time) - parseFloat(b.time));
        
        // Now, for each annotation ID, fetch the full annotation (which contains focus data).
        const fullAnnotations = await Promise.all(
          sortedMeta.map(async (ann) => {
            const res = await axios.get(`${API_URL}/annotation/${ann.annotationId}`, {
              headers: { Authorization: `Bearer ${token}` }
            });
            return res.data;
          })
        );


        const annotationFocusData = fullAnnotations.map(annotation => ({
          annotationId: annotation.annotationId,
          time: parseFloat(annotation.time),
          offsetTime: parseFloat(JSON.parse(annotation.focus).timeOffset),
          thumbnail: annotation.videoFrameUrl,
          description: annotation.description,
          title: annotation.title

        }));


        
        setAnnotationFocusList(annotationFocusData);

      } catch (error) {
        console.error("Error fetching annotations: ", error);
      }
    }
    
    fetchAnnotations();

  }, [token, videoId, refreshAnnotationsCounter]);



// Effect to fetch the full annotation focusData for the current focusIndex
useEffect(() => {
  async function fetchFocusData() {
    if (!token || annotationFocusList.length === 0) return;
    const annotationId = annotationFocusList[focusIndex].annotationId;
    // Only re-fetch if we don't already have focus data for this annotation.
    if (focusData && focusData.annotationId === annotationId) {
      return;
    }
    try {
      const response = await axios.get(`${API_URL}/annotation/${annotationId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const annotationDetails = response.data;
      let parsedFocus = null;
      try {
        parsedFocus = annotationDetails.focus ? JSON.parse(annotationDetails.focus) : null;
      } catch (err) {
        console.error("Error parsing focus data:", err);
      }
      // Store the annotationId along with the parsed focus data
      setFocusData({ ...parsedFocus, annotationId });
    } catch (error) {
      console.error("Error fetching focus data:", error);
    }
  }
  fetchFocusData();
}, [focusIndex, token, annotationFocusList]);


  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;
    let hls;
  
    const handleWaiting = () => {
      setIsBuffering(true);
    };
  
    const handlePlaying = () => {
      setLoading(false);
      setIsBuffering(false);
    };
  
    const handleLoadedMetadata = () => {
      video.currentTime = 0;
      setLoading(false);
      setIsBuffering(false);
    };
  
    const handleError = (event, data) => {
      console.error('HLS.js error:', data);
      if (data.type === Hls.ErrorTypes.NETWORK_ERROR) {
        setLoading(false);
        setVideoError(true);
        setIsBuffering(false);
      }
    };
  
    // Determine which source to load based on presentationMode.
    // If presentationMode is true, we build our focus (mini master) via blobs.
    let sourceToLoad;
    if (presentationMode && focusData) {
      // --- Focus Streaming ---
      const startDir = focusData.startDir || 0;
      const startSeg = focusData.startSeg || 0;



      const pad5 = (num) => String(num).padStart(5, '0');
      const focusVideoId = videoMetadata.videoId; 
      const CF = process.env.REACT_APP_CLOUDFRONT_LINK;
  
      // For 720p:
      let secondDir720 = startDir;
      let secondSeg720 = startSeg + 1;
      if (secondSeg720 > 10) {
        secondDir720 = startDir + 1;
        secondSeg720 = 1;
      }
      const focus720Lines = [
        "#EXTM3U",
        "#EXT-X-VERSION:3",
        "#EXT-X-TARGETDURATION:10",
        "#EXT-X-PLAYLIST-TYPE:VOD",
        "#EXTINF:10,",
        `${CF}/videos/${focusVideoId}/HLS/${focusVideoId}_720p/${pad5(startDir)}/${focusVideoId}_720p_${pad5(startSeg)}.ts`,
        "#EXTINF:10,",
        `${CF}/videos/${focusVideoId}/HLS/${focusVideoId}_720p/${pad5(secondDir720)}/${focusVideoId}_720p_${pad5(secondSeg720)}.ts`,
        "#EXT-X-ENDLIST"
      ];
      const focus720String = focus720Lines.join("\n");

      // For 480p:
      let secondDir480 = startDir;
      let secondSeg480 = startSeg + 1;
      if (secondSeg480 > 10) {
        secondDir480 = startDir + 1;
        secondSeg480 = 1;
      }
      const focus480Lines = [
        "#EXTM3U",
        "#EXT-X-VERSION:3",
        "#EXT-X-TARGETDURATION:10",
        "#EXT-X-PLAYLIST-TYPE:VOD",
        "#EXTINF:10,",
        `${CF}/videos/${focusVideoId}/HLS/${focusVideoId}_480p/${pad5(startDir)}/${focusVideoId}_480p_${pad5(startSeg)}.ts`,
        "#EXTINF:10,",
        `${CF}/videos/${focusVideoId}/HLS/${focusVideoId}_480p/${pad5(secondDir480)}/${focusVideoId}_480p_${pad5(secondSeg480)}.ts`,
        "#EXT-X-ENDLIST"
      ];
      const focus480String = focus480Lines.join("\n");
  
      const focusMasterLines = [
        "#EXTM3U",
        "#EXT-X-VERSION:3",
        "#EXT-X-INDEPENDENT-SEGMENTS",
        '#EXT-X-STREAM-INF:BANDWIDTH=2860163,AVERAGE-BANDWIDTH=2860163,CODECS="avc1.64001f",RESOLUTION=1280x720,FRAME-RATE=25.000',
        "focus720.m3u8",
        '#EXT-X-STREAM-INF:BANDWIDTH=1191521,AVERAGE-BANDWIDTH=1191521,CODECS="avc1.64001e",RESOLUTION=854x480,FRAME-RATE=25.000',
        "focus480.m3u8"
      ];
      const focusMasterString = focusMasterLines.join("\n");
  
      // Cleanup old Blob URLs before generating new ones
      cleanupBlobCache(["focusMaster", "focus720", "focus480"]);
  
      // Convert sub-playlist strings to Blob URLs
      const focus720Blob = new Blob([focus720String], { type: 'application/vnd.apple.mpegurl' });
      const focus720Url = URL.createObjectURL(focus720Blob);
      const focus480Blob = new Blob([focus480String], { type: 'application/vnd.apple.mpegurl' });
      const focus480Url = URL.createObjectURL(focus480Blob);
  
      // Store these in the cache
      blobCacheRef.current["focus720"] = focus720Url;
      blobCacheRef.current["focus480"] = focus480Url;
  
      // Replace placeholder names in the master with Blob URLs
      const updatedFocusMasterString = focusMasterString
        .replace("focus720.m3u8", focus720Url)
        .replace("focus480.m3u8", focus480Url);
      
      const focusMasterBlob = new Blob([updatedFocusMasterString], { type: 'application/vnd.apple.mpegurl' });
      const focusMasterUrl = URL.createObjectURL(focusMasterBlob);
      
      blobCacheRef.current["focusMaster"] = focusMasterUrl;
      
      sourceToLoad = focusMasterUrl;
      console.log("Focus mode enabled. Loading master URL:", sourceToLoad);
    } else {
      sourceToLoad = videoLink;
    }
  
    // Initialize Hls.js
    if (Hls.isSupported()) {
      const hlsConfig = {
        capLevelToPlayerSize: true,
        maxBufferLength: 20,
        maxMaxBufferLength: 30,
        startLevel: 0,
        maxBufferHole: 1,
        abrBandWidthFactor: 0.8,
        abrBandWidthUpFactor: 0.5,
        fragLoadingTimeOut: 30000,
        fragLoadingMaxRetry: 5,
      };
  
      hls = new Hls(hlsConfig);
      hlsRef.current = hls;
      hls.loadSource(sourceToLoad);
      hls.attachMedia(video);
  
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        const levels = hls.levels;
        setAvailableLevels(levels);
        /*if (presentationMode) {
          // For autoplay to work across browsers, mute the video first.
          const wasMuted = video.muted;
          video.muted = true;
          video.play().then(() => {
            // Autoplay succeeded. If you want to restore mute state, you can do so here.
            // For example, if you want unmuted playback after user interaction, you can update it.
            // video.muted = wasMuted;  // Uncomment if you want to restore the original mute state.
          }).catch(err => {
            console.error("Autoplay failed:", err);
          });
        }*/
      });
  
      hls.on(Hls.Events.ERROR, handleError);
  
      video.addEventListener('waiting', handleWaiting);
      video.addEventListener('playing', handlePlaying);
      video.addEventListener('loadedmetadata', handleLoadedMetadata);
  
      return () => {
        hls.destroy();
        hls.off(Hls.Events.ERROR, handleError);
        video.removeEventListener('waiting', handleWaiting);
        video.removeEventListener('playing', handlePlaying);
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = sourceToLoad;
      video.addEventListener('waiting', handleWaiting);
      video.addEventListener('playing', handlePlaying);
      video.addEventListener('loadedmetadata', handleLoadedMetadata);
      video.onerror = () => {
        console.error('Native video error');
        setLoading(false);
        setVideoError(true);
        setIsBuffering(false);
      };
      return () => {
        Object.values(blobCacheRef.current).forEach(url => URL.revokeObjectURL(url));
        blobCacheRef.current = {};
        if (hls) {
          hls.destroy();
        }
        video.removeEventListener('waiting', handleWaiting);
        video.removeEventListener('playing', handlePlaying);
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    } else {
      console.error('HLS is not supported on this browser.');
      setLoading(false);
      setVideoError(true);
    }


  }, [videoLink, videoMetadata, presentationMode, focusData]);





  // hook for tracing and scrubbing (videoPlaybackHandler)
  useEffect(() => {


    // components to attach event listeners
    const currentTimeElem = document.querySelector(".current-time")
    const videoContainer = document.querySelector(".editor-container")
    const timelineContainer = document.querySelector(".timeline-container")
    const video = document.querySelector("video")

    if (!video || !currentTimeElem || !videoContainer || !timelineContainer) {
      return;
    }
    if (typeof window === 'undefined' || typeof document === 'undefined') {
      return;
    }

    // arguments for video playback handler
    const args = {
      videoRef: videoRef,
      videoLink: videoLink,
      videoContainer: videoContainer,
      coldCanvasRef: coldCanvasRef,
      setCanvasEdited: setCanvasEdited,
      setLastPausedTime: setLastPausedTime,
      setPlaybackState: setPlaybackState,
      lastPausedTime: lastPausedTime,
      canvasManager: canvasManager,
      canvasStatus: canvasStatus,
      updateCanvasStatus: updateCanvasStatus,
      presentationMode: presentationMode,
      focusIndex: focusIndex,
      annotationFocusList: annotationFocusList,
      currentTimeElem: currentTimeElem,
      timelineContainer: timelineContainer,
      isScrubbingRef: isScrubbingRef,
      setLoading: setLoading,
      hotCanvasRef: hotCanvasRef,
      document: document,
      setDrawMode: setDrawMode,
      resetStatus: resetStatus,
      token: token,
      videoId: videoId,
      setAnnotationCommentary: setAnnotationCommentary,
      setAnnotationTitle:setAnnotationTitle,
      setAnnotations: setAnnotations,
      setPreloading: setPreloading,
      setPendingAction: setPendingAction,
      drawMode: drawMode,
      hasUnsavedChanges: hasUnsavedChanges,
      setHasUnsavedChanges: setHasUnsavedChanges,
      setFocusIndex: setFocusIndex,
      presentationMode: presentationMode,

    }

    const areAnyArgsNull = Object.values(args).some(value => value === null || value === undefined);

    if (areAnyArgsNull) {
      return;
    }

    // setting arguments
    videoPlaybackHandler.setArgs(args);

    // some helper functions
    const drawTracingPlay = () => {
      videoPlaybackHandler.drawTracingPlay();
    };

    const drawTracingPause = () => {
      videoPlaybackHandler.drawTracingPause();
    };




    const handleMouseUp = (e) => {
      if (isScrubbingRef.current) {
        videoPlaybackHandler.toggleScrubbing(e, loading);
      }
    };

    const handleToggleScrubbing = (e) => {
      videoPlaybackHandler.toggleScrubbing(e, loading);
    };

    const handleVisibilityChange = (e) => {
    if (!videoRef.current) return
      
      if (!videoRef.current.paused){
         //videoRef.current.pause();
         canvasManager.togglePlay({
          playbackState,
          setPlaybackState,
          playPauseInProgress,
        });
      }

      if (document.visibilityState === 'visible') {
        videoPlaybackHandler.handleVisibilityChange(true);
        handleTimelineUpdate(e, timelineContainer, isScrubbingRef.current)

      } else {
        videoPlaybackHandler.handleVisibilityChange(false);
      }
    };
    const pauseWhenNoVisibility = () => {
      if (!videoRef.current) return

      if (document.visibilityState !== 'visible') {
        videoRef.current.pause();
        setPlaybackState("paused")
        if (!videoRef.current.paused){
          canvasManager.togglePlay({
            playbackState,
            setPlaybackState,
            playPauseInProgress,
          });

        }
        
      }
    };

const handleLoadedMetadata = async () => {
  if (!initializeTimeline||didInitRef.current) return;

  didInitRef.current = true;
  await videoPlaybackHandler.initializeTimelineIndicators();

  if (presentationMode) {
    videoRef.current.muted = true;
    videoRef.current.play();
  }

  // Wait for the video to be ready to play
  const video = videoRef.current;
  if (video) {
    await Promise.race([
      new Promise(resolve => {
        video.addEventListener("canplay", resolve, { once: true });
      }),
      new Promise(resolve => setTimeout(resolve, 1000)) // 1-second fallback
    ]);
  }

  setInitializeTimeline(false);
};



    // Add event listeners
timelineContainer.addEventListener("mouseleave", () => {
  timelineContainer.style.setProperty("--preview-position", "0");
});


    // add event listeners
    video.addEventListener("timeupdate", pauseWhenNoVisibility);
    document.addEventListener('visibilitychange', e => handleVisibilityChange(e));    
    window.addEventListener('resize', () => videoPlaybackHandler.setCanvasSize());
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("webkitfullscreenchange", () => videoPlaybackHandler.handleFullScreenChange());
    document.addEventListener("fullscreenchange", () => videoPlaybackHandler.handleFullScreenChange());



    video.addEventListener('loadedmetadata', handleLoadedMetadata);


    video.addEventListener('play', drawTracingPlay);
    video.addEventListener('pause', drawTracingPause);
    video.addEventListener('loadedmetadata',() =>  videoPlaybackHandler.setCanvasSize());
    timelineContainer.addEventListener("mousedown", handleToggleScrubbing);
    // remove event listeners
    return () => {
      timelineContainer.removeEventListener("mouseleave", () => {
        timelineContainer.style.setProperty("--preview-position", "0");
      });
      
      video.removeEventListener("timeupdate", pauseWhenNoVisibility);
      document.removeEventListener('visibilitychange', e => handleVisibilityChange(e));
      window.removeEventListener('resize', () => videoPlaybackHandler.setCanvasSize());
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("webkitfullscreenchange", () => videoPlaybackHandler.handleFullScreenChange());
      document.removeEventListener("fullscreenchange", () => videoPlaybackHandler.handleFullScreenChange());
      video.removeEventListener('loadedmetadata',() =>  videoPlaybackHandler.setCanvasSize());
      video.removeEventListener('play', drawTracingPlay);
      video.removeEventListener('pause', drawTracingPause);


      
      video.removeEventListener('loadedmetadata', handleLoadedMetadata);



      timelineContainer.removeEventListener("mousedown", handleToggleScrubbing);
    };
  },  [token,initializeTimeline,presentationMode, annotationFocusList, presentationMode, focusIndex, hasUnsavedChanges,playbackState, playPauseInProgress, videoId,drawMode,canvasEdited, color, thickness, videoLink, canvasStatus, loading, lastPausedTime, canvasManager, videoPlaybackHandler]);

/*

            setTimeout(() => {
            }, 1000);      */

  // hook for handling the timeline indicators
  // triggers when changing presentation mode or focus data
  useEffect(() => {


      setInitializeTimeline(true)
      didInitRef.current = false;

  }, [presentationMode, focusIndex]);




  // hook for video controls (canvasManager)
  useEffect(() => {


    // components to attach event listeners
    const components = {
      videoContainer: document.querySelector(".editor-container"),
      videoEditContainer: document.querySelector(".edit-tools-container"),
      speedBtn: document.querySelector(".speed-btn"),
      currentTimeElem: document.querySelector(".current-time"),
      timelineContainer: document.querySelector(".timeline-container"),
      video: document.querySelector("video"),
      playPauseBtn: document.querySelector(".play-pause-btn"),
      fullScreenBtn: document.querySelector(".full-screen-btn"),
      editBtn: document.querySelector(".edit-btn"),
      trashBtn: document.querySelector(".trash-btn"),
      saveBtn: document.querySelector(".save-btn"),

      colorBtn: document.querySelector(".color-btn"),
      totalTimeElem: document.querySelector(".total-time"),

    };

    const saveBtn2= document.querySelector(".save-btn-2");

    if (Object.values(components).some(value => value === null || value === undefined)) {
      return;
    }

    const canvasManagerArgs = {
      coldCanvasRef: coldCanvasRef,
      setCanvasEdited: setCanvasEdited,
      setDrawMode: setDrawMode,
      loading: loading,
      lastPausedTime: lastPausedTime,
      videoRef: videoRef?.current ? videoRef : null,  // Prevent undefined reference
      videoLink: videoLink,
      document: document,
      canvasStatus: canvasStatus,
      canvasEdited: canvasEdited,
      resetStatus: resetStatus,
      updateCanvasStatus: updateCanvasStatus,
      deleteCanvasStatus: deleteCanvasStatus,
      setCanvasStatus: setCanvasStatus,
      videoContainer: components.videoContainer ?? null, // Ensure it exists
      videoEditContainer: components.videoEditContainer ?? null,
      setLastPausedTime: setLastPausedTime,
      setPlaybackState: setPlaybackState,
      currentTimeElem: components.currentTimeElem ?? null,
      timelineContainer: components.timelineContainer ?? null,
      speedBtn: components.speedBtn ?? null,
      restartIconRef: restartIconRef,
      token: token,
      videoId: videoId,
      drawMode: drawMode,
      annotationCommentary: annotationCommentary,
      annotationTitle: annotationTitle,
      setAnnotationCommentary: setAnnotationCommentary,
      setAnnotationTitle: setAnnotationTitle,
      setAnnotations: setAnnotations,
      annotations: annotations,
      videoContainerRef: videoContainerRef?.current ? videoContainerRef : null,
      setPendingAction: typeof setPendingAction === "function" ? setPendingAction : null, // Ensure function exists
      hasUnsavedChanges: hasUnsavedChanges,
      setHasUnsavedChanges: setHasUnsavedChanges,
      setPendingParams: setPendingParams,
      savingAnnotation: savingAnnotation,
      setSavingAnnotation: setSavingAnnotation,
      availableLevels: availableLevels,
      hlsRef: hlsRef,
      setRefreshAnnotationsCounter: setRefreshAnnotationsCounter,
      presentationMode: presentationMode,
      setPresentationMode: setPresentationMode,
      editingAnnotation: editingAnnotation,
      annotationFocusList: annotationFocusList,
      setEditingAnnotation: setEditingAnnotation,
      annotationStrokes: annotationStrokes,
      setAnnotationStrokes: setAnnotationStrokes,
    };

    const areAnyArgsNull = Object.values(canvasManagerArgs).some(value => value === null || value === undefined);

    if (areAnyArgsNull) {
      return;
    }

    // setting arguments
    canvasManager.setArgs(canvasManagerArgs);

    // stable references to avoid latency
    const handleFullscreen = (e) => {
      e.preventDefault();
      e.stopPropagation();
      canvasManager.toggleFullScreenMode()
    };
    /*const handleEditContainer = () => {
      canvasManager.toggleVideoEditContainer();
    };*/
    const handleTogglePlay = () => {
      canvasManager.togglePlay({
        playbackState,
        setPlaybackState,
        playPauseInProgress,
      });
    };
    const handleKeyDown = (e) => {
      canvasManager.keyDown(e,playbackState, setPlaybackState, playPauseInProgress);
    };
    const handleSpeedChange = () => {
      changePlaybackSpeed(videoRef,components.speedBtn);
    };
    const handleEditToggle = () => {
      setIsEditing(prev => !prev);
    };

    
      
    // event listeners
    components.editBtn.addEventListener('click', handleEditToggle);
    restartIconRef.current.addEventListener('click', handleTogglePlay); // this event listener is not removed because its a ref
    components.video.addEventListener('ended', () => canvasManager.toggleRestartIcon());
    components.speedBtn.addEventListener("click", handleSpeedChange);
    components.fullScreenBtn.addEventListener("click", handleFullscreen);
    if (presentationMode){
      components.video.addEventListener("dblclick", handleFullscreen);
    };
    components.playPauseBtn.addEventListener("click", handleTogglePlay);
    //components.editBtn.addEventListener("click", handleEditContainer);
    components.trashBtn.addEventListener("click", () => canvasManager.toggleDeleteCanvas());
    components.saveBtn.addEventListener("click", () => canvasManager.saveCanvas() );
    components.saveBtn.addEventListener("click", () => clickAnimation(components.saveBtn));
    if (saveBtn2){
      saveBtn2.addEventListener("click", () => canvasManager.saveCanvas() );
      saveBtn2.addEventListener("click", () => clickAnimation(saveBtn2));
    }
    components.trashBtn.addEventListener("click", () => clickAnimation(components.trashBtn));
    components.colorBtn.addEventListener("click", () => clickAnimation(components.colorBtn));
    document.addEventListener('keydown', handleKeyDown);
    components.video.addEventListener("loadeddata", () => handleLoadedData(videoRef, components.totalTimeElem));
    components.video.addEventListener("timeupdate", () => handleTimeUpdate(videoRef, components.currentTimeElem, components.timelineContainer));
    components.video.addEventListener("click", handleTogglePlay);
    components.video.addEventListener("play", () => handlePlay(videoRef, components.videoContainer, lastPausedTime, canvasManager));
    components.video.addEventListener("pause", () =>  handlePause(videoRef, components.videoContainer, setLastPausedTime));
    components.video.addEventListener("ended", () => handleVideoEnd(videoRef, lastPausedTime, setPlaybackState));
    components.video.addEventListener("ended", resetStatus);

    // removing the event listeners
    return () => {
      components.editBtn.removeEventListener('click', handleEditToggle);
      components.video.removeEventListener('ended', () => canvasManager.toggleRestartIcon());
      document.removeEventListener('keydown', handleKeyDown);
      components.speedBtn.removeEventListener("click", handleSpeedChange);
      components.fullScreenBtn.removeEventListener("click",handleFullscreen);
      if (presentationMode){
        components.video.removeEventListener("dblclick", handleFullscreen);
      };
      components.playPauseBtn.removeEventListener("click", handleTogglePlay);
      //components.editBtn.removeEventListener("click", handleEditContainer);
      components.trashBtn.removeEventListener("click", () => canvasManager.toggleDeleteCanvas());
      components.saveBtn.removeEventListener("click", () => canvasManager.saveCanvas());
      components.saveBtn.removeEventListener("click", () => clickAnimation(components.saveBtn));
      if (saveBtn2){
        saveBtn2.removeEventListener("click", () => canvasManager.saveCanvas());
        saveBtn2.removeEventListener("click", () => clickAnimation(saveBtn2));
      };
      components.trashBtn.removeEventListener("click", () => clickAnimation(components.trashBtn));
      components.colorBtn.removeEventListener("click", () => clickAnimation(components.colorBtn));
      components.video.removeEventListener("loadeddata", () => handleLoadedData(videoRef, components.totalTimeElem));
      components.video.removeEventListener("timeupdate", () => handleTimeUpdate(videoRef, components.currentTimeElem, components.timelineContainer));
      components.video.removeEventListener("click", handleTogglePlay);
      components.video.removeEventListener("play", () => handlePlay(videoRef, components.videoContainer, lastPausedTime, canvasManager));
      components.video.removeEventListener("pause", () => handlePause(videoRef, components.videoContainer, setLastPausedTime));
      components.video.removeEventListener("ended", () => handleVideoEnd(videoRef, lastPausedTime, setPlaybackState));
      components.video.removeEventListener("ended", resetStatus);
    }
  },[token, annotationStrokes, annotationFocusList, editingAnnotation, presentationMode, availableLevels, hlsRef, savingAnnotation, hasUnsavedChanges,pendingAction, annotationTitle, annotations, annotationCommentary,videoId, videoLink, canvasEdited, color, thickness, drawMode, canvasStatus, loading, lastPausedTime, canvasManager, videoPlaybackHandler, playPauseInProgress, playbackState]);


  // hook for drawing tool (drawingTool)
  useEffect(() => {
    // components to attach event listeners
    const hotCanvasObj = hotCanvasRef.current;
    const coldCanvasObj = coldCanvasRef.current;

    if (!hotCanvasObj || !coldCanvasObj){
      return;
    }

    // arguments for drawing tool
    const args = {
      hotCanvas: hotCanvasObj,
      coldCanvas: coldCanvasObj,
      ctx_cold: coldCanvasObj.getContext('2d'),
      ctx_hot: hotCanvasObj.getContext('2d'),
      startX: startX,
      startY: startY,
      color: color,
      thickness: thickness,
      drawMode: drawMode,
      hasUnsavedChanges: hasUnsavedChanges,
      setHasUnsavedChanges: setHasUnsavedChanges,
      setAnnotationStrokes: setAnnotationStrokes,
      strokeFlag: strokeFlag, 
      setStrokeFlag: setStrokeFlag,
    };

    const areAnyArgsNull = Object.values(args).some(value => value === null || value === undefined);

    if (areAnyArgsNull) {
      return;
    }

    // setting arguments
    drawingTool.updateDrawConfig(args);


    // helpers to select the correct draw function
    const handleStartDrawing = (e) => {
      drawingTool.drawFunctionMap[drawMode].start(e);
      setIsDrawing(true);
    };
    const handleDraw = (e) => {
      if (!isDrawing) return;
      drawingTool.drawFunctionMap[drawMode].draw(e);
      setCanvasEdited(true);
    };
    const handleStopDrawing = () => {
      setIsDrawing(false);
      drawingTool.drawFunctionMap[drawMode].stop();
    };

    
    // event listeners
    if (drawMode !== '') {
      hotCanvasObj.addEventListener('mousedown', handleStartDrawing);
      hotCanvasObj.addEventListener('mousemove', handleDraw);
      hotCanvasObj.addEventListener('mouseup', handleStopDrawing);
      hotCanvasObj.addEventListener('mouseout', handleStopDrawing);
    }

    // removing the event listeners
    return () => {
      hotCanvasObj.removeEventListener('mousedown', handleStartDrawing);
      hotCanvasObj.removeEventListener('mousemove', handleDraw);
      hotCanvasObj.removeEventListener('mouseup', handleStopDrawing);
      hotCanvasObj.removeEventListener('mouseout', handleStopDrawing);
    };
  }, [token, drawMode, color, thickness, isDrawing, drawingTool, hasUnsavedChanges, strokeFlag]);

  // hook to remove default video controls
  useEffect(() => {

    // some helper functions
    const videoContainer = document.querySelector(".editor-container");
    if (!videoContainer) return;
    const preventDefault = (e) => e.preventDefault();
    const preventLeftClick = (e) => {
      if (e.button === 0) {
        e.preventDefault();
      }
    };

    // adding event listeners
    videoContainer.addEventListener('contextmenu', preventDefault);
    videoContainer.addEventListener('mousedown', preventLeftClick);
    videoContainer.addEventListener('dragstart', preventDefault);
    videoContainer.addEventListener('selectstart', preventDefault);

    // removing event listeners
    return () => {
      videoContainer.removeEventListener('contextmenu', preventDefault);
      videoContainer.removeEventListener('mousedown', preventLeftClick);
      videoContainer.removeEventListener('dragstart', preventDefault);
      videoContainer.removeEventListener('selectstart', preventDefault);
    };
  }, []);


  // if go next index to a new page change page
  useEffect(() => {
    const newPage = Math.floor(focusIndex / itemsPerPage) + 1;
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
    }
  }, [focusIndex, itemsPerPage]);



  
  // Hook for indicators
  useEffect(() => {

    // Components to attach event listeners
    const currentTimeElem = document.querySelector('.current-time');
    const videoContainer = document.querySelector('.editor-container');
    const timelineContainer = document.querySelector('.timeline-container');
    const video = document.querySelector('video');

    if (!video || !currentTimeElem || !videoContainer || !timelineContainer) {
      return;
    }
    if (typeof window === 'undefined' || typeof document === 'undefined') {
      return;
    }

    // Arguments for video playback handler
    const args = {
      videoRef: videoRef,
      videoLink: videoLink,
      videoContainer: videoContainer,
      coldCanvasRef: coldCanvasRef,
      setCanvasEdited: setCanvasEdited,
      setLastPausedTime: setLastPausedTime,
      lastPausedTime: lastPausedTime,
      canvasManager: canvasManager,
      canvasStatus: canvasStatus,
      updateCanvasStatus: updateCanvasStatus,
      currentTimeElem: currentTimeElem,
      timelineContainer: timelineContainer,
      isScrubbingRef: isScrubbingRef,
      setLoading: setLoading,
      hotCanvasRef: hotCanvasRef,
      document: document, 
      setDrawMode: setDrawMode,
      resetStatus: resetStatus,
      setPlaybackState: setPlaybackState,
      token: token,
      videoId: videoId,
      loading: loading, // Include loading state
      setAnnotationTitle: setAnnotationTitle,
      setAnnotationCommentary: setAnnotationCommentary,
      setAnnotations: setAnnotations,
      setPreloading: setPreloading,
      setPendingAction: setPendingAction,
      drawMode: drawMode,
      hasUnsavedChanges: hasUnsavedChanges,
      setHasUnsavedChanges: setHasUnsavedChanges,
      annotationFocusList: annotationFocusList,
      focusIndex: focusIndex,
      presentationMode: presentationMode,
      setFocusIndex: setFocusIndex,
      presentationMode:presentationMode,
    };

    const areAnyArgsNull = Object.values(args).some(
      (value) => value === null || value === undefined
    );

    if (areAnyArgsNull) {
      return;
    }

    // Setting arguments
    videoPlaybackHandler.setArgs(args);

    // Set up indicator event listeners
    videoPlaybackHandler.setupIndicatorEventListeners();

    const removeGlowingIndicators = () => {
      const video = document.querySelector('video');
      if (!video) return;
    
      // Use the video's current time (as a number with 4 decimals)
      const videoTime = parseFloat(video.currentTime.toFixed(4));
      const tolerance = 0.1; // seconds
    
      // Process timeline indicators
      const glowingIndicators = document.querySelectorAll('.timeline-indicator.glowing');
      glowingIndicators.forEach(indicator => {
        // Extract the numeric time from the indicator's ID.
        // The ID is assumed to start with "indicator-"
        let idStr = indicator.id.slice("indicator-".length);
        // If there is a dash (for actualTime), take the first part.
        if (idStr.includes('-')) {
          idStr = idStr.split('-')[0];
        }
        const indicatorTime = parseFloat(idStr);
        // Remove glow if the difference exceeds the tolerance.
        if (isNaN(indicatorTime) || Math.abs(indicatorTime - videoTime) > tolerance) {
          indicator.classList.remove('glowing');
        }
      });
    
      // Process annotation cards similarly.

    };
    // Debounce function
    function debounce(func, wait) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    }


  // Function to handle time updates and scroll annotations
  const handleTimeUpdate = debounce(() => {
    const video = document.querySelector('video');
    if (!video) return;

    const currentTime = video.currentTime;

    // Get all annotations and their times
    const annotationCards = Array.from(document.querySelectorAll('.annotation-card'));
    const annotationTimes = annotationCards.map((card) => ({
      time: parseFloat(card.getAttribute('data-time')),
      card: card
    }));

    // Find the last annotation that occurs at or before the current time
    const currentAnnotation = annotationTimes
      .filter((annotation) => annotation.time <= currentTime)
      .sort((a, b) => b.time - a.time)[0]; // Get the latest annotation

    if (currentAnnotation) {
      const { time, card } = currentAnnotation;

      // Check if this annotation is different from the last scrolled annotation
      if (lastScrolledAnnotationTimeRef.current !== time) {
        lastScrolledAnnotationTimeRef.current = time; // Update the last scrolled annotation time

        // Scroll the annotation card into view
        const annotationList = document.querySelector('.annotation-list');
        if (annotationList) {
          const offset = 20; // Adjust the offset as needed
          smoothScrollTo(annotationList, card, 1000, offset);
        }
      }
    }
  }, 200); // Adjust debounce delay as needed


  


    // Attach event listeners
    video.addEventListener('timeupdate', handleTimeUpdate);
    video.addEventListener('timeupdate', removeGlowingIndicators);

    // Cleanup on unmount
    return () => {
      videoPlaybackHandler.cleanupIndicatorEventListeners();
      video.removeEventListener('timeupdate', handleTimeUpdate);
      video.removeEventListener('timeupdate', removeGlowingIndicators);

    };
  }, [
    videoPlaybackHandler,
    videoRef,
    canvasStatus,
    loading, canvasManager, lastPausedTime, videoId, videoLink, hasUnsavedChanges,
    presentationMode, focusIndex, annotationFocusList,  drawMode, token, presentationMode
  ]);


  // hook for leaving the page
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (drawMode !== '') {
        e.preventDefault();
        e.returnValue = ''; 
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [drawMode]);

  useEffect(() => {
    const handleDrawModeChange = () => {
      window.dispatchEvent(new CustomEvent("drawModeUpdate", { detail: drawMode }));
    };

    handleDrawModeChange(); // Trigger on first render
    return () => window.removeEventListener("drawModeUpdate", handleDrawModeChange);
  }, [drawMode]);



  /*if ( videoError) {
    return (
      <div className="loading-message-container">
        <div className="surgeon-icons">
          <span role="img" aria-label="surgical-mask" className="bacteria">🦠</span>
          <span role="img" aria-label="syringe" className="bacteria">🦠</span>
          <span role="img" aria-label="hospital" className="bacteria">🦠</span>
        </div>
        <p className="loading-message-text">
          The procedure is still underway! Please scrub in and check back soon for the full operation.
        </p>
        
      </div>
    );
  }*/
  






  return (
    <div className="main-container">

      {savingAnnotation && (
        <div className="annotation-save-modal">
          <div className="annotation-save-modal-content">
            <div className="annotation-save-loader"></div>
            <p>Saving annotation, please wait...</p>
          </div>
        </div>
      )}

      <div className="editor-container paused" data-volume-level="high" ref={videoContainerRef} >
          {/* Edit Tools Sidebar */}
          <div className="edit-tools-container" style={{ backgroundColor: presentationMode ? 'black' : 'lightgray' }}>

          <button
            className={`toggle-moments-btn gen-btn ${showMoments ? 'active' : ''} ${!presentationMode ? 'placeholder-btn-min' : ''}`}
            onClick={() => setShowMoments(!showMoments)}
          >
            ≡ {/* or an inline SVG icon */}
          </button>

          <button
            className={`info-btn gen-btn ${showInfo ? 'active' : ''} ${presentationMode ? 'placeholder-btn-min' : ''}`}
            onClick={() => setShowInfo(!showInfo)}
          >
            <InfoIcon/>
          </button>

          
          <div
          className={`toggle-slider ${presentationMode ? 'presentation' : 'edit'} ${!annotationFocusList || annotationFocusList.length < 1 ? 'disabled' : ''}`}
          onClick={() => {
                  // various states handling in when toggling presentation mode
                  setLastPausedTime(0);
                  setPresentationMode(!presentationMode);
                  setFocusIndex(0);
                  setInitializeTimeline(true);
                  didInitRef.current = false;
                  setShowMoments(false);
                  document.querySelector(".speed-btn").textContent = "1x"
                  if (videoContainerRef.current) {
                    if (!presentationMode) {
                      // Enter presentation mode: request fullscreen and add "paused" class
                      videoContainerRef.current.requestFullscreen();
                    } else {
                      // Leaving presentation mode: if in fullscreen, exit it
                      if (document.fullscreenElement) {
                        document.exitFullscreen();
                      }
                      videoContainerRef.current.classList.add("paused");

                    }
                  }
                }}
          >
                <div className="slider-circle"></div>
              </div>


          




            {/* Edit Toggle Button */}
            <button className={`edit-btn gen-btn ${isEditing ? 'active' : ''} ${presentationMode ? 'presentation-edit' : ''}`}>
              <svg className="pencil" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M8.56078 20.2501L20.5608 8.25011L15.7501 3.43945L3.75012 15.4395V20.2501H8.56078ZM15.7501 5.56077L18.4395 8.25011L16.5001 10.1895L13.8108 7.50013L15.7501 5.56077ZM12.7501 8.56079L15.4395 11.2501L7.93946 18.7501H5.25012V16.0608L12.7501 8.56079Z"
                />
              </svg>
            </button>
          {/* Edit Tools (Always rendered, visibility controlled by CSS) */}



          <div
            className={`edits ${isEditing ? 'visible' : ''} ${
              presentationMode ? 'presentation-edits' : ''
            }`}
          >
              <button className={`draw-btn ${drawMode === 'pen' ? 'active' : ''}`}
                onClick={() => toggleDrawMode('pen', videoRef, setDrawMode, setPlaybackState)}
              >
                <svg className="draw" viewBox="0 0 24 24">
                  <path
                    fill="none"
                    stroke="#000000"
                    d="M3,13.5793719 C9.98914658,5.83454059 14.2442185,2.78592627 15.7652158,4.43352892 C18.0467117,6.90493289 7.55581053,16.1455344 9.47834357,17.954063 C11.4008766,19.7625917 16.9959382,11.5719148 19.0578414,12.4109285 C21.1197445,13.2499421 16.1152903,18.1722847 17.4055985,18.9997829 C18.2658039,19.5514483 19.3191667,19.0606734 20.5656867,17.527458"
                  ></path>
                </svg>
              </button>
              
              <button className={`arrow-btn ${drawMode === 'arrow' ? 'active' : ''}`}
                onClick={() => toggleDrawMode('arrow', videoRef, setDrawMode, setPlaybackState)}
              >
                <svg className="arrow" viewBox="0 0 24 24">
                  <path
                    fill="none"
                    stroke="#000000"
                    d="M4 12H20M4 12L8 8M4 12L8 16"
                  ></path>
                </svg>
              </button>
              <button className={`circle-btn ${drawMode === 'circle' ? 'active' : ''}`}
                onClick={() => toggleDrawMode('circle', videoRef, setDrawMode, setPlaybackState)}
              >
                <svg className="circle" viewBox="0 0 24 24">
                  <path
                    fill="none"
                    stroke="#000000"
                    d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                  ></path>
                </svg>
              </button>
            
            </div>

            
          {/* Color Picker */}
          <button
            className={`color-btn ${presentationMode ? 'present-mode' : ''}`}
            onClick={handleColorCycle}
            aria-label="Color Picker"
          >
            <svg className="color-icon" viewBox="0 0 24 24">
              <path
                fill={color}  // Use the selected color here
                stroke="#000000"
                d="M15.5 8.5H15.51M10.5 7.5H10.51M7.5 11.5H7.51M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 13.6569 19.6569 15 18 15H17.4C17.0284 15 16.8426 15 16.6871 15.0246C15.8313 15.1602 15.1602 15.8313 15.0246 16.6871C15 16.8426 15 17.0284 15 17.4V18C15 19.6569 13.6569 21 12 21Z"
              ></path>
            </svg>
          </button>

          <button
            className={`thickness-btn ${presentationMode ? 'present-mode' : ''}`}
            onClick={handleThicknessCycle}
            aria-label="Thickness Picker"
          >
            <div
              className="thickness-indicator"
              style={{ 
                height: `${thickness}px`, 
                backgroundColor: color,
                borderRadius: thickness === 12 ? '6px' : '4px',  // Make the third option more rounded
                width: thickness === 12 ? '70%' : '60%'          // Slightly increase the width for the third option
              }}
            ></div>
          </button>


          


          <button className={`trash-btn gen-btn ${presentationMode ? "placeholder-btn" : ""}`}>
            { !presentationMode ? (
            <svg className="trash" viewBox="0 0 24 24">
              <path
                fill="none"
                stroke="#000000"
                d="M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M18 6V16.2C18 17.8802 18 18.7202 17.673 19.362C17.3854 19.9265 16.9265 20.3854 16.362 20.673C15.7202 21 14.8802 21 13.2 21H10.8C9.11984 21 8.27976 21 7.63803 20.673C7.07354 20.3854 6.6146 19.9265 6.32698 19.362C6 18.7202 6 17.8802 6 16.2V6M14 10V17M10 10V17"
              ></path>
            </svg>
            ) : null }

          </button>

          <button className={`save-btn gen-btn ${presentationMode ? "placeholder-btn" : ""}`}>
            { !presentationMode ? (
                <svg className="save" viewBox="0 0 24 24">
                  <path
                    fill="none"
                    stroke="#000000"
                    d="M15 20V15H9V20M18 20H6C4.89543 20 4 19.1046 4 18V6C4 4.89543 4.89543 4 6 4H14.1716C14.702 4 15.2107 4.21071 15.5858 4.58579L19.4142 8.41421C19.7893 8.78929 20 9.29799 20 9.82843V18C20 19.1046 19.1046 20 18 20Z"
                  ></path>
                </svg>
            ) : null }
          </button>

        
        

          </div>
          {showInfo && (
            <div className="info-card">
              <button
                className="close-info-btn"
                onClick={() => setShowInfo(false)}
                aria-label="Close Info Card"
              >
                &times;
              </button>
              <h1 className="info-title">{videoMetadata.videoTitle}</h1>
              <p className="info-description">{videoMetadata.videoDescription}</p>
              <hr className="info-separator" />
              <p>
                <strong>Surgeon(s):</strong> <span className="info-data">{videoMetadata.operatedBy}</span>
              </p>
              <p>
                <strong>Gender:</strong> <span className="info-data">{videoMetadata.patientGender}</span>
              </p>
              <p>
                <strong>Speciality:</strong> <span className="info-data">{videoMetadata.surgerySpeciality}</span>
              </p>
              <p>
                <strong>Procedure:</strong> <span className="info-data">{videoMetadata.surgeryType}</span>
              </p>
              <p>
                <strong>Upload Date:</strong>
                {videoMetadata.uploadDate && (
                  <span className="info-date">
                    {(() => {
                      const date = new Date(videoMetadata.uploadDate);
                      const year = date.getFullYear();
                      const month = (date.getMonth() + 1).toString().padStart(2, '0');
                      const day = date.getDate().toString().padStart(2, '0');
                      return ` ${year}/${month}/${day}`;
                    })()}
                  </span>
                )}
              </p>
            </div>
          )}


        {/* Video Container */}      {/*onLoadedMetadata={() => setPreloading(false)} */}
        <div className={`video-wrapper ${pendingAction ? 'overlay-active' : ''}`}>

            {/* Loader overlay for timeline indicators */}
            {/* Timeline Indicators Loader */}
            {initializeTimeline && (
  <div className="video-transition-overlay">
    <div className="minimal-loader"></div>
  </div>
)}

{presentationMode && annotationFocusList.length > 1 && (
  <div className="focus-navigation">
    <button
      className="focus-prev"
      onClick={() => {
        if (focusIndex > 0) {
          setLastPausedTime(0);
          setDisplayedAnnotation(null);
          setFocusIndex((prevFocus) => prevFocus - 1);
          
        }
      }}
      disabled={focusIndex === 0}
      title="Previous Focus"
    >
      <svg viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M15.41,7.41L14,6l-6,6l6,6l1.41-1.41L10.83,12L15.41,7.41z"
        />
      </svg>
    </button>

    <button
      className="focus-next"
      onClick={() => {
        if (focusIndex < annotationFocusList.length - 1) {
          setLastPausedTime(0);
          // Clear the currently displayed annotation
          setDisplayedAnnotation(null);
          // Update the focus index
          setFocusIndex((prevFocus) => prevFocus + 1);
        }
      }}
      disabled={focusIndex === annotationFocusList.length - 1}
      title="Next Focus"
    >
      <svg viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z"
        />
      </svg>
    </button>
  </div>
)}

          {isHlsSupported ? (
            <video ref={videoRef} crossOrigin="anonymous" autoPlay={false} >
              Your browser does not support the video tag.
            </video>
          ) : (
            <p>Your browser does not support HLS streaming.</p>
          )}

                  {/* Canvases */}
          <canvas
            ref={coldCanvasRef}
            id="coldCanvas"
          ></canvas>
          <canvas
            ref={hotCanvasRef}
            id="hotCanvas"
          ></canvas>



              {/* Throttling Spinner */}
    {isBuffering && (
      <div className="throttling-spinner">
        <div className="spinner"></div>
      </div>
    )}
          {/* Video Controls */}

          <div className="video-controls-container">
          {(displayedAnnotation && presentationMode) && (
            <div className="annotation-details">
              <h3 className="annotation-details-title">
                {displayedAnnotation.title}
              </h3>
              <p className="annotation-details-description">
                {displayedAnnotation.description}
              </p>
            </div>
          )}
            <div className="timeline-container">
              <div className="timeline">
                <div className="thumb-indicator"></div>
              </div>
            </div>
            <div className="controls">
              <button className="play-pause-btn">
                <svg className="play-icon" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                </svg>
                <svg className="pause-icon" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M14,19H18V5H14M6,19H10V5H6V19Z" />
                </svg>
              </button>
              <div className="duration-container">
                <div className="current-time">0:00</div>/<div className="total-time"></div>
              </div>
              <button className="speed-btn wide-btn">1x</button>
              <div className="quality-selector">
                {/* The button that toggles the drop-up */}
                <button
                  className="quality-btn"
                  onClick={() => setIsQualityOpen(prev => !prev)}
                >
                  {selectedQuality}
                </button>

                {/* Conditionally render the drop-up */}
                {isQualityOpen && (
                  <div className="quality-dropdown">
                    <div className="quality-option" onClick={() => changeQuality("auto")}>
                      Auto
                    </div>
                    {availableLevels.map((level, index) => (
                      <div
                        key={index}
                        className={`quality-option ${
                          selectedQuality === `${level.height}p` ? "active" : ""
                        }`}
                        onClick={() => changeQuality(index)}
                      >
                        {level.height}p
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <button className="full-screen-btn" style={{ visibility: presentationMode ? "visible" : "hidden" }}>
                <svg className="open" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"
                  />
                </svg>
                <svg className="close" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"
                  />
                </svg>
              </button>
            </div>
          </div>
          
          <div className="loader-vp">
            {loading && <PulseLoader color="#ffffff" loading={loading} size={15} />}
          </div>


          {/* Restart Icon */}
          <div className="restart-icon-container" ref={restartIconRef}>
            <RestartIcon className="restart-icon" />
          </div>




  {pendingAction && (
    <div className="custom-overlay">
      <div className="custom-modal">
        <h2>
          {pendingAction === "deleteAnnotation"
            ? "Delete Annotation"
            : "Unsaved Changes"}
        </h2>
        <p>
          {pendingAction === "deleteAnnotation"
            ? "This action is permanent and cannot be undone. Are you sure you want to delete this annotation?"
            : "Proceeding will discard your current drawing. Continue?"}
        </p>
        <div className="modal-buttons">
        <button
    className="cancel-btn"
    onClick={() => {
      setPendingAction(null);  // Reset the action state
      setPendingParams(null);  // Reset any stored parameters
    }}
  >
    Cancel
  </button>
          <button
            className="confirm-btn"
            onClick={() => {
              if (!pendingAction) return; // Safety check

              const actionToExecute = pendingAction;
              const params = pendingParams; // Read params before clearing state



              // 🚀 Execute the function IMMEDIATELY
              switch (actionToExecute) {
                case "deleteAnnotation":
                  // 🛠 FIX: Pass `params.remoteTime` directly, ensuring it's always available
                  canvasManager.confirmedDeleteAnnotation(params?.remoteTime ?? -1);
                  break;
                case "enterFullscreen":
                  videoContainerRef.current.requestFullscreen();
                  break;
                case "exitFullscreen":
                  document.exitFullscreen();
                  break;
                case "play":
                  canvasManager.togglePlay({
                    skipPrompt: true,
                    playbackState,
                    setPlaybackState,
                    playPauseInProgress,
                  });
                  break;
                case "forwardTime":
                  canvasManager.forwardTime({
                    skipPrompt: true,
                    playbackState,
                    setPlaybackState,
                    playPauseInProgress,
                  });
                  break;
                case "backTime":
                  setPendingAction(null); 
                  canvasManager.backTime({
                    skipPrompt: true,
                    playbackState,
                    setPlaybackState,
                    playPauseInProgress,
                  });
                  break;
                case "scrubbing":
                  videoPlaybackHandler.toggleScrubbing(
                    videoPlaybackHandler.lastScrubEvent,
                    false, 
                    true   
                  );
                  videoContainerRef.current.classList.remove("scrubbing");
                  isScrubbingRef.current = false;

                  const timelinePreview = document.querySelector(".timeline-preview");
                  if (timelinePreview) {
                    timelinePreview.style.display = "none"; 
                    timelinePreview.textContent = "";      
                  }
                  break;
                default:
                  break;
              }

              // 🔥 Immediately hide modal BEFORE execution
              setPendingAction(null);
              setPendingParams(null);
              setDrawMode("");
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  )}
        </div>

        {presentationMode && showMoments && (
          <div className="moments-popup">
            <div className="moments-row">
              <div className="moments-content">
                {isPageLoading
                  ? Array.from({ length: itemsPerPage }).map((_, idx) => (
                      <div key={idx} className="moment-card dummy">
                        <div className="moment-thumbnail">
                          <div className="dummy-thumbnail"></div>
                        </div>
                        <div className="moment-info">
                          <div className="dummy-title"></div>
                          <div className="dummy-description"></div>
                          <div className="dummy-time"></div>
                        </div>
                      </div>
                    ))
                  : currentItems.map((moment, index) => {
                      const overallIndex = index + (currentPage - 1) * itemsPerPage;
                      return (
                        <div
                          key={moment.annotationId}
                          className={`moment-card ${
                            overallIndex === focusIndex ? "active" : ""
                          }`}
                          onClick={() => {
                            setLastPausedTime(0);
                            setFocusIndex(overallIndex);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="moment-thumbnail">
                            <img
                              src={moment.thumbnail}
                              alt={moment.title}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/placeholder-thumbnail.png"; // adjust as needed
                              }}
                            />
                          </div>
                          <div className="moment-info">
                          <p className="moment-time">{formatDuration(moment.time)}</p>
                            <h4 className="moment-title">{moment.title}</h4>
                            {moment.description && (
                              <p className="moment-description">
                                {moment.description}
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}
              </div>

                <div className="moments-pagination">
                  <button
                    className="pagination-btn"
                    onClick={() =>
                      currentPage > 1 && setCurrentPage(currentPage - 1)
                    }
                    disabled={currentPage === 1}
                  >
                    Prev
                  </button>
                  <button
                    className="pagination-btn"
                    onClick={() =>
                      currentPage < totalPages && setCurrentPage(currentPage + 1)
                    }
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                  <span className="pagination-info">
                    Page {currentPage} of {totalPages}
                  </span>
                </div>

            </div>
          </div>
        )}





      </div>



      {!presentationMode && (
        <div className="indicator-scroll-container">
          <div className="annotation-list-container">
            <div className="annotation-list">
              {/* No Annotations Message */}
              <p className="no-annotations-message">There are no annotations yet.</p>


            </div>

            {/* Shimmer Loader overlay: rendered on top of the list while loading */}
            {initializeTimeline && (
              <div className="annotation-list-shimmer-overlay">
                <div className="annotation-list-shimmer"></div>
              </div>
            )}

            {/* Annotation Form (if active) */}
            { ((drawMode !== '')) && (
  <div className="annotation-form-container">
    <div className="annotation-form">
      <div className="annotation-time-container">
        <div className="annotation-form-header">
          { editingAnnotation ? "Editing annotation" : "New annotation" }
        </div>
        <div className="paused-time">
          <strong>Time:</strong> {formatDuration(parseFloat(lastPausedTime))}
        </div>
      </div>

      {/* Annotation Form */}
      <form onSubmit={(e) => { e.preventDefault(); /* Handle form submission */ }}>
        <div className="form-group">
          <label htmlFor="annotation-title">
            <i className="fas fa-heading"></i> Annotation Title:
          </label>
          <input
            type="text"
            id="annotation-title"
            value={annotationTitle}
            onChange={(e) => setAnnotationTitle(e.target.value)}
            placeholder=" "
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="annotation-commentary">
            <i className="fas fa-comment-dots"></i> Commentary:
          </label>
          <textarea
            id="annotation-commentary"
            value={annotationCommentary}
            onChange={(e) => setAnnotationCommentary(e.target.value)}
            placeholder=" "
            required
          />
        </div>
      </form>

      <button className="save-btn-2 gen-btn">
        <svg className="save" viewBox="0 0 24 24">
          <path
            fill="none"
            stroke="#000000"
            d="M15 20V15H9V20M18 20H6C4.89543 20 4 19.1046 4 18V6C4 4.89543 4.89543 4 6 4H14.1716C14.702 4 15.2107 4.21071 15.5858 4.58579L19.4142 8.41421C19.7893 8.78929 20 9.29799 20 9.82843V18C20 19.1046 19.1046 20 18 20Z"
          ></path>
        </svg>
      </button>
    </div>
  </div>
)}
          </div>
        </div>
      )}




      

</div>

  );
}
export default VideoPlayer;

