import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

import Home from './components/home/home.js';
import CasesVideo from './components/cases/cases_video.js';
import CasesHome from './components/cases/cases_home.js';
import Account from './components/auth/account.js';
import UploadVideo from './components/cases/UploadVideo.js';
import { UploadProgressProvider } from './components/context/UploadProgressContext.js';
import CookieConsent from './components/common/CookieConsent';
import { Auth0Preloader } from './components/ui/Auth0Preloader.js';

import './App.css';

// Auth0 Credentials (replace with your actual Auth0 settings)
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const MIN_LOADER_TIME = 500; // 500ms minimum display time

const App = () => {
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_LOGOUT_URL,
        audience: audience,
        scope: "openid profile email"
      }}
    >
      <Router>
        <UploadProgressProvider>
          <AppContent />
        </UploadProgressProvider>
      </Router>
    </Auth0Provider>
  );
};

const AppContent = () => {
  const { isAuthenticated, loginWithRedirect, logout, isLoading } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const hideFooter = location.pathname.startsWith('/cases_video/');
  const [isDrawing, setIsDrawing] = useState(false);
  const [drawMode, setDrawMode] = useState("");
  const [showLoader, setShowLoader] = useState(true);

  // Define which routes are considered protected.
  // In this case, only the landing page ("/") is public.
  const isProtectedRoute = location.pathname !== "/";

  const handleLogoClick = () => navigate('/');

  const handleSignOut = () => {
    logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_LOGOUT_URL,
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      }
    });
  };

  // --- Non-auth related effects (for drawing, navigation, etc.) ---
  useEffect(() => {
    const handleDrawModeUpdate = (event) => {
      setDrawMode(event.detail);
      setIsDrawing(event.detail !== "");
    };
    window.addEventListener("drawModeUpdate", handleDrawModeUpdate);
    return () => window.removeEventListener("drawModeUpdate", handleDrawModeUpdate);
  }, []);

  useEffect(() => {
    if (!location.pathname.startsWith("/cases_video/")) {
      setDrawMode("");
      setIsDrawing(false);
    }
  }, [location.pathname]);

  const handleNavigation = (event) => {
    if (isDrawing && drawMode !== "") {
      event.preventDefault();
      const confirmExit = window.confirm("You have unsaved changes. Leave?");
      if (confirmExit) {
        setDrawMode("");
        setIsDrawing(false);
        navigate(event.target.getAttribute("href"));
      }
    }
  };

  useEffect(() => {
    const links = document.querySelectorAll("a.nav-link");
    links.forEach((link) => link.addEventListener("click", handleNavigation));
    return () => links.forEach((link) => link.removeEventListener("click", handleNavigation));
  }, [isDrawing, drawMode]);

  // --- Loader Minimum Display Time ---
  useEffect(() => {
    if (!isLoading) {
      const timer = setTimeout(() => setShowLoader(false), MIN_LOADER_TIME);
      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  // --- Top-level authentication check for protected routes ---
  useEffect(() => {
    if (!isLoading && !isAuthenticated && isProtectedRoute) {
      // Immediately trigger login with the current route as appState (if needed)
      loginWithRedirect({ appState: { returnTo: location.pathname } });
      // Do not navigate to "/" here; we want a direct redirect to login.
    }
  }, [isLoading, isAuthenticated, isProtectedRoute, location.pathname, loginWithRedirect]);

  // --- Render preloader until Auth0 is done loading and the minimum time has passed ---
  if (isLoading || showLoader) return <Auth0Preloader />;
  // For protected routes, if user is not authenticated, render nothing (preloader already shown)
  if (isProtectedRoute && !isAuthenticated) return <Auth0Preloader />;

  // --- Render the application layout if authenticated or on a public route ---
  return (
    <div className="App">
      <CookieConsent />
      <header className="header">
        <div className="logo" onClick={handleLogoClick}>InOperis</div>
        <nav className="nav">
          <Link to="/" className="nav-link">Home</Link>
          {isAuthenticated ? (
            <>
              <Link to="/cases_home" className="nav-link">Cases</Link>
              <Link to="/account" className="nav-link">Account</Link>
              <button className="nav-link logout-button" onClick={handleSignOut}>Logout</button>
            </>
          ) : (
            <button className="nav-link login-button" onClick={() => loginWithRedirect()}>
              Login
            </button>
          )}
        </nav>
      </header>

      <main className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cases_home" element={<CasesHome />} />
          <Route path="/cases_video/:videoId" element={<CasesVideo />} />
          <Route path="/account" element={<Account />} />
          <Route path="/upload_video" element={<UploadVideo />} />
        </Routes>
      </main>

      {!hideFooter && (
        <footer className="footer">
          <div>© 2024 InOperis</div>
          <div className="contact-info">
            <div className="contact-item">
              <p>Contact Us</p>
              <p>InOperis@gmail.com</p>
            </div>
            <div className="contact-item">
              <p>Montreal</p>
              <p>Quebec, Canada</p>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
};

export default App;